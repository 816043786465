import React from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import PublicClientWhiteList from './components/PublicClientWhiteList';

function ClientWhiteList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="Task">
        <PublicClientWhiteList />
      </div>
    </DashboardLayout>
  );
}

export default ClientWhiteList;
