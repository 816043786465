import React, { useState, useEffect } from 'react';
import DashboardCard from '../components/DashboardCard';
import { Typography, CircularProgress } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { getListingsStats, getResStats } from '../services/serverApi.financialConfig';
import logo from 'assets/images/logo-ct.png';
import booking from 'assets/images/booking.png';
import airbnb from 'assets/images/airbnb.png';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import ListingCard from '../components/ListingCard';

const ReservationsDashboard = () => {
  const [listingStats, setListingStats] = useState();
  const [reservationStats, setReservationStats] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [staging, setStaging] = useState(JSON.parse(localStorage.getItem('isStaging')) || false);

  useEffect(() => {
    const handleStagingToggle = (event) => {
      setStaging(event.detail);
      fetchData(event.detail);
    };

    window.addEventListener('stagingToggle', handleStagingToggle);
    fetchData(staging);

    return () => {
      window.removeEventListener('stagingToggle', handleStagingToggle);
    };
  }, []);

  const fetchData = async (staging) => {
    setIsLoading(true);
    try {
      const [listingData, reservationData] = await Promise.all([
        getListingsStats(staging),
        getResStats(staging)
      ]);
      setListingStats(listingData.data);
      setReservationStats(reservationData.data);
    } catch (error) {
      console.error('Error fetching listing stats:', error);
      setListingStats([]);
      setReservationStats([]);
    } finally {
      setIsLoading(false);
    }
  };
      // const unreadConversations = [
  //   { name: "abdilah eladi", date: "2024-06-18 - 2024-06-19", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "abdilah elaidi", date: "2024-06-24 - 2024-06-26", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "ABDELHAMID AZIZI", date: "2024-06-06 - 2024-06-07", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "Sojori", date: "2024-07-01 - 2024-07-12", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "ABDELHAMID AZIZI", date: "2024-05-23 - 2024-05-25", isNew: false, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "Tawfiq Gouach", date: "2024-05-25 - 2024-05-28", isNew: true, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  //   { name: "Anass Filali", date: "2024-04-03 - 2024-04-10", isNew: false, image: <img src="https://w7.pngwing.com/pngs/670/695/png-transparent-hostaway-company-business-service-management-in-the-future-company-text-service.png" alt="" className="w-6 h-6 rounded-full" /> },
  // ];



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatTime = (time) => time ? `@${time}:00` : 'N/A';

  const createGuestData = (data) => {
    return data?.map(item => {
      let guestImage = null;
      if (item.atSojori) {
        guestImage = <img src={logo} alt="" className="w-6 h-6" />;
      } else if (item.channelName?.toLowerCase() === 'bookingcom') {
        guestImage = <img src={booking} alt="" className="w-6 h-6" />;
      } else if (item.channelName?.toLowerCase() === 'airbnb') {
        guestImage = <img src={airbnb} alt="" className="w-6 h-6" />;
      }

      return {
        _id: item._id,
        Listingname: item.listing.name,
        CheckInDate: formatDate(item.arrivalDate),
        CheckinTime: formatTime(item.checkInTime),
        CheckOutDate: formatDate(item.departureDate),
        CheckOutTime: formatTime(item.checkOutTime),
        image: guestImage,
      };
    }) || [];
  };

  const StayingGuests = createGuestData(reservationStats?.stayingGuestsData);
  const PendingInquiries = createGuestData(reservationStats?.pendingInquiriesData);
  const UpcomingReservations = createGuestData(reservationStats?.upcomingReservationsData);
  const PendingReservations = createGuestData(reservationStats?.pendingReservationsData);
  const CheckInsToday = createGuestData(reservationStats?.checkInsTodayData);
  const CheckOutsToday = createGuestData(reservationStats?.checkOutsTodayData);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <CircularProgress sx={{ color: '#00b4b4' }} />
        </div>
      ) : (
        <>
          <Typography variant="h5" className="!my-8 !text-[#676A6C] bg-white mx-2 shadow-sm p-3 flex items-center gap-2 shadow-md">
            <EventAvailableIcon className="!text-2xl !font-semibold" />Reservations
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:grid-cols-1">
            <DashboardCard title="Today's check-outs" count={reservationStats?.checkOutsToday} subtitle="Check-outs today" isCurrent listingList={CheckOutsToday} />
            <DashboardCard title="Today's check-ins" count={reservationStats?.checkInsToday} subtitle="Check-ins today" isCurrent listingList={CheckInsToday} />
            <DashboardCard title="Reservations that need your approval" count={reservationStats?.pendingReservations} subtitle="Pending & unconfirmed reservation(s)" listingList={PendingReservations} isCurrent />
            <DashboardCard title="Upcoming reservation(s)" count={reservationStats?.upcomingReservations} subtitle="Upcoming reservations" next7Days listingList={UpcomingReservations} />
            <DashboardCard title="Staying guest(s)" count={reservationStats?.stayingGuests} subtitle="Guests who are currently staying" isCurrent listingList={StayingGuests} />
            <DashboardCard title="Pending inquiries" count={reservationStats?.pendingInquiries} subtitle="Guest inquiries for reservation" isCurrent listingList={PendingInquiries} />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:grid-cols-1 mt-4">
            <DashboardCard title="Check-in" count={reservationStats?.checkInsLast30Days} subtitle="Guest Check-ins" last30Days percentage={`${reservationStats?.checkInsPercentageChange}`} />
            <DashboardCard title="Revenue" count={reservationStats?.avgRevenueLast30Days} subtitle="Avg. revenue per guest" last30Days percentage={reservationStats?.avgRevenuePercentageChange} />
            <DashboardCard title="Revenue" count={reservationStats?.totalRevenueLast30Days} subtitle="Total Revenue" last30Days percentage={reservationStats?.totalRevenuePercentageChange} />
            <DashboardCard title="Reservation" count={reservationStats?.reservationLast30Days} subtitle="reservations" last30Days percentage={reservationStats?.reservationPercentageChange} />
          </div>
          <Typography variant="h5" className="!my-8 !text-[#676A6C] bg-white mx-2 shadow-sm p-3 flex items-center gap-2">
            <FormatListBulletedIcon className="!text-2xl !font-semibold" />Listings
          </Typography>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 sm:grid-cols-1 !mb-8">
            <ListingCard title="Total" count={listingStats?.totalListing} subtitle="Total listing(s)" />
            <ListingCard title="Active" count={listingStats?.totalActive || 0} subtitle="Active listing(s)" />
            <ListingCard title="Inactive" count={listingStats?.totalInactive} subtitle="Inactive listing(s)" />
          </div>
           {/* <Typography variant="h5" className="!my-8 !text-[#676A6C] bg-white mx-2 shadow-sm p-3 flex items-center gap-2"><ForumIcon className="!text-2xl !font-semibold" />Messages</Typography>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <MessageCard title="Sent" count="0" subtitle="Total sent messages" percentage="-100%" last30Days />
          <MessageCard title="Average sent" count="0" subtitle="Average sent messages per day" last30Days />
          <MessageCard title="Received" count="0" subtitle="Received message(s)" percentage="-100%" last30Days />
          <MessageCard title="Average received" count="0" subtitle="Average received messages per day" last30Days />
          <MessageCard title="Automation" count="0" subtitle="Messages sent by automation" percentage="-100%" last30Days />
          <MessageCard title="Automation" count="0" subtitle="Avg. sent by automation per day " last30Days />
        </div>
        <div className="grid grid-cols-1">
          <MessageCard title="Unread conversation(s)" unreadConversations={unreadConversations} last30Days className=""/>
        </div>
      </div> */}
        </>
      )}
    </DashboardLayout>
  );
};

export default ReservationsDashboard;