import React, { useState, useEffect } from 'react';
import { IconButton, FormControl, FormGroup, FormControlLabel, Checkbox, Popover, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const ColumnVisibility = ({ view, columnVisibility, handleColumnVisibilityChange }) => {
  const relevantColumns = view === 'listing'
    ? ['name', 'totalRevenue', 'nightsBooked', 'totalCheckIns', 'nightsAvailable', 'occupancyRate', 'revenuePerNightBooked', 'revenuePerNightAvailable', 'averageNightlyRate', 'averageRevenuePerStay', 'cancelations', 'cancelationsPercentage']
    : ['guestName', 'listingName', 'totalRevenue', 'fees'];

  return (
    <FormControl component="fieldset">
      <FormGroup>
        {relevantColumns.map(column => (
          <FormControlLabel
            key={column}
            control={
              <Checkbox
                checked={columnVisibility[column]}
                onChange={() => handleColumnVisibilityChange(column)}
                sx={{
                  color: '#00b4b4',
                  '&.Mui-checked': {
                    color: '#00b4b4',
                  },
                }}
              />
            }
            label={
              <Typography variant="body2">
                {column}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const ColumnVisibilitySettings = ({ view, columnVisibility, handleColumnVisibilityChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover-column-visibility' : undefined;

  return (
    <div className="d-flex justify-content-end">
      <IconButton aria-describedby={id} onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { backgroundColor: 'white', padding: 16, maxWidth: 300, maxHeight: 300, overflowY: 'auto' },
        }}
      >
        <ColumnVisibility
          view={view}
          columnVisibility={columnVisibility}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
        />
      </Popover>
    </div>
  );
};

export default ColumnVisibilitySettings;