// Reviews.jsx
import React from 'react';
import ReviewDetailsRes from './componentsDetails/reviewDetailsRes';

const Reviews = ({ reviews = [], onReplySubmitted, reservationInfo }) => {

  console.log("Reviews", reviews);
  console.log("ReservationDetailsbb", reservationInfo);
  return (
    <div className="mt-4">
      {!reviews || reviews.length === 0 ? (
        <p className="text-gray-500 text-center py-4">No reviews available</p>
      ) : (
        <div className="space-y-4">
          {reviews.map((review, index) => (
            <div key={review._id || index} className="bg-white shadow-sm rounded-lg mt-2">
              <ReviewDetailsRes 
                review={review} 
                containerWidth="100%"
                onReplySubmitted={onReplySubmitted}
                reservationInfo={reservationInfo} 
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Reviews;