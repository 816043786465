import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Stack,
  Chip,
  IconButton,
  Paper,
  Button

} from '@mui/material';
import {
  X,
  Home,
  User,
  Calendar,
  Clock,
  Users,
  DollarSign,
  MapPin,
  Edit
} from 'lucide-react';
import UpdateReservationDialog from './UpdateReservationDialog';
import { getReservationsById } from '../services/serverApi.reservation';


const EventPopup = ({ event, open, onClose, onReservationUpdate }) => {
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fullReservation, setFullReservation] = useState(null);

  useEffect(() => {


    fetchFullReservation();
  }, [event?._id, open]);


  const fetchFullReservation = async () => {
    if (!event?._id || !open) return;

    setIsLoading(true);
    try {
      const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
      const response = await getReservationsById(event._id, staging);
      setFullReservation(response.reservation);
    } catch (error) {
      console.error('Error fetching reservation details:', error);
      toast.error('Failed to fetch reservation details');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const formatTime = (time) => {
    return new Date(`2024-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };


  const handleUpdateClick = () => {
    setUpdateDialogOpen(true);
  };

  const handleUpdateDialogClose = () => {
    setUpdateDialogOpen(false);
  };

  const handleUpdateSuccess = (updatedData) => {
    setFullReservation(updatedData);
    onReservationUpdate(updatedData);
    setUpdateDialogOpen(false);
    onClose();
    fetchFullReservation();
  };

  

  const displayData = fullReservation || event;


  const renderInfoSection = (icon, title, content, secondaryContent = null) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      p: 2,
      borderRadius: 2,
      backgroundColor: 'rgba(0,0,0,0.03)'
    }}>
      {icon}
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body1" fontWeight="medium">
          {content}
        </Typography>
      </Box>
      {secondaryContent}
    </Box>
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent sx={{ p: 3, position: 'relative' }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'text.secondary'
            }}
          >
            <X size={20} />
          </IconButton>

          <div className="flex items-center justify-between mb-4">
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}
            >
              <Home size={24} color="#2196f3" />
              Reservation Details
            </Typography>

            <Button
              variant="contained"
              className="!bg-medium-aquamarine !text-white !mr-12"
              startIcon={<Edit size={20} />}
              onClick={handleUpdateClick}

            >
            </Button>
          </div>


          <Stack spacing={2}>
            {renderInfoSection(
              <User size={20} />,
              `${event?.guestName}`,
              `${event?.phone}`,
              <Chip
                label={event?.channelName || ''}
                size="small"
                color="primary"
                variant="outlined"
              />
            )}


            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              {renderInfoSection(
                <Calendar size={20} />,
                'Check-In',
                formatDate(event?.arrivalDate)
              )}
              {renderInfoSection(
                <Calendar size={20} />,
                'Check-Out',
                formatDate(event?.departureDate)
              )}
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              {renderInfoSection(
                <Clock size={20} />,
                'Check-In Time',
                event?.checkInTime || 'not set' 
              )}
              {renderInfoSection(
                <Users size={20} />,
                'Guests',
                `${event?.y || 0} guests`
              )}
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              {renderInfoSection(
                <DollarSign size={20} />,
                'Total Price',
                `${event?.currency} ${event?.totalPrice}`,
                <Chip
                  label={event?.paymentStatus}
                  color={event?.paymentStatus === 'Paid' ? 'success' : 'error'}
                  size="small"
                />
              )}
              {renderInfoSection(
                <MapPin size={20} />,
                'Reservation Number',
                event?.reservationNumber
              )}
            </Box>

            <Paper
              elevation={0}
              sx={{
                backgroundColor: 'background.default',
                p: 3,
                borderRadius: 3
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Home size={24} color="#2196f3" />
                <Typography variant="h6" fontWeight="bold">
                  Property Details
                </Typography>
              </Box>
              <Typography variant="body1" fontWeight="bold" sx={{ mt: 1 }} className="!text-gray-500">
                {event?.listing || ''}
              </Typography>
            </Paper>
          </Stack>
        </DialogContent>
      </Dialog>


      <UpdateReservationDialog
        open={updateDialogOpen}
        onClose={handleUpdateDialogClose}
        reservation={fullReservation || event}
        onUpdate={handleUpdateSuccess}
      />
    </>
  );
};

export default EventPopup;