import React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@mui/material';

const AddTaskButton = ({ onClick }) => {
  return (
    <Button
    className="!p-button-text !p-1 !rounded !my-1 sm:my-3 !bg-medium-aquamarine !h-9 !px-4 !justify-center"
      onClick={onClick}
    >
      <span className="text-sm font-medium text-white">Add Task</span>
      </Button>



  );
};

export default AddTaskButton;