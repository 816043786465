import { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import { getMailTemplate, translateText, sendMailTemplate } from 'features/setting/services/serverApi.adminConfig';
import DynamicFieldButtons from './DynamicFieldButtons';
import { ToastContainer, toast } from 'react-toastify';

const Templates = ({ reservationInfo }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [modelContent, setModelContent] = useState('');
  const [previewContent, setPreviewContent] = useState('');
  const [targetLanguage, setTargetLanguage] = useState(reservationInfo?.guestLanguage || 'fr');
  const [isLoading, setIsLoading] = useState(false);

  const availableFields = [
    { label: 'Guest Full Name', value: '${guestName}', display: '[GUEST_NAME]' },
    { label: 'Guest First Name', value: '${guestFirstName}', display: '[GUEST_FIRST_NAME]' },
    { label: 'Guest Last Name', value: '${guestLastName}', display: '[GUEST_LAST_NAME]' },
    { label: 'Guest Email', value: '${guestEmail}', display: '[GUEST_EMAIL]' },
    { label: 'Guest Phone', value: '${phone}', display: '[GUEST_PHONE]' },
    { label: 'Guest Address', value: '${guestAddress}', display: '[GUEST_ADDRESS]' },
    { label: 'Guest City', value: '${guestCity}', display: '[GUEST_CITY]' },
    { label: 'Guest Country', value: '${guestCountry}', display: '[GUEST_COUNTRY]' },
    { label: 'Nationality', value: '${nationality}', display: '[NATIONALITY]' },
    { label: 'Reservation Number', value: '${reservationNumber}', display: '[RESERVATION_NUMBER]' },
    { label: 'Number of Guests', value: '${numberOfGuests}', display: '[NUMBER_OF_GUESTS]' },
    { label: 'Number of Nights', value: '${nights}', display: '[NIGHTS]' },
    { label: 'Arrival Date', value: '${arrivalDate}', display: '[ARRIVAL_DATE]' },
    { label: 'Departure Date', value: '${departureDate}', display: '[DEPARTURE_DATE]' },
    { label: 'Check-in Time', value: '${checkInTime}', display: '[CHECK_IN_TIME]' },
    { label: 'Check-out Time', value: '${checkOutTime}', display: '[CHECK_OUT_TIME]' },
    { label: 'Total Price', value: '${totalPrice}', display: '[TOTAL_PRICE]' },
    { label: 'Currency', value: '${currency}', display: '[CURRENCY]' },
    { label: 'Payment Status', value: '${paymentStatus}', display: '[PAYMENT_STATUS]' },
    { label: 'Payment Method', value: '${paymentMethod}', display: '[PAYMENT_METHOD]' },
    { label: 'Door Code', value: '${doorCode}', display: '[DOOR_CODE]' },
    { label: 'Listing Name', value: '${listing.name}', display: '[LISTING_NAME]' },
  ];

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await getMailTemplate();
      if (response?.data?.success) {
        setTemplates(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    }
  };

  const replaceTemplateVariables = (content) => {
    if (!content || !reservationInfo) return content;

    const variables = {};
    availableFields.forEach(field => {
      const key = field.value;
      let value = '';
      
      if (key.includes('.')) {
        const [obj, prop] = key.slice(2, -1).split('.');
        value = reservationInfo[obj]?.[prop] || '';
      } else {
        value = reservationInfo[key.slice(2, -1)] || '';
      }

      if (field.label.includes('Date')) {
        value = value ? new Date(value).toLocaleDateString() : '';
      }

      variables[key] = value;
    });

    let replacedContent = content;
    Object.entries(variables).forEach(([key, value]) => {
      replacedContent = replacedContent.replace(new RegExp(escapeRegExp(key), 'g'), value);
    });

    return replacedContent;
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const handleTemplateChange = (e) => {
    const selectedId = e.target.value;
    setSelectedTemplate(selectedId);
    
    const template = templates.find(t => t._id === selectedId);
    if (template) {
      const content = template.content;
      setModelContent(content);
      setPreviewContent(replaceTemplateVariables(content));
    }
  };

  const handleModelChange = (e) => {
    const content = e.target.value;
    setModelContent(content);
    setPreviewContent(replaceTemplateVariables(content));
  };

  const handleTranslate = async () => {
    if (!previewContent || !targetLanguage) return;
  
    setIsLoading(true);
    try {
      const response = await translateText({
        txt: previewContent,
        language: targetLanguage,
        preserveValues: [reservationInfo.listing?.name].filter(Boolean)
      });
  
      if (response.data.success) {
        setPreviewContent(response.data.translatedText);
      }
    } catch (error) {
      console.error('Translation error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSendMessage = async () => {
    if (!previewContent) return;

    setIsLoading(true);
    try {
      await sendMailTemplate({
        templateId: selectedTemplate || "", 
        reservationId: reservationInfo._id,
        content: previewContent
      });
      toast.success(`Message sent successfully to ${reservationInfo.guestEmail}`);
    } catch (error) {
      console.error('Send message error:', error);
      const errorMessage = error.response?.data?.error || 'Failed to send message';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const insertField = (field) => {
    const textarea = document.querySelector('textarea');
    const cursorPosition = textarea?.selectionStart || modelContent.length;
    const textBeforeCursor = modelContent.slice(0, cursorPosition);
    
    const needsSpace = cursorPosition > 0 &&
      textBeforeCursor.slice(-1) !== ' ' &&
      textBeforeCursor.slice(-1) !== '\n';

    const fieldToInsert = needsSpace ? ` ${field.value}` : field.value;
    const newContent = modelContent.slice(0, cursorPosition) +
      fieldToInsert +
      modelContent.slice(cursorPosition);

    setModelContent(newContent);
    setPreviewContent(replaceTemplateVariables(newContent));
  };

  return (
    <div className="w-full space-y-4">
      <div className="flex gap-2 items-center mb-4">
        <select
          value={selectedTemplate}
          onChange={handleTemplateChange}
          className="w-40 p-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00b4b4]"
        >
          <option value="">Select a template</option>
          {templates.map((template) => (
            <option key={template._id} value={template._id}  className="text-sm">
              {template.messageName}
            </option>
          ))}
        </select>

        <input
          type="text"
          value={targetLanguage}
          onChange={(e) => setTargetLanguage(e.target.value)}
          placeholder="Target language (e.g. fr, en, es)"
          className="w-40 px-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#00b4b4]"
          />

        <button
          onClick={handleTranslate}
          disabled={isLoading || !previewContent} 
          className="px-2 bg-[#00b4b4] text-white !rounded-md hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Translate
        </button>

        <button
          onClick={handleSendMessage}
          disabled={isLoading || !previewContent} 
          className="px-2 bg-[#00b4b4] text-white !rounded-md hover:bg-opacity-90 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Send Message
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Card className="!p-4">
          <div className="mb-2">
            <label className="block mb-2 !text-sm font-bold">
              Template Model
            </label>
            <textarea
              value={modelContent}
              onChange={handleModelChange}
              className="w-full p-2 border rounded min-h-[400px] !text-sm font-mono focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
            />
            <div className="mt-4">
              <DynamicFieldButtons
                availableFields={availableFields}
                insertField={insertField}
              />
            </div>
          </div>
        </Card>

        <Card className="!p-4">
          <div className="mb-2">
            <label className="block mb-2 !text-sm font-bold">Preview</label>
            <div className="p-4 bg-gray-100 rounded !text-sm whitespace-pre-wrap h-[400px] overflow-y-auto border">
              {previewContent}
            </div>
          </div>
        </Card>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
    </div>
  );
};

export default Templates;