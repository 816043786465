import React from 'react';
import { Button } from '@mui/material';
import resetSvgIcon from 'assets/images/resetSvgIcon.svg';

const ViewToggle = ({ view, setView, toggleFilters, onViewChange, showFilters = false, handleFilterChange }) => (
    <div className="flex mb-2 items-center">
        <Button
            onClick={toggleFilters}
            className="!p-button-text !mr-2 !p-1 !rounded !bg-medium-aquamarine !h-9 !px-4 !justify-center"
        >
            <span className="!text-white !text-xs !font-medium">
                Filter {showFilters ? '▼' : '▶'}
            </span>
        </Button>
        <button
            className={`h-10 px-1 ${view === 'reservation' ? 'bg-gray-500 text-white' : 'bg-gray-200 text-[#957F8C]'} border-none cursor-pointer !text-sm px-4`}
            onClick={() => {
                setView('reservation');
                onViewChange('reservation');
            }}
        >
            Per reservation
        </button>
        <button
            className={`h-10 px-1 ${view === 'listing' ? 'bg-gray-500 text-white' : 'bg-gray-200 text-[#957F8C]'} border-none cursor-pointer !text-sm px-4`}
            onClick={() => {
                setView('listing');
                onViewChange('listing');
            }}
        >
            Per listing
        </button>
        <Button
            onClick={() => handleFilterChange('reset', null)}
            className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !h-9 !w-9 !justify-center !ml-2"
        >
            <img src={resetSvgIcon} alt="reset" />
        </Button>
    </div>
);

export default ViewToggle;