import React, { useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Pagination from './Pagination';
import TotalRevenueTable from 'features/financial/components/rentalActivity/TotalRevenueTable';
import styled from 'styled-components';

const TableContainer = styled.div`
  .table-wrapper {
    width: 100%;
    overflow-x: auto;
    /* Add max-height and vertical scroll */
    max-height: 600px;
    overflow-y: auto;
  }

  .totals-wrapper {
    width: 100%;
    overflow-x: auto;
    border-top: 1px solid #dfe1e6;
  }

  @media screen and (max-width: 768px) {
    .table-wrapper,
    .totals-wrapper {
      -webkit-overflow-scrolling: touch;
    }

    table {
      min-width: 768px;
    }
  }

  table {
    table-layout: fixed;
  }
    
  tr:nth-child(even),
  tr:nth-child(odd) {
    background-color: white;
    color: black;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  td,
  th {
    font-size: 14px;
    border: none;
    white-space: nowrap; 
    text-align: start;
    padding: 8px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  td {
    height: 60px;
  }

  th {
    background: white;
    border-bottom: 1px solid #dfe1e6;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    /* Make headers sticky */
    position: sticky;
    top: 0;
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    th,
    td {
      min-width: 106px;
      width: 106px;
      max-width: 106px;
    }
  }

  .p-sortable-column .p-sortable-column-icon {
    color: #808080; 
  }

  .p-sortable-column:hover .p-sortable-column-icon {
    color: #606060; 
  }

  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #404040;
  }

  .total-row {
    background-color: #f8f9fa !important;
    border-top: 1px solid #dfe1e6;
  }
`;

const GlobalTable = ({
  data,
  columns,
  page,
  onPageChange,
  isNextDisabled,
  hasPagination,
  limit = 20, // Default limit
  onLimitChange,
  rowsPerPageOptions,
  totalRevenue,
  totals,
  onRowClick
}) => {
  const mainTableRef = useRef(null);

  const rowClassName = (rowData) => {
    if (rowData.isTotal) {
      return 'total-row font-bold';
    }
    return {
      '!bg-red-100': rowData.isUnmapped,
      '!cursor-pointer': rowData.isUnmapped
    };
  };

  const shouldEnableScroll = data.length > limit;

  const dataWithTotals = [...data];
  if (totals) {
    const totalsRow = columns.reduce((acc, col) => {
      if (col.field === 'name' || col.field === 'guestName') {
        acc[col.field] = 'Total';
      } else if (col.field === 'totalRevenue') {
        acc[col.field] = totals.totalRevenue;
      } else if (col.field === 'fees') {
        acc[col.field] = totals.totalFees;
      } else if (col.field === 'nightsBooked') {
        acc[col.field] = totals.totalNightsBooked;
      } else if (col.field === 'totalCheckIns') {
        acc[col.field] = totals.totalCheckIns;
      } else if (col.field === 'nightsAvailable') {
        acc[col.field] = totals.totalNightsAvailable;
      } else if (col.field === 'occupancyRate') {
        acc[col.field] = totals.averageOccupancyRate;
      } else if (col.field === 'revenuePerNightBooked') {
        acc[col.field] = totals.revenuePerNightBooked;
      } else if (col.field === 'revenuePerNightAvailable') {
        acc[col.field] = totals.revenuePerNightAvailable;
      } else if (col.field === 'averageNightlyRate') {
        acc[col.field] = totals.averageNightlyRate;
      } else if (col.field === 'averageRevenuePerStay') {
        acc[col.field] = totals.averageRevenuePerStay;
      } else if (col.field === 'cancelations') {
        acc[col.field] = totals.totalCancelations;
      } else if (col.field === 'cancelationsPercentage') {
        acc[col.field] = totals.totalCancelationsPercentage;
      }
      return acc;
    }, { isTotal: true });
    dataWithTotals.push(totalsRow);
  }

  return (
    <TableContainer>
      <div ref={mainTableRef}>
        <div className="table-wrapper" style={{ 
          maxHeight: shouldEnableScroll ? '600px' : 'none',
          overflowY: shouldEnableScroll ? 'auto' : 'visible' 
        }}>
          <DataTable 
            value={dataWithTotals} 
            className="p-datatable-sm"
            rowClassName={rowClassName}
            sortField="isUnmapped" 
            sortOrder={-1}
            onRowClick={(e) => !e.data.isTotal && onRowClick && onRowClick(e.data)}
            scrollable
            scrollHeight="600px"
          >
            {columns.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                body={col.body}
                headerClassName={col.headerClassName}
                sortable 
                headerStyle={{
                  minWidth: col.width || '150px',  
                  width: col.width || '150px',
                  maxWidth: col.width || '150px',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  ...col.headerStyle,
                }}
                bodyStyle={(data) => ({
                  minWidth: col.width || '150px',
                  width: col.width || '150px',
                  maxWidth: col.width || '150px',
                  fontWeight: data.isTotal ? 'bold' : 'normal',
                  textAlign: (col.field === 'name' || col.field === 'guestName' || col.field === 'listingName') ? 'left' : 'right',
                  ...col.bodyStyle,
                })}
              />
            ))}
          </DataTable>
        </div>
      </div>

      {hasPagination && (
        <Pagination
          page={page}
          onPageChange={onPageChange}
          isNextDisabled={isNextDisabled}
          limit={limit}
          onLimitChange={onLimitChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
    </TableContainer>
  );
};

export default GlobalTable;