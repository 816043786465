import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

const formatDate = (date) => {
  if (!date) return '';
  const utcDate = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
  return utcDate.toISOString().split('T')[0];
};

export function getReservations(params = {}) {
  const {
    limit = 0,
    dateType = '',
    startDate = '',
    endDate = '',
    idType = 'sojori',
    guestName = '',
    page = 0,
    status = '',
    phone = '',
    staging = false,
    paymentStatus = '',
    timeLine = '',
    reservationNumber = '',
    channelName = '',
    listings = [],
    cities = [],
    countryNames = [],
  } = params;

  const formattedStartDate = startDate
    ? new Date(startDate).toISOString().split('T')[0]
    : '';
  const formattedEndDate = endDate
    ? new Date(endDate).toISOString().split('T')[0]
    : '';

  const queryParams = new URLSearchParams();

  const addParam = (key, value) => {
    if (value !== undefined && value !== null && value !== '') {
      if (Array.isArray(value)) {
        value.forEach(item => {
          if (item) queryParams.append(`${key}[]`, item);
        });
      } else {
        queryParams.append(key, value);
      }
    }
  };

  addParam('limit', limit);
  addParam('dateType', dateType);
  addParam('startDate', formattedStartDate);
  addParam('endDate', formattedEndDate);
  addParam('idType', idType);
  addParam('guestName', guestName);
  addParam('page', page);
  addParam('status', status);
  addParam('phone', phone);
  addParam('staging', staging);
  addParam('paymentStatus', paymentStatus);
  addParam('timeLine', timeLine);
  addParam('reservationNumber', reservationNumber);
  addParam('channelName', channelName);
  addParam('listingId', listings);
  addParam('cityId', cities);

  if (Array.isArray(countryNames)) {
    countryNames.forEach(country => {
      if (country) queryParams.append('countryNames', country);
    });
  }


  const formatDatecheck = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const mapReservationData = (reservation) => {
    const reservationDate = reservation.reservationDate
      ? new Date(reservation.reservationDate)
      : null;
    const createdAt = reservation.createdAt
      ? new Date(reservation.createdAt)
      : null;

    return {
      id: reservation._id,
      guestName: reservation.guestName,
      checkInDate: reservation.arrivalDate
        ? formatDatecheck(reservation.arrivalDate)
        : '',
      checkOutDate: reservation.departureDate
        ? formatDatecheck(reservation.departureDate)
        : '',
      currency: reservation.currency,
      totalPrice: reservation.totalPrice,
      status: reservation.status,
      y: reservation.guestRegistration?.nbre_guest_to_register || 0,
      x: reservation.guestRegistration?.nbre_guest_registered || 0,
      checkinStatus: reservation.checkinStatus,
      listing: reservation.listing
        ? reservation.listing.name
        : 'No Listing Name',
      reservationNumber: reservation.reservationNumber,
      reservationDate: reservationDate ? formatDate(reservationDate) : '',
      createdAt: createdAt ? formatDate(createdAt) : '',
      timeLine: reservation.timeLine,
      paymentStatus: reservation.paymentStatus,
      paymentRedirectToSuccess: reservation.paymentRedirectToSuccess,
      sojoriId: reservation.sojoriId,
      roomTypeName: reservation.roomTypeName,
      roomTypeId: reservation.roomTypeId,
      listingData: reservation.listing,
      isUnmapped: reservation.isUnmapped || false,
      phone: reservation.phone,
      channelName: reservation.channelName,
      otaCommission: reservation.otaCommission,
      paymentCollect: reservation.paymentCollect,
      byChannex: reservation.byChannex || false,
      otaCode: reservation.otaCode,
      voucherNo: reservation.voucherNo,
      confirmedCheckInTime : reservation.confirmedCheckInTime,
      confirmedCheckOutTime : reservation.confirmedCheckOutTime,
      checkInTime: reservation.checkInTime,
      checkOutTime: reservation.checkOutTime,
      checkInTimeStart: reservation?.listing?.checkInTimeStart,

    };
  };

  return axios
    .get(`${MICROSERVICE_BASE_URL.RESERVATION}/?${queryParams.toString()}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      const unmappedReservations = (response.data.unmappedReservation || []).map(reservation => ({
        ...mapReservationData(reservation),
        isUnmapped: true
      }));

      const regularReservations = (response.data.data || []).map(reservation => ({
        ...mapReservationData(reservation),
        isUnmapped: false
      }));

      return [...unmappedReservations, ...regularReservations];

    })
    .catch((error) => {
      console.error('Error fetching reservations:', error);
      throw error;
    });
}

// https://dev.sojori.com/api/v1/reservations/reservations/resolve-unmaped-reservation/:reservationId

export function resolveUnmappedReservation(reservationId) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.RESERVATION}/resolve-unmaped-reservation/${reservationId}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Error resolving unmapped reservation:', error);
      throw error;
    });
}


export function getListings(staging = false) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.LISTING}/?staging=${staging}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data.data.map((listing) => ({
        id: listing._id,
        name: listing.name,
        checkInTime: listing.checkInTimeStart,
        checkOutTime: listing.checkOutTime,
        propertyUnit: listing.propertyUnit,
        roomTypes: listing.roomTypes,
      }));
    })
    .catch((error) => {
      console.error('Error fetching listings:', error);
      throw error;
    });
}

export function getReservationsById(id, staging = false) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.RESERVATION}/by-id/${id}/?staging=${staging}`)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error('Error fetching reservation:', error);
      throw error;
    });
}
export function createReservation(data) {
  return axios
    .post(`${MICROSERVICE_BASE_URL.RESERVATION}/create`, data)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error('Error creating reservation:', error);
      throw error;
    });
}

export function updateReservation(id, data) {
  return axios
    .put(`${MICROSERVICE_BASE_URL.RESERVATION}/update/${id}`, data)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error('Error updating reservation:', error);
      throw error;
    });
}

export function getCalendarDetails(sojoriId, startDate, endDate) {
  return axios
    .get(
      `${MICROSERVICE_BASE_URL.CALENDAR}/${sojoriId}/calendar?startDate=${startDate}&endDate=${endDate}`,
    )
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    })
    .catch((error) => {
      console.error('Error fetching calendar details:', error);
      throw error;
    });
}

export const getLanguages = () => {
  return axios
    .get(
      `${MICROSERVICE_BASE_URL.LANGUAGE}?page=0&limit=20&paged=false&search_text=`,
    )

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching languages:', error);
      throw error;
    });
};

export function getCountries() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.COUNTRY}?page=0&limit=10&search_text&paged=false`,
  );
}

export function getCities() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CITY}?page=0&limit=10&search_text&paged=false`,
  );
}

export function cancelReservation(id) {
  const data = { status: 'CancelledByAdmin' };
  return axios.put(`${MICROSERVICE_BASE_URL.RESERVATION}/cancel/${id}`, data);
}

export function revertCancellation(id, data) {
  const payload = {
    status: 'Pending',
    ...data,
  };
  return axios.put(
    `${MICROSERVICE_BASE_URL.RESERVATION}/update/${id}`,
    payload,
  );
}

export function sendMessage(data) {
  return axios
    .post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/channex/send-msg-by-channex`, data)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    })
    .catch((error) => {
      console.error('Error sending message:', error);
      throw error;
    });
}


// https://dev.sojori.com/api/v1/admin/user-chat-history?page=0&limit=20&paged=true&conversation_page=0&conversation_limit=1000&search_text=212664876132


export function getChatHistory(page, limit, conversationPage = 0, conversationLimit = 1000, search_text = '') {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history?page=${page}&limit=${limit}&paged=true&conversation_page=${conversationPage}&conversation_limit=${conversationLimit}&search_text=${search_text}`);

}

export function pushMessage(conversationId, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history/push-msg/${conversationId}`, data);
}


export const SOCKET_URL = 'https://dev.sojori.com';

export const SOCKET_PATH = '/api/v1/sockets/connect';