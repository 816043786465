import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Chip,
    Popover,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Paper,
    TextField,
    InputAdornment,
} from '@mui/material';
import { ExpandMore, Close, Search } from '@mui/icons-material';

const FilterItems = ({ selectedItems, options, onItemsChange, placeholder = 'Select items' }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSearchQuery('');
    };

    const handleToggle = (value) => {
        const currentIndex = selectedItems.indexOf(value);
        const newSelectedItems = [...selectedItems];
        if (currentIndex === -1) {
            newSelectedItems.push(value);
        } else {
            newSelectedItems.splice(currentIndex, 1);
        }
        onItemsChange(newSelectedItems);
    };

    const filteredOptions = options.filter(option => 
        typeof option === 'string' 
            ? option.toLowerCase().includes(searchQuery.toLowerCase())
            : option.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const open = Boolean(anchorEl);
    const id = open ? 'items-popover' : undefined;

    return (
        <Box>
            <Box display="flex" alignItems="center">
                <Chip
                    label={selectedItems.length > 0 
                        ? Array.isArray(selectedItems[0]) 
                            ? selectedItems.map(item => item.name).join(', ')
                            : selectedItems.join(', ')
                        : placeholder}
                    onClick={handleClick}
                    onDelete={selectedItems.length > 0 ? () => onItemsChange([]) : undefined}
                    deleteIcon={<Close />}
                    variant="outlined"
                    sx={{  
                        maxWidth: { xs: '100%', sm: 160 },
                        width: { xs: '100%', sm: 'auto' },
                        overflow: 'hidden',
                        borderRadius: '5px',
                        height: 41
                    }}
                />
                <IconButton 
                    size="small" 
                    onClick={handleClick}
                    sx={{ display: { xs: 'none', sm: 'flex' } }}
                >
                    <ExpandMore />
                </IconButton>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    }
                }}
            >
                <Paper elevation={0}>
                    <TextField
                        size="small"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        fullWidth
                        sx={{ p: 1 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <List sx={{ width: 200 }} className='!p-1'>
                        {filteredOptions.map((option) => (
                            <ListItem 
                                key={typeof option === 'string' ? option : option._id} 
                                dense 
                                button 
                                onClick={() => handleToggle(option)}
                            >
                                <Checkbox
                                    edge="start"
                                    checked={selectedItems.indexOf(option) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    className='!text-teal-600'
                                />
                                <ListItemText 
                                    primary={typeof option === 'string' ? option : option.name} 
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Popover>
        </Box>
    );
};

export default FilterItems;