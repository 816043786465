import React, { useState, useEffect } from 'react';
import { CalendarReservation } from './CalendarReservation';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getListings, getListingsList, getCities } from '../../tasks/services/serverApi.task';
import { getCountries } from '../services/serverApi.reservation';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useParams, useNavigate } from 'react-router-dom';

function ReservationCalendar() {
    const [groupedListings, setGroupedListings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [listingOptions, setListingOptions] = useState([]);
    const [selectedListings, setSelectedListings] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState(['Pending', 'Confirmed']);
    const { listingId } = useParams();
    const navigate = useNavigate();
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

    const statusOptions = [
        { id: 'Pending', name: 'Pending' },
        { id: 'Confirmed', name: 'Confirmed' },
        { id: 'Completed', name: 'Completed' },
        { id: 'CancelledByAdmin', name: 'Cancelled by Admin' },
        { id: 'CancelledByCustomer', name: 'Cancelled by Customer' },
        { id: 'CancelledByOta', name: 'Cancelled by OTA' },
        { id: 'CancelledAfterFailedPayment', name: 'Cancelled After Failed Payment' },
        { id: 'OtherCancellation', name: 'Other Cancellation' }
    ];

    const fetchListings = async () => {
        try {
            setIsLoading(true);
            console.log('Fetching listings with params:', {
                staging,
                selectedListings,
                selectedCities,
                selectedCountries,
                selectedStatuses
            });

            const response = await getListings(
                staging,
                selectedListings,
                selectedCities
            );

            // console.log('Raw API response:', response);

            if (
                response?.data?.success === false ||
                !response?.data?.data ||
                response.data.data.length === 0
            ) {
                // console.log('No listings found or invalid response');
                setGroupedListings([]);
                // setError("No listings found for the selected criteria.");
                return;
            }

            if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
                // console.log('Original listings data:', response.data.data);

                const normalizedListings = response.data.data.map(group => ({
                    ...group,
                    _id: group._id.toLowerCase()
                }));
                // console.log('Normalized listings:', normalizedListings);

                let filteredListings = normalizedListings;
                if (listingId) {
                    // console.log('Filtering by listingId:', listingId);
                    filteredListings = normalizedListings.map(group => ({
                        ...group,
                        listings: group.listings.filter(listing => listing._id === listingId),
                        count: group.listings.filter(listing => listing._id === listingId).length
                    })).filter(group => group.count > 0);
                    // console.log('Filtered listings by ID:', filteredListings);
                }

                const groupedByZone = filteredListings.reduce((acc, group) => {
                    const zone = group._id;
                    if (!acc[zone]) {
                        acc[zone] = { _id: zone, listings: [], count: 0 };
                    }
                    acc[zone].listings = [...acc[zone].listings, ...group.listings];
                    acc[zone].count += group.count;
                    return acc;
                }, {});
                // console.log('Grouped by zone:', groupedByZone);

                const regroupedListings = Object.values(groupedByZone);
                // console.log('Final regrouped listings:', regroupedListings);

                setGroupedListings(regroupedListings);
            }
        } catch (err) {
            // console.error('Error fetching listings:', err);
            setGroupedListings([]);
            setError('Failed to fetch listings. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchListingOptions = async () => {
        try {
            const options = await getListingsList(staging);
            setListingOptions(options);
        } catch (err) {
            // console.error('Error fetching listing options:', err);
        }
    };

    const fetchCities = async () => {
        try {
            const response = await getCities();
            setCities(response.data);
        } catch (error) {
            // console.error('Error fetching cities:', error);
            setError('Failed to fetch cities. Please try again.');
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await getCountries();
            if (Array.isArray(response.data)) {
                const formattedCountries = response.data.map(country => ({
                    id: country.name,
                    name: country.name,
                    countryCode: country.countryCode,
                    currency: country.currency
                }));
                setCountries(formattedCountries);
            } else {
                console.error('Unexpected country data format:', response.data);
                setError('Invalid country data format');
            }
        } catch (error) {
            console.error('Error fetching countries:', error);
            setError('Failed to fetch countries');
        }
    };

    useEffect(() => {
        fetchListingOptions();
        fetchCities();
        fetchCountries();
    }, [staging]);

    useEffect(() => {
        fetchListings();
    }, [selectedListings, selectedCities, selectedCountries, selectedStatuses, staging]);

    const handleListingChange = (selectedIds) => {
        setSelectedListings(selectedIds);
        if (listingId && !selectedIds.includes(listingId)) {
            navigate('/reservation');
        }
    };

    const handleCityChange = (selectedCities) => {
        setSelectedCities(selectedCities);
    };

    const handleCountryChange = (selectedCountries) => {
        setSelectedCountries(selectedCountries);
    };

    const handleStatusChange = (selectedStatuses) => {
        setSelectedStatuses(selectedStatuses);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="reservation-container card !p-4">
                <div className="flex items-center mb-2 gap-2">

                    <FilterSearch
                        selectedItems={selectedCountries}
                        options={countries}
                        onItemsChange={handleCountryChange}
                        placeholder="Select Countries"
                        idKey="id"
                        labelKey="name"
                    />

                    <FilterSearch
                        selectedItems={selectedCities}
                        options={cities}
                        onItemsChange={handleCityChange}
                        placeholder="Select Cities"
                        idKey="_id"
                        labelKey="name"
                    />

                    <FilterSearch
                        selectedItems={selectedListings}
                        options={listingOptions}
                        onItemsChange={handleListingChange}
                        placeholder="Select Listings"
                        idKey="id"
                        labelKey="name"
                    />


                    <FilterSearch
                        selectedItems={selectedStatuses}
                        options={statusOptions}
                        onItemsChange={handleStatusChange}
                        placeholder="Select Status"
                        idKey="id"
                        labelKey="name"
                    />
                </div>
                <CalendarReservation
                    groupedListings={groupedListings}
                    listingId={listingId}
                    selectedCountries={selectedCountries}
                    selectedStatuses={selectedStatuses}
                />
            </div>
        </DashboardLayout>
    );
}

export default ReservationCalendar;
