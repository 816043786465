import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const TimelineFilter = ({ timeLine, setTimeLine, showFrench = true }) => {
  const timeLineOptions = [
    { id: 'Coming-Today', name: 'Arrivée aujourd\'hui' },
    { id: 'Departure-today', name: 'Départ aujourd\'hui' },
    { id: 'Coming-Tomorrow', name: 'Arrivée demain' },
    { id: 'Departure-tomorrow', name: 'Départ demain' },
    { id: 'Inside', name: 'Actuellement sur place' },
    { id: 'Coming-in2days', name: 'Arrivée dans 2 jours' },
    { id: 'Coming-in3days', name: 'Arrivée dans 3 jours' },
    { id: 'Normal', name: 'Future Reservation' },
    { id: 'Leave', name: 'Départ terminé' },
    { id: 'One-Night', name: 'Une nuitée' }
  ];

  return (
    <FilterSearch
      options={timeLineOptions}
      selectedItems={timeLine}
      onItemsChange={setTimeLine}
      placeholder="Timeline"
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default TimelineFilter;