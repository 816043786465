import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import { Add as Plus, Remove as Minus } from '@mui/icons-material';

const GuestCountInput = ({ value, onChange, isEditMode, editableDetails }) => {
    const [isOpen, setIsOpen] = useState(false);

    const parseGuestCounts = (val) => {
        if (typeof val !== 'string') return { adults: 0, children: 0, infants: 0 };
        
        const match = val.match(/(\d+)A\s*(\d+)C\s*(\d+)I/);
        if (!match) return { adults: 0, children: 0, infants: 0 };
        
        return {
            adults: parseInt(match[1]) || 0,
            children: parseInt(match[2]) || 0,
            infants: parseInt(match[3]) || 0
        };
    };

    const [counts, setCounts] = useState(() => {
        if (editableDetails) {
            return {
                adults: editableDetails.adults || 0,
                children: editableDetails.children || 0,
                infants: editableDetails.infants || 0
            };
        }
        return parseGuestCounts(value);
    });

    useEffect(() => {
        if (editableDetails) {
            setCounts({
                adults: editableDetails.adults || 0,
                children: editableDetails.children || 0,
                infants: editableDetails.infants || 0
            });
        } else if (typeof value === 'string') {
            setCounts(parseGuestCounts(value));
        }
    }, [value, editableDetails]);

    const handleCountChange = (type, increment) => {
        const newCounts = { ...counts };
        newCounts[type] = Math.max(0, counts[type] + increment);
        setCounts(newCounts);
    };

    const handleSave = () => {
        const totalGuests = counts.adults + counts.children + counts.infants;
        
        const updates = [
            { name: 'numberOfGuests', value: totalGuests },
            { name: 'adults', value: counts.adults },
            { name: 'children', value: counts.children },
            { name: 'infants', value: counts.infants }
        ];

        updates.forEach(update => {
            onChange({ target: update });
        });

        setIsOpen(false);
    };

    const renderCounter = (label, type) => (
        <div className="flex items-center justify-between py-4 px-4">
            <span className="text-sm text-gray-700">{label}</span>
            <div className="flex items-center gap-4">
                <Button
                    className="!bg-gray-100 !text-gray-700"
                    size="small"
                    onClick={() => handleCountChange(type, -1)}
                    disabled={counts[type] === 0}
                    style={{ minWidth: '40px', padding: '5px' }}
                >
                    <Minus fontSize="small" />
                </Button>
                <span className="w-8 text-center">{counts[type]}</span>
                <Button
                    className="!bg-medium-aquamarine !text-white"
                    size="small"
                    onClick={() => handleCountChange(type, 1)}
                    style={{ minWidth: '40px', padding: '5px' }}
                >
                    <Plus fontSize="small" />
                </Button>
            </div>
        </div>
    );

    const displayValue = `${counts.adults}A ${counts.children}C ${counts.infants}I`;

    return (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">Number Of Guests</label>
            <div
                className={`flex items-center gap-2 mb-2 px-3 py-2 rounded ${
                    isEditMode ? 'bg-white border border-gray-300' : 'bg-[#eee] border border-[#eee]'
                }`}
                onClick={() => isEditMode && setIsOpen(true)}
                style={{ cursor: isEditMode ? 'pointer' : 'default' }}
            >
                <span className="text-sm text-gray-700">{displayValue}</span>
            </div>

            <Dialog 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle style={{ paddingBottom: 0 }}>
                    Edit Guest Count
                </DialogTitle>
                <DialogContent>
                    <div className="py-4">
                        {renderCounter('Adults', 'adults')}
                        {renderCounter('Children', 'children')}
                        {renderCounter('Infants', 'infants')}
                    </div>
                    <div className="flex justify-end gap-2 pt-4">
                        <Button 
                            className="!bg-gray-100 !text-gray-700"
                            onClick={() => setIsOpen(false)}
                            color="inherit"
                        >
                            Cancel
                        </Button>
                        <Button 
                            className="!bg-medium-aquamarine !text-white"
                            onClick={handleSave}
                            color="primary"
                        >
                            Save
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default GuestCountInput;