import React, { useState } from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const CityFilter = ({ cities = [], selectedCities = [], setSelectedCities }) => {
  return (
    <FilterSearch
      options={cities}
      selectedItems={selectedCities}
      onItemsChange={setSelectedCities}
      placeholder="Select Cities"
      idKey="_id"
      labelKey="name"
    />
  );
};

export default CityFilter;