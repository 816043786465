import React, { useState, useEffect } from 'react';
import { getListings, getCalendarDetails } from 'features/reservation/services/serverApi.reservation';
import GuestDetails from './guestInfoDetails/GuestDetails';
import ListingDetails from './guestInfoDetails/ListingDetails';
import ReservationDetails from './guestInfoDetails/ReservationDetails';
import InvoicesAndCharges from './guestInfoDetails/InvoicesAndCharges';
import RateDetailsTable from './guestInfoDetails/RateDetailsTable ';
import { ChevronDown, ChevronUp } from 'lucide-react';


const GuestInfo = ({ reservationDetails, isEditMode, onDetailsUpdate }) => {
    const [listings, setListings] = useState([]);
    const [isTableOpen, setIsTableOpen] = useState(false);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    const [editableDetails, setEditableDetails] = useState({
        reservationNumber: '',
        guestName: '',
        guestFirstName: '',
        guestLastName: '',
        guestEmail: '',
        phone: '',
        numberOfGuests: '',
        guestLanguage: '',
        listingName: '',
        sojoriId: '',
        cancellationPolicy: '',
        arrivalDate: '',
        departureDate: '',
        checkInTime: '',
        checkOutTime: '',
        currency: '',
        status: '',
        guestCountry: '',
        guestCity: '',
        reservationDate: '',
        timeLine: '',
        paymentStatus: '',
        roomTypeId: '',
        confirmedCheckInTime: false,
        confirmedCheckOutTime: false,
        adults: 0,
        children: 0,
        infants: 0,

    });


    useEffect(() => {
        const fetchListings = async () => {
            try {
                const fetchedListings = await getListings(staging);
                setListings(fetchedListings);
            } catch (error) {
                console.error("Error fetching listings:", error);
            }
        };
        fetchListings();
    }, [staging]);

    useEffect(() => {
        if (reservationDetails) {
            const selectedListing = listings.find(listing => listing.id === reservationDetails.sojoriId);

            setEditableDetails(prevDetails => {
                const details = {
                    ...prevDetails,
                    ...reservationDetails,
                    listingName: reservationDetails.listing ? reservationDetails.listing.name : '',
                    sojoriId: selectedListing ? selectedListing.id : '',
                    arrivalDate: formattedDate(reservationDetails.arrivalDate),
                    departureDate: formattedDate(reservationDetails.departureDate),
                    reservationDate: formattedDate(reservationDetails.reservationDate),
                    checkInTime: reservationDetails.checkInTime || '',
                    checkOutTime: reservationDetails.checkOutTime || '',
                };

                if (reservationDetails.atSojori) {
                    details.createdAt = formattedDate(reservationDetails.createdAt);
                } else {
                    details.reservationDate = formattedDate(reservationDetails.reservationDate);
                }

                return details;
            });
        }
    }, [reservationDetails, listings]);

    useEffect(() => {
        if (isEditMode) {
            onDetailsUpdate(editableDetails);
        }
    }, [editableDetails, isEditMode, onDetailsUpdate]);

    const formattedDate = (date) => {
        if (!date) return '';
        const formattedDate = new Date(date);
        if (isNaN(formattedDate.getTime())) return '';
        return formattedDate.toISOString().split('T')[0];
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;

        if (name === 'listing-select') {
            const selectedListing = listings.find(listing => listing.id.toString() === value);

            if (selectedListing) {
                setEditableDetails(prevDetails => ({
                    ...prevDetails,
                    sojoriId: selectedListing.id,
                    listingName: selectedListing.name,
                    // checkInTime: selectedListing.checkInTime,
                    // checkOutTime: selectedListing.checkOutTime,
                    // roomTypeId: '', 

                }));

                await fetchCalendarAndSetPrice(selectedListing);
            }
        } else {
            setEditableDetails(prevDetails => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };


    const fetchCalendarAndSetPrice = async (selectedListing) => {
        if (!selectedListing) return;

        try {
            const arrivalDate = formattedDate(editableDetails.arrivalDate);
            const departureDate = formattedDate(editableDetails.departureDate);
            const calendarData = await getCalendarDetails(selectedListing.id, arrivalDate, departureDate);
            const totalPrice = calendarData[0]?.price || 0;

            setEditableDetails(prevDetails => ({
                ...prevDetails,
                totalPrice,
            }));
        } catch (error) {
            console.error('Error fetching calendar details:', error);
        }
    };

    return (
        <div className="transition-all duration-100 ease-in-out !my-8 px-4 md:px-0">
            {/* Desktop view */}
            <div className="hidden md:flex flex-row gap-4">
                <div className="w-full">
                    <GuestDetails
                        editableDetails={editableDetails}
                        isEditMode={isEditMode}
                        handleInputChange={handleInputChange}
                    />
                    <InvoicesAndCharges reservationDetails={reservationDetails} />

                </div>
                <div className="w-full">
                    <ListingDetails
                        listings={listings}
                        editableDetails={editableDetails}
                        isEditMode={isEditMode}
                        handleInputChange={handleInputChange}
                    />
                    <ReservationDetails
                        editableDetails={editableDetails}
                        isEditMode={isEditMode}
                        handleInputChange={handleInputChange}
                        listings={listings}
                    />
                </div>
            </div>
            <div className="hidden md:flex flex-col gap-1">
            {reservationDetails?.roomsDetails?.length > 0 && (
                <>
                    <div
                        className="flex !items-center gap-2 cursor-pointer hover:bg-gray-50 p-2 rounded mt-4 justify-center"
                        onClick={() => setIsTableOpen(!isTableOpen)}
                    >
                        {isTableOpen ? <ChevronUp className="text-[#676a6c]" size={20} /> : <ChevronDown className="text-[#676a6c]" size={20} />}
                        <span className="text-sm font-medium text-[#676a6c]">View rate details</span>
                    </div>
                    {isTableOpen && (
                        <RateDetailsTable
                            roomDetails={reservationDetails.roomsDetails[0]}
                            reservationDetails={reservationDetails}
                        />
                    )}
                </>
            )}
            </div>

            <div className="md:hidden">
                <GuestDetails
                    editableDetails={editableDetails}
                    isEditMode={isEditMode}
                    handleInputChange={handleInputChange}
                />
                <InvoicesAndCharges reservationDetails={reservationDetails} />
                {reservationDetails?.roomsDetails?.length > 0 && (
                    <>
                        <div
                            className="flex items-center gap-2 cursor-pointer hover:bg-gray-50 p-2 rounded mt-4"
                            onClick={() => setIsTableOpen(!isTableOpen)}
                        >
                            {isTableOpen ? <ChevronUp className="text-[#676a6c]" size={20} /> : <ChevronDown className="text-[#676a6c]" size={20} />}
                            <span className="text-sm font-medium text-[#676a6c]">View rate details</span>
                        </div>
                        {isTableOpen && (
                            <RateDetailsTable
                                roomDetails={reservationDetails.roomsDetails[0]}
                                reservationDetails={reservationDetails}
                            />
                        )}
                    </>
                )}
                <ListingDetails
                    listings={listings}
                    editableDetails={editableDetails}
                    isEditMode={isEditMode}
                    handleInputChange={handleInputChange}
                />
                <ReservationDetails
                    editableDetails={editableDetails}
                    isEditMode={isEditMode}
                    handleInputChange={handleInputChange}
                    listings={listings}
                />
            </div>
        </div>
    );
};

export default GuestInfo;
