import React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';

const AddEditTravellerModal = ({ isOpen, onClose, currentTraveller, editIndex, languages, onSave, onInputChange }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} PaperProps={{ style: { minWidth: '600px' } }}>
            <DialogTitle>{editIndex !== null ? 'Edit Traveller' : 'Add Traveller'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the traveller details.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    name="whatsappNumber"
                    label="WhatsApp Number"
                    type="text"
                    fullWidth
                    value={currentTraveller?.whatsappNumber || ''}
                    onChange={onInputChange}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Language</InputLabel>
                    <Select
                        name="language"
                        value={currentTraveller?.language || ''}
                        onChange={onInputChange}
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang._id} value={lang.languageCode}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <InputLabel>Role</InputLabel>
                    <Select
                        name="role"
                        value={currentTraveller?.role || 'Traveller'}
                        onChange={onInputChange}
                    >
                        <MenuItem value="Traveller">Traveller</MenuItem>
                        <MenuItem value="Registration">Registration</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditTravellerModal;