import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  CalendarSearch, StepForward, SkipForward, SkipBack, StepBack, ChevronRight, ChevronLeft, ChevronLast, ChevronFirst

} from 'lucide-react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const SidebarProperty = ({
  onPreviousPeriod,
  onNextPeriod,
  onTodayClick,
  onDateRangeChange,
  formattedDateRange,
  dateRange,
  showDatePicker,
  setShowDatePicker
}) => {
  const datePickerRef = useRef(null);
  const triggerRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (triggerRef.current && showDatePicker) {
        const rect = triggerRef.current.getBoundingClientRect();
        const scrollX = window.scrollX || window.pageXOffset;
        const scrollY = window.scrollY || window.pageYOffset;

        setPosition({
          top: rect.bottom + scrollY,
          left: rect.left + scrollX,
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [showDatePicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDatePicker, setShowDatePicker]);

  const handleDateClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (date) => {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 29); 
  
    onDateRangeChange({
      startDate,
      endDate,
      key: 'selection'
    });
    setShowDatePicker(false);
  };

  const handleNextDay = () => {
    const currentStart = new Date(dateRange.startDate);
    const currentEnd = new Date(dateRange.endDate);

    const newStartDate = new Date(currentStart);
    newStartDate.setDate(newStartDate.getDate() + 1);

    const newEndDate = new Date(currentEnd);
    newEndDate.setDate(newEndDate.getDate() + 1);

    onDateRangeChange({
      startDate: newStartDate,
      endDate: newEndDate,
      key: 'selection'
    });
  };

  const handlePreviousDay = () => {
    const currentStart = new Date(dateRange.startDate);
    const currentEnd = new Date(dateRange.endDate);

    const newStartDate = new Date(currentStart);
    newStartDate.setDate(newStartDate.getDate() - 1);

    const newEndDate = new Date(currentEnd);
    newEndDate.setDate(newEndDate.getDate() - 1);

    onDateRangeChange({
      startDate: newStartDate,
      endDate: newEndDate,
      key: 'selection'
    });
  };


  const handleNextMonth = () => {
    const currentStart = new Date(dateRange.startDate);
    const nextMonthStart = new Date(currentStart.getFullYear(), currentStart.getMonth() + 1, 1);
    const nextMonthEnd = new Date(nextMonthStart);
    nextMonthEnd.setMonth(nextMonthEnd.getMonth() + 1, 0);
    nextMonthEnd.setDate(nextMonthEnd.getDate());
  
    onDateRangeChange({
      startDate: nextMonthStart,
      endDate: nextMonthEnd,
      key: 'selection',
    });
  };
  
  const handlePreviousPeriod = () => {
    const currentStart = new Date(dateRange.startDate);
    const currentMonthStart = new Date(currentStart.getFullYear(), currentStart.getMonth(), 1);
  
    if (currentStart.getDate() === 1) {
      const prevMonthStart = new Date(currentStart.getFullYear(), currentStart.getMonth() - 1, 1);
      const prevMonthEnd = new Date(prevMonthStart);
      prevMonthEnd.setMonth(prevMonthEnd.getMonth() + 1, 0);
  
      onDateRangeChange({
        startDate: prevMonthStart,
        endDate: prevMonthEnd,
        key: 'selection',
      });
    } else {
      const currentMonthEnd = new Date(currentMonthStart);
      currentMonthEnd.setMonth(currentMonthStart.getMonth() + 1, 0);
  
      onDateRangeChange({
        startDate: currentMonthStart,
        endDate: currentMonthEnd,
        key: 'selection',
      });
    }
  };



  return (
    <div className="w-full">
      <div className="flex flex-wrap justify-center items-center gap-1 sm:gap-4 p-1 sm:p-2">
        <div className="flex justify-center items-center gap-1 sm:gap-3">
          <ChevronFirst
            onClick={handlePreviousPeriod}
            className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
          />
          <ChevronLeft
            onClick={handlePreviousDay}
            className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
          />
          <div
            ref={triggerRef}
            className="flex items-center gap-1 cursor-pointer hover:bg-gray-100 p-1 rounded-md"
            onClick={handleDateClick}
          >
            <span className="text-[10px] sm:text-sm font-bold whitespace-nowrap">{formattedDateRange}</span>
            <CalendarSearch className="text-teal-500 w-5 h-5 sm:w-6 sm:h-6" />
          </div>
          <div
            onClick={onTodayClick}
            className="px-2 py-1 bg-white border border-teal-500 rounded-md cursor-pointer hover:bg-teal-600 hover:text-white"
          >
            <span className="text-[10px] sm:text-xs text-teal-500 hover:text-white">Now</span>
          </div>
          <ChevronRight
            onClick={handleNextDay}
            className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
          />
          <ChevronLast
            onClick={handleNextMonth}
            className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
          />
        </div>
        {showDatePicker && createPortal(
          <div
            ref={datePickerRef}
            className="fixed shadow-xl bg-white rounded-lg z-[9999]"
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
              maxWidth: '95vw',
            }}
          >
            <Calendar
              date={dateRange.startDate}
              onChange={handleDateChange}
              className="rounded-lg overflow-hidden scale-[0.85] sm:scale-100"
            />
          </div>,
          document.body
        )}
      </div>
    </div>
  );
};

export default SidebarProperty;