import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAmenities, updateAmenity, deleteAmenity } from '../services/serverApi.listing.js';
import { getcities } from '../../setting/services/serverApi.adminConfig.js';
import ModifyAmenity from './ModifyAmenity.jsx';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

function Amenities() {
  const [amenities, setAmenities] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const fetchAmenities = async () => {
    setIsLoading(true);
    try {
      const response = await getAmenities();
      if (response.data && Array.isArray(response.data.data)) {
        setAmenities(response.data.data);
      } else {
        setAmenities([]);
      }
    } catch (error) {
      console.error('Error fetching amenities:', error);
      setAmenities([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await getcities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  useEffect(() => {
    fetchAmenities();
    fetchCities();
  }, []);

  const getCityNames = (cityIds) => {
    if (!cityIds) {
      return [''];
    }
    return cityIds.map((cityId) => {
      const city = cities.find((c) => c._id === cityId);
      return city ? city.name : cityId;
    });
  };

  const handleOpenModifyDialog = (index, amenity) => {
    setSelectedAmenity(amenity);
    setSelectedIndex(index);
    setIsCreating(false);
    setOpenModifyDialog(true);
  };

  const handleCloseModifyDialog = () => {
    setOpenModifyDialog(false);
    setSelectedAmenity(null);
    setSelectedIndex(null);
    setIsCreating(false);
  };

  const handleToggleEnabled = async (amenity) => {
    try {
      const updatedAmenity = { ...amenity, enabled: !amenity.enabled };
      const response = await updateAmenity(amenity._id, updatedAmenity);

      if (response.data && response.data.data) {
        setAmenities((prevAmenities) =>
          prevAmenities.map((a) =>
            a._id === amenity._id ? response.data.data : a,
          ),
        );
        toast.success('Amenity updated successfully');
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error updating amenity:', error);
      toast.error('Error updating amenity');
    }
  };

  const handleToggleUsedForFilter = async (amenity) => {
    try {
      const updatedAmenity = {
        ...amenity,
        usedForFilter: !amenity.usedForFilter,
      };
      const response = await updateAmenity(amenity._id, updatedAmenity);

      if (response.data && response.data.data) {
        setAmenities((prevAmenities) =>
          prevAmenities.map((a) =>
            a._id === amenity._id ? response.data.data : a,
          ),
        );
        toast.success('Amenity updated successfully');
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error updating amenity:', error);
      toast.error('Error updating amenity');
    }
  };

  const handleToggleTopAmenity = async (amenity) => {
    try {
      const updatedAmenity = {
        ...amenity,
        topAmentity: !amenity.topAmentity,
      };
      const response = await updateAmenity(amenity._id, updatedAmenity);

      if (response.data && response.data.data) {
        setAmenities((prevAmenities) =>
          prevAmenities.map((a) =>
            a._id === amenity._id ? response.data.data : a,
          ),
        );
        toast.success('Amenity updated successfully');
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error('Error updating amenity:', error);
      toast.error('Error updating amenity');
    }
  };

  const handleDelete = async (amenityId) => {
    if (window.confirm('Are you sure you want to delete this Amenity?')) {
      try {
        await deleteAmenity(amenityId);
        setAmenities((prevAmenities) => prevAmenities.filter((item) => item._id !== amenityId));
        toast.success('Amenity deleted successfully');
      } catch (error) {
        console.error('Error deleting amenity:', error);
        toast.error('Error deleting amenity');
      }
    }
  };

  const columns = [
    {
      field: 'iconUrl',
      header: 'Icon',
      body: (rowData) => (
        <img 
          src={rowData.iconUrl} 
          alt={rowData.name} 
          style={{ width: '70px', height: '70px', objectFit: 'contain' }} 
        />
      ),
      headerStyle: { width: '100px' }
    },
    {
      field: 'name',
      header: 'Name',
      headerStyle: { width: '200px' }
    },
    {
      field: 'cityIds',
      header: 'Cities',
      body: (rowData) => getCityNames(rowData.cityIds).join(', '),
      headerStyle: { width: '200px' }
    },
    {
      field: 'enabled',
      header: 'Enabled',
      body: (rowData) => (
        <Switch 
          checked={rowData.enabled} 
          onChange={() => handleToggleEnabled(rowData)} 
          color="primary" 
        />
      ),
      headerStyle: { width: '100px' }
    },
    {
      field: 'usedForFilter',
      header: 'Use For Filter',
      body: (rowData) => (
        <Switch 
          checked={rowData.usedForFilter} 
          onChange={() => handleToggleUsedForFilter(rowData)} 
          color="primary" 
        />
      ),
      headerStyle: { width: '150px' }
    },
    {
      field: 'topAmentity',
      header: 'Top Amenity',
      body: (rowData) => (
        <Switch 
          checked={rowData.topAmentity} 
          onChange={() => handleToggleTopAmenity(rowData)} 
          color="primary" 
        />
      ),
      headerStyle: { width: '100px' }
    },
    {
      field: 'actions',
      header: 'Action',
      body: (rowData) => (
        <>
          <IconButton 
            className="!p-[7px] !rounded-lg bg-white shadow-sm"
            onClick={() => handleOpenModifyDialog(amenities.indexOf(rowData), rowData)}
          >
            <ModeEditOutlineOutlinedIcon style={{ color: '#66cdaa' }} />
          </IconButton>
          <IconButton 
            className="!p-[7px] !rounded-lg bg-white shadow-sm mx-2"
            onClick={() => handleDelete(rowData._id)}
          >
            <DeleteIcon style={{ color: '#ff6b6b' }} />
          </IconButton>
        </>
      ),
      headerStyle: { width: '100px' }
    }
  ];

  return (
    <>
      <Button 
        onClick={() => {
          setIsCreating(true);
          setSelectedAmenity({});
          setOpenModifyDialog(true);
        }}
        className='mb-3 !bg-[#00b4b4]'
      >
        <span className='!text-white'>Create Amenity</span>
      </Button>
      <ToastContainer position="top-right" autoClose={3000} />
      <Box >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress sx={{ color: '#00b4b4' }} />
        </Box>
      ) : (
        <GlobalTable
          data={amenities}
          columns={columns}
          page={page}
          onPageChange={setPage}
          isNextDisabled={false}
          hasPagination={false}
          limit={limit}
          onLimitChange={setLimit}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      )}
      </Box>
      {(selectedAmenity || isCreating) && (
        <ModifyAmenity
          open={openModifyDialog}
          onClose={handleCloseModifyDialog}
          setAmenities={setAmenities}
          cities={cities}
          amenities={amenities}
          index={selectedIndex}
          dataAmenity={selectedAmenity}
          isCreating={isCreating}
        />
      )}
    </>
  );
}

export default Amenities;