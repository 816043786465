import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Send as SendIcon } from 'lucide-react';
import { getChatHistory, pushMessage, SOCKET_URL, SOCKET_PATH } from 'features/reservation/services/serverApi.reservation';
import moment from 'moment';
import { Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import io from 'socket.io-client';

const WhatsAppChat = ({ reservationDetails, chatTitle = "WhatsApp Chat" }) => {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [lastReceivedDate, setLastReceivedDate] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const messagesEndRef = useRef(null);
  const listRef = useRef(null);
  const socketRef = useRef(null);
  const MESSAGES_PER_PAGE = 100;

  const formatPhoneNumber = (phone) => phone?.replace(/^\+/, '') || '';

  const isSystemSource = (source) => source && source.toLowerCase() !== 'customer';

  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      transports: ['websocket'],
      autoConnect: true,
    });

    newSocket.on('connect', () => {
      console.log('WhatsApp WebSocket connected');
      newSocket.emit('JOIN_ROOM', 'CHAT');
    });

    newSocket.on('disconnect', () => {
      console.log('WhatsApp WebSocket disconnected');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    socketRef.current = newSocket;

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const handleNewMessage = useCallback((data) => {
    console.log('Received socket message:', data);

    const messageDetails = data.msg || {};
    
    const newMsg = {
      _id: messageDetails._id || `temp-${Date.now()}`,
      content: messageDetails.content || '',
      origin: messageDetails.origin || '',
      source: messageDetails.source || '',
      type: messageDetails.type || 'Text',
      date: messageDetails.date || new Date().toISOString(),
      url: messageDetails.url || '',
      category: messageDetails.Category || '',
      intent: messageDetails.intent || '',
      sentiment: messageDetails.sentiment || '',
      urgency_level: messageDetails.urgency_level || ''
    };

    if (messageDetails.origin === 'Received' || messageDetails.source === 'Customer') {
      setLastReceivedDate(data.last_date_received || messageDetails.date);
    }

    setConversations(prev => {
      if (prev.some(msg => msg._id === newMsg._id)) return prev;
      return [...prev, newMsg].sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, []);

  useEffect(() => {
    if (!socketRef.current) return;

    socketRef.current.on('NEW_RECIVED_MSG', handleNewMessage);
    socketRef.current.on('NEW_SENDED_MSG', handleNewMessage);

    return () => {
      socketRef.current.off('NEW_RECIVED_MSG');
      socketRef.current.off('NEW_SENDED_MSG');
    };
  }, [handleNewMessage]);

  const handleScroll = useCallback(() => {
    const list = listRef.current;
    if (!list || !hasMore || isLoading) return;

    if (list.scrollTop === 0) {
      loadMoreMessages();
    }
  }, [hasMore, isLoading]);

  const loadMoreMessages = async () => {
    if (!hasMore || isLoading || !reservationDetails?.phone) return;

    try {
      setIsLoading(true);
      const nextPage = page + 1;
      const formattedPhone = formatPhoneNumber(reservationDetails.phone);

      const response = await getChatHistory(
        nextPage * MESSAGES_PER_PAGE,
        MESSAGES_PER_PAGE,
        0,
        1000,
        formattedPhone
      );

      if (response.data?.data?.[0]) {
        const chatData = response.data.data[0];
        const newMessages = chatData.conversations || [];

        if (newMessages.length < MESSAGES_PER_PAGE) {
          setHasMore(false);
        }

        setConversations(prev => {
          const combined = [...prev, ...newMessages];
          const uniqueMessages = [...new Map(combined.map(item => [item._id, item])).values()];
          return uniqueMessages.sort((a, b) => new Date(a.date) - new Date(b.date));
        });

        setPage(nextPage);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);
      return () => list.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    const fetchInitialChatHistory = async () => {
      if (!reservationDetails?.phone) {
        console.log('No phone number available');
        return;
      }

      try {
        setIsLoading(true);
        const formattedPhone = formatPhoneNumber(reservationDetails.phone);
        console.log('Fetching chat history for phone:', formattedPhone);

        const response = await getChatHistory(0, MESSAGES_PER_PAGE, 0, 1000, formattedPhone);

        if (response.data?.data?.[0]) {
          const chatData = response.data.data[0];
          setChatId(chatData._id);

          if (chatData.conversations) {
            const sortedConversations = chatData.conversations.sort((a, b) => 
              new Date(a.date) - new Date(b.date)
            );

            const lastReceived = sortedConversations
              .filter(msg => msg.origin === 'Received' || msg.source === 'Customer')
              .pop()?.date;

            setLastReceivedDate(lastReceived || null);
            setConversations(sortedConversations);

            if (chatData.conversations.length < MESSAGES_PER_PAGE) {
              setHasMore(false);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching chat history:', error);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialChatHistory();
    setPage(0);
    setHasMore(true);
    setConversations([]);
  }, [reservationDetails?.phone]);

  const sendMessage = async (messageText) => {
    if (isSending || !messageText.trim() || !chatId) {
      console.log('Cannot send message:', { isSending, messageText, chatId });
      return;
    }

    const currentDate = moment().toISOString();

    const tempMessage = {
      _id: `temp-${Date.now()}`,
      content: messageText,
      origin: "Sent",
      source: "admin",
      type: "Text",
      date: currentDate,
      Category: "",
      intent: "",
      sentiment: "",
      urgency_level: "",
      url: "",
      location: { longitude: 0, latitude: 0 }
    };

    try {
      setIsSending(true);
      setConversations(prev => [...prev, tempMessage]);
      setNewMessage('');

      const messageData = {
        msg: {
          ...tempMessage,
          _id: undefined 
        },
        last_date_sent: currentDate,
        last_date_received: lastReceivedDate || currentDate,
        phone: reservationDetails?.phone
      };

      const response = await pushMessage(chatId, messageData);

      if (socketRef.current?.connected) {
        socketRef.current.emit('NEW_SENDED_MSG', {
          ...messageData,
          _id: response?.data?._id || chatId,
          roomName: 'CHAT'
        });
      }

      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    } catch (error) {
      console.error('Error sending message:', error);
      setConversations(prev => prev.filter(msg => msg._id !== tempMessage._id));
      setNewMessage(messageText);
    } finally {
      setIsSending(false);
    }
  };

  const renderMessageBubble = (message, index) => {
    const isSystem = isSystemSource(message.source);

    return (
      <div key={`${message._id}-${index}`} className="w-full clear-both mb-4">
        <div
          className={`max-w-[80%] rounded-lg p-3 my-2 ${
            isSystem
              ? 'ml-auto bg-blue-50 float-right'
              : 'mr-auto bg-gray-50 float-left'
          }`}
        >
          <div className="whitespace-pre-line mb-2">
            {message.type === 'Text' ? (
              message.content
            ) : message.type === 'Image' ? (
              <div className="max-w-xs">
                <img
                  src={message.url}
                  alt={message.content}
                  className="max-w-full rounded-lg"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/placeholder-image.png';
                  }}
                />
              </div>
            ) : (
              <span className="text-sm text-gray-500">Unsupported message type</span>
            )}
          </div>
          <div className="flex justify-between items-center text-xs text-gray-500 gap-1">
            <span>{message.source}</span>
            <span>{moment(message.date).format('MMM D, h:mm A')}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[650px] bg-white shadow-md rounded-lg overflow-hidden mt-4">
      <div className="p-4 bg-[#00b4b4] text-white">
        <Typography variant="h6" className="font-bold text-white">
          {chatTitle}
        </Typography>
      </div>

      <div
        ref={listRef}
        className="flex-grow overflow-y-auto p-4 space-y-2"
        style={{
          background: 'linear-gradient(to bottom, #f0f0f0 0%, #ffffff 100%)'
        }}
      >
        {isLoading && page === 0 ? (
          <div className="flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#00b4b4]" />
          </div>
        ) : (
          <>
            {isLoading && hasMore && (
              <div className="flex justify-center py-2">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#00b4b4]" />
              </div>
            )}

            {conversations.map(renderMessageBubble)}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      <div className="p-4 bg-white border-t">
        <div className="flex gap-2">
          <div className="flex-1 relative">
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  if (newMessage.trim() && !isSending) {
                    sendMessage(newMessage);
                  }
                }
              }}
              disabled={isSending}
              rows={3}
              placeholder="Type a message..."
              className="w-full px-3 py-2 border border-[#00b4b4] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00b4b4] disabled:opacity-50 resize-none"
            />
            <button
              onClick={() => {
                if (newMessage.trim() && !isSending) {
                  sendMessage(newMessage);
                }
              }}
              disabled={!newMessage.trim() || isSending}
              className="absolute bottom-2 right-2"
            >
              <SendIcon 
                className={
                  newMessage.trim() && !isSending
                    ? 'text-[#00b4b4]'
                    : 'text-gray-400'
                }
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsAppChat;