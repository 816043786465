import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const PaymentStatusFilter = ({ paymentStatus, setPaymentStatus }) => {
  const paymentStatusOptions = [
    { id: 'Paid', name: 'Paid' },
    { id: 'UnPaid', name: 'UnPaid' }
  ];

  return (
    <FilterSearch
      options={paymentStatusOptions}
      selectedItems={paymentStatus}
      onItemsChange={setPaymentStatus}
      placeholder="Payment Status"
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default PaymentStatusFilter;