import React, { useEffect, useState } from 'react';
import {
  getReservations,
  getListings,
  resolveUnmappedReservation,
  getCities,
  getCountries,
} from '../services/serverApi.reservation';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import FilterDialog from './components/componentsFiltrage/FilterDialog';
import StyledCard from '../assets/styles/StyledCard';
import GlobalTable from '../../../components/GlobalTable/GlobalTable';
import settingSvgIcon from '../assets/icons/settingSvgIcon.svg';
import resetSvgIcon from '../assets/icons/resetSvgIcon.svg';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Button } from '@mui/material';
import QuickFilterDropdown from './components/componentsFiltrage/QuickFilterDropdown';
import ReservationActions from './components/componentsFiltrage/ReservationActions';
import ReservationColumns from './components/componentsFiltrage/ReservationColumns';
import handleQuickFilter from './components/componentsFiltrage/handleQuickFilter';
import {
  quickFilterOptions,
  dateTypeOptions,
} from './components/componentsFiltrage/filterOptions';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import StatusFilter from './components/componentsFiltrage/StatusFilter';
import PaymentStatusFilter from './components/componentsFiltrage/PaymentStatusFilter';
import TimelineFilter from './components/componentsFiltrage/TimelineFilter';
import ReservationNumberFilter from './components/componentsFiltrage/ReservationNumberFilter';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ColumnVisibilityManager from './components/componentsFiltrage/ColumnVisibilityManager';
import UnmappedReservationDialog from './components/unmappedReservations/UnmappedReservationDialog';
import ChannelNameFilter from './components/componentsFiltrage/ChannelNameFilter';
import ListingFilter from './components/componentsFiltrage/ListingFilter';
import CityFilter from './components/componentsFiltrage/CityFilter';
import CountryFilter from './components/componentsFiltrage/CountryFilter';


const ReservationPage = () => {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [rowsPerPageOptions] = useState([20, 50, 100]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dateType, setDateType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [phone, setPhone] = useState('');
  const [idType, setIdType] = useState('sojori');
  const [listingId, setListingId] = useState('');
  const [listings, setListings] = useState([]);
  const [quickFilter, setQuickFilter] = useState(null);
  const [status, setStatus] = useState(['Pending', 'Confirmed']);
  const [reservationNumber, setReservationNumber] = useState('');
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [previousReservationNumber, setPreviousReservationNumber] = useState('');
  const [showFilters, setShowFilters] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [resolveLoading, setResolveLoading] = useState(false);
  const [channelName, setChannelName] = useState([]);
  const [selectedListings, setSelectedListings] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);



  useEffect(() => {
    const allColumns = ReservationColumns({
      handleDetailsClick,
      handleGuestClick,
      handleCalendarClick,
      handleTaskClick,
      openSnackbar,
      handleListingClick: (listingData) => {
        console.log('Listing Data:', listingData);
        navigate('/admin/Listing/Listing_Details', {
          state: { data: listingData }
        });
      }
    });
    setVisibleColumns(allColumns);
  }, []);


  useEffect(() => {
    fetchListings();
    fetchCities();
    fetchCountries();
  }, [staging]);

  useEffect(() => {
    console.log("Date values in effect:", {
      dateType,
      startDate,
      endDate
    });

    if (reservationNumber !== previousReservationNumber) {
      setPreviousReservationNumber(reservationNumber);
      if (reservationNumber) {
        setStatus([]);
      } else if (!reservationNumber && previousReservationNumber) {
        setStatus(['Pending', 'Confirmed']);
      }
    } else {
      fetchReservations({
        limit, dateType, startDate, endDate, idType, guestName: globalFilter, page, status: status.join(','), reservationNumber, phone, staging, paymentStatus: paymentStatus.join(','), timeLine: timeLine.join(','), channelName: channelName.join(','),
        listings: selectedListings,
        cities: selectedCities,
        countryNames: selectedCountries.map(country => country.name)
      });
    }
    console.log("selectedListings:", selectedListings);
    console.log("selectedCities:", selectedCities);
  }, [page, limit, globalFilter, dateType, startDate, endDate, idType, listingId, status, reservationNumber, phone, staging, paymentStatus, timeLine, previousReservationNumber, channelName, selectedListings, selectedCities, selectedCountries
  ]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const openSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  const fetchListings = async () => {
    try {
      const listingsData = await getListings(staging);
      setListings(listingsData);
    } catch (error) {
      console.error('Error fetching listings:', error);
      setError(error.message || 'Failed to fetch listings.');
    }
  };

  const fetchCities = async () => {
    try {
      const response = await getCities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Failed to fetch cities. Please try again.');
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      if (Array.isArray(response.data)) {
        const formattedCountries = response.data.map(country => ({
          id: country.name,
          name: country.name,
          countryCode: country.countryCode,
          currency: country.currency
        }));
        setCountries(formattedCountries);
      } else {
        console.error('Unexpected country data format:', response.data);
        setError('Invalid country data format');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setError('Failed to fetch countries');
    }
  };

  const fetchReservations = async (params) => {
    setLoading(true);
    setError(null);
    try {
      const reservationsData = await getReservations(params);
      const processedReservations = reservationsData.map(reservation => {
        return {
          ...reservation,
          rowClassName: reservation.isUnmapped ? '!bg-red-100 hover:!bg-red-200' : ''
        };
      });

      setReservations(processedReservations);
    } catch (error) {
      setError(error.message || 'Failed to fetch reservations.');
      setReservations([]);
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const applyModalFilter = (filters) => {
    setGlobalFilter(filters.globalFilter);
    setDateType(filters.dateType);
    setStartDate(filters.startDate);
    setEndDate(filters.endDate);
    setIdType('sojori');
    setListingId(filters.listingId.id);
    setPhone(filters.phone);
    setPage(0);
    setModalVisible(false);
  };
  const clearFilter = () => {
    setQuickFilter(null);
    setGlobalFilter('');
    setDateType('');
    setStartDate(null);
    setEndDate(null);
    setIdType('sojori');
    setListingId('');
    setStatus(['Pending', 'Confirmed']);
    setPaymentStatus([]);
    setTimeLine([]);
    setPhone('');
    setPage(0);
    setReservationNumber('');
    setChannelName([]);
    setSelectedListings([]);
    setSelectedCities([]);
    setSelectedCountries([]);
  };

  const handleDetailsClick = (reservationId) => {
    navigate(`/admin/Reservation/${reservationId}/details`);
  };

  const handleGuestClick = (reservationId) => {
    navigate(`/admin/Reservation/ChekinDetails/${reservationId}`);
  };

  const handleCreateReservation = () => {
    navigate('/admin/Reservation/create');
  };


  const handleCalendarClick = (listingId, checkInDate, checkOutDate) => {
    const formattedCheckIn = moment(checkInDate).format('YYYY-MM-DD');
    const formattedCheckOut = moment(checkOutDate).format('YYYY-MM-DD');
    navigate(`/admin/calendar/Inventory/${listingId}?checkIn=${formattedCheckIn}&checkOut=${formattedCheckOut}`);
  };
  const handleTaskClick = (reservationNumber) => {
    navigate(`/admin/Tasks?reservationNumber=${reservationNumber}`);
  };

  const handleUnmappedReservationClick = (reservation) => {

    if (reservation.isUnmapped) {
      setSelectedReservation(reservation);
      setDialogOpen(true);
    }
  };

  const handleResolveConfirm = async (reservationId) => {
    setResolveLoading(true);
    try {
      await resolveUnmappedReservation(reservationId);
      setDialogOpen(false);
      setSelectedReservation(null);
      fetchReservations({
        limit, dateType, startDate, endDate, idType, listingId, guestName: globalFilter, page, status: status.join(','), reservationNumber, phone, staging, paymentStatus: paymentStatus.join(','), timeLine: timeLine.join(','),
        channelName: channelName.join(','),
        listings: selectedListings,
        cities: selectedCities,
        countryNames: selectedCountries.map(country => country.name)
      });
      openSnackbar('Reservation resolved successfully');
    } catch (error) {
      console.error('Error resolving reservation:', error);
      openSnackbar(error.response?.data?.message || 'Failed to resolve reservation');
    } finally {
      setResolveLoading(false);
    }
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <StyledCard>
        <div className="card p-2 md:!p-6 mt-3">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <div id="fixed_control_bar" className="w-full">
              <div className="flex flex-row justify-between items-center p-2 md:p-4">
                <h5 className="text-[#676a6c] text-[1.1rem] sm:text-[1.3rem] font-bold">
                  Reservations
                </h5>
                <ReservationActions onCreateReservation={handleCreateReservation} />
              </div>
            </div>
          </Box>

          <div className="flex flex-row justify-between items-center gap-3 sm:gap-2 mb-2 md:mb-4">
            <div className="flex flex-wrap items-center gap-2 w-full sm:w-auto">
              <div className="flex items-center gap-2">
                <Button
                  onClick={() => setShowFilters(!showFilters)}
                  className="!p-button-text !p-1 !rounded !my-1 sm:my-3 !bg-medium-aquamarine !h-9 !px-4 !justify-center"
                >
                  <span className="!text-white !text-xs !font-medium">
                    Filter {showFilters ? '▼' : '▶'}
                  </span>
                </Button>
                <Button
                  onClick={openModal}
                  className="!p-button-text !p-1 !rounded !my-1 sm:my-3 !bg-medium-aquamarine !h-9 !w-32 !justify-center"
                >
                  <img src={settingSvgIcon} alt="settings" className="!mr-1.5" />
                  <em className="!text-white !text-xs !font-medium">More Filters</em>
                </Button>
                <Button
                  onClick={clearFilter}
                  className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !h-9 !w-9 !justify-center"
                >
                  <img src={resetSvgIcon} alt="reset" className="mr-2" />
                </Button>
              </div>
            </div>
            <ColumnVisibilityManager
              columns={ReservationColumns({
                handleDetailsClick,
                handleGuestClick,
                handleCalendarClick,
                handleTaskClick,
                openSnackbar,
                handleListingClick: (listingData) => {
                  navigate('/admin/Listing/Listing_Details', {
                    state: { data: listingData }
                  });
                }
              })}
              setVisibleColumns={setVisibleColumns}
            />
          </div>
          {showFilters && (
            <div className="mb-2">
              <div className="grid grid-cols-2 sm:flex sm:flex-wrap gap-2">
                <div className="flex items-center">
                  <ReservationNumberFilter
                    reservationNumber={reservationNumber}
                    setReservationNumber={setReservationNumber}
                  />
                </div>
                <div className="flex items-center">
                  <CountryFilter
                    countries={countries}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                  />
                </div>

                <div className="flex items-center">
                  <CityFilter
                    cities={cities}
                    selectedCities={selectedCities}
                    setSelectedCities={setSelectedCities}
                  />
                </div>
                <div className="flex items-center">
                  <ListingFilter
                    listings={listings}
                    selectedListings={selectedListings}
                    setSelectedListings={setSelectedListings}
                  />
                </div>
                <div className="flex items-center">
                  <ChannelNameFilter
                    channelName={channelName}
                    setChannelName={setChannelName}
                  />
                </div>
                <div className="flex items-center">
                  <StatusFilter status={status} setStatus={setStatus} />
                </div>

                {/* <div className="flex items-center">
                  <QuickFilterDropdown
                    quickFilter={quickFilter}
                    setQuickFilter={setQuickFilter}
                    quickFilterOptions={quickFilterOptions}
                    clearFilter={clearFilter}
                    onQuickFilterChange={(value) =>
                      handleQuickFilter(value, setDateType, setStartDate, setEndDate, setPage)
                    }
                  />
                </div> */}
                <div className="flex items-center">
                  <PaymentStatusFilter
                    paymentStatus={paymentStatus}
                    setPaymentStatus={setPaymentStatus}
                  />
                </div>
                <div className="flex items-center">
                  <TimelineFilter timeLine={timeLine} setTimeLine={setTimeLine} />
                </div>

              </div>
            </div>
          )}

          {loading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress sx={{ color: '#00b4b4' }} />
            </div>
          ) : (
            <GlobalTable
              data={reservations}
              columns={visibleColumns}
              page={page}
              onPageChange={setPage}
              isNextDisabled={loading || reservations.length < limit}
              hasPagination={true}
              limit={limit}
              onLimitChange={setLimit}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowClick={handleUnmappedReservationClick}
              getRowClassName={(row) => row.rowClassName}
            />

          )}
          <UnmappedReservationDialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              setSelectedReservation(null);
            }}
            onConfirm={handleResolveConfirm}
            loading={resolveLoading}
            reservationId={selectedReservation?.id}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

          <FilterDialog
            visible={modalVisible}
            onHide={closeModal}
            onApply={applyModalFilter}
            initialFilters={{
              globalFilter,
              dateType,
              startDate,
              endDate,
              listingId: listings.find((listing) => listing.id === listingId),
              phone,
            }}
            dateTypeOptions={dateTypeOptions}
            listings={listings}
          />
        </div>
      </StyledCard>
    </DashboardLayout>
  );
};

export default ReservationPage;
