import React, { useState, useEffect } from 'react';
import { getCountries, getCities } from 'features/reservation/services/serverApi.reservation';

const ReservationDetails = ({ editableDetails, isEditMode, handleInputChange }) => {
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

useEffect(() => {
    const fetchData = async () => {
        try {
            const countriesData = await getCountries();
            setCountries(countriesData.data);

            const citiesData = await getCities();
            setCities(citiesData.data);

        } catch (error) {
            console.error('Error fetching country and city data:', error);
        }
    };

    fetchData();
}, []);

    const handleBooleanFieldChange = (name, value) => {
        const boolValue = value === "" ? false : value === "true";
        handleInputChange({
            target: {
                name,
                value: boolValue
            }
        });
    };

    const renderInputField = (label, name, type = 'text', additionalProps = {}) => {
        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <input
                    type={type}
                    name={name}
                    onChange={handleInputChange}
                    value={editableDetails[name]}
                    readOnly={!isEditMode}
                    list={name === 'guestCountry' ? 'countries' : name === 'guestCity' ? 'cities' : undefined}
                    autoComplete="off"
                    {...additionalProps}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                    style={{
                        backgroundColor: (!isEditMode) ? '#eee' : 'white',
                        border: (!isEditMode) ? '1px solid #eee' : '1px solid #ccc',
                        fontSize: 'small',
                    }}
                />
                {name === 'guestCountry' && (
                    <datalist id="countries" autoComplete="off">
                        {countries.map(country => (
                            <option key={country.id} value={country.name} />
                        ))}
                    </datalist>
                )}
                {name === 'guestCity' && (
                    <datalist id="cities" autoComplete="off">
                        {cities.map(city => (
                            <option key={city.id} value={city.name} />
                        ))}
                    </datalist>
                )}
            </div>
        );
    };

    const renderSelectField = (label, name, options, isBoolean = false) => {
        let currentValue;
        if (isBoolean) {
            currentValue = editableDetails[name] === "" ? "false" : editableDetails[name].toString();
        } else {
            currentValue = editableDetails[name] != null ? editableDetails[name].toString() : '';
        }

        const optionsWithCurrent = [...options];
        if (!isBoolean && currentValue && !options.some(opt => opt.value === currentValue)) {
            optionsWithCurrent.push({ value: currentValue, label: currentValue });
        }

        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <select
                    name={name}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
                    value={currentValue}
                    readOnly={!isEditMode}
                    disabled={!isEditMode}
                    onChange={(e) => {
                        if (isBoolean) {
                            handleBooleanFieldChange(name, e.target.value);
                        } else {
                            handleInputChange(e);
                        }
                    }}
                    style={{
                        backgroundColor: isEditMode ? 'white' : '#eee',
                        border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                        fontSize: 'small',
                    }}
                >
                    {isBoolean ? (
                        <>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </>
                    ) : (
                        <>
                            <option value="">Select {label}</option>
                            {optionsWithCurrent.map(option => (
                                <option
                                    key={option.value}
                                    value={option.value}
                                    disabled={['CancelledByAdmin', 'CancelledByCustomer', 'CancelledByOta', 'CancelledAfterFailedPayment', 'OtherCancellation'].includes(option.value)}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </>
                    )}
                </select>
            </div>
        );
    };

    const renderTimeSelectField = (label, name) => {
        const hours = Array.from({ length: 24 }, (_, i) => i);
        const getValue = () => {
            const time = editableDetails[name];
            if (!time) return "";
            return parseInt(time.split(':')[0]) || 0;
        };
        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <select
                    name={name}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
                    value={getValue()}
                    readOnly={!isEditMode}
                    onChange={handleInputChange}
                    style={{
                        backgroundColor: isEditMode ? 'white' : '#eee',
                        border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                        fontSize: 'small',
                    }}
                >
                    {hours.map(hour => (
                        <option key={hour} value={hour}>
                            {`${hour.toString().padStart(2, '0')}:00`}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {renderInputField('Check-in date (Listing time)', 'arrivalDate', 'date')}
                {renderInputField('Check-out date (Listing time)', 'departureDate', 'date')}
                {renderTimeSelectField('Check-in time', 'checkInTime')}
                {renderTimeSelectField('Check-out time', 'checkOutTime')}
                {renderSelectField('Confirmed Check-in Time', 'confirmedCheckInTime', [], true)}
                {renderSelectField('Confirmed Check-out Time', 'confirmedCheckOutTime', [], true)}
                {renderSelectField('Currency', 'currency', [{ value: 'MAD', label: 'MAD' }, { value: 'USD', label: 'USD' }, { value: 'EUR', label: 'EUR' }, { value: 'GBP', label: 'GBP' }])}
                {renderSelectField('Status', 'status', [
                    { value: 'Pending', label: 'Pending' },
                    { value: 'Confirmed', label: 'Confirmed' },
                    { value: 'Completed', label: 'Completed' },
                    { value: 'CancelledByAdmin', label: 'Cancelled by Admin' },
                    { value: 'CancelledByCustomer', label: 'Cancelled by Customer' },
                    { value: 'CancelledByOta', label: 'Cancelled by OTA' },
                    { value: 'CancelledAfterFailedPayment', label: 'Cancelled After Failed Payment' },
                    { value: 'OtherCancellation', label: 'Other Cancellation' }
                ])}
                {renderSelectField('Payment Status', 'paymentStatus', [{ value: 'Paid', label: 'Paid' }, { value: 'UnPaid', label: 'UnPaid' }])}
                {renderSelectField('Time Line', 'timeLine', [
                    { value: 'Coming-Today', label: 'Coming Today' },
                    { value: 'Coming-Tomorrow', label: 'Coming Tomorrow' },
                    { value: 'Coming-in2days', label: 'Coming in 2 days' },
                    { value: 'Coming-in3days', label: 'Coming in 3 days' },
                    { value: 'Normal', label: 'Normal' },
                    { value: 'Departure-today', label: 'Departure Today' },
                    { value: 'Departure-tomorrow', label: 'Departure Tomorrow' },
                    { value: 'Inside', label: 'Inside' },
                    { value: 'Leave', label: 'Leave' },
                    { value: 'One-Night', label: 'One Night' }
                ])}
                {renderInputField('Guest Country', 'guestCountry')}
                {renderInputField('Guest City', 'guestCity')}
            </div>
            <div className="flex justify-end mt-4">
                <button className="px-2 py-1 bg-[#dcf6f6] !rounded-sm flex items-center gap-2">
                    <i className="pi pi-download text-[#00b4b4] text-xs"></i>
                    <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px]">Download invoice</span>
                </button>
            </div>
        </>
    );
};

export default ReservationDetails;