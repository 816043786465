import React, { useState, useEffect } from 'react';
import { 
  Checkbox, 
  FormControlLabel, 
  Menu, 
  IconButton,
  Box,
  Button
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ColumnVisibilityManager = ({ columns, setVisibleColumns }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderedColumns, setOrderedColumns] = useState(() => {
    const savedOrder = localStorage.getItem('tableColumnOrder');
    if (savedOrder) {
      try {
        const parsed = JSON.parse(savedOrder);
        // Create a map of saved preferences
        const savedPrefs = parsed.reduce((acc, col) => ({
          ...acc,
          [col.key]: col
        }), {});
        
        // Map current columns with saved preferences
        return columns.map(col => {
          const key = col.field || col.uniqueId || col.header;
          const savedPref = savedPrefs[key];
          return {
            key,
            displayLabel: typeof col.header === 'function' ? 
              (col.headerLabel || col.field || 'Column') : 
              col.header,
            visible: savedPref ? savedPref.visible : true,
            order: savedPref ? savedPref.order : null
          };
        }).sort((a, b) => {
          if (a.order === null) return 1;
          if (b.order === null) return -1;
          return a.order - b.order;
        });
      } catch (error) {
        console.error('Error parsing saved column order:', error);
      }
    }
    
    // Default state if no saved preferences or error occurred
    return columns.map((col, index) => ({
      key: col.field || col.uniqueId || col.header,
      displayLabel: typeof col.header === 'function' ? 
        (col.headerLabel || col.field || 'Column') : 
        col.header,
      visible: true,
      order: index
    }));
  });

  useEffect(() => {
    // Save only necessary data
    const columnPrefs = orderedColumns.map((col, index) => ({
      key: col.key,
      visible: col.visible,
      order: index
    }));
    localStorage.setItem('tableColumnOrder', JSON.stringify(columnPrefs));

    // Apply ordering to original columns
    const orderedOriginalColumns = orderedColumns
      .filter(col => col.visible)
      .map(orderedCol => 
        columns.find(originalCol => 
          (originalCol.field || originalCol.uniqueId || originalCol.header) === orderedCol.key
        )
      )
      .filter(Boolean);

    setVisibleColumns(orderedOriginalColumns);
  }, [orderedColumns, columns, setVisibleColumns]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumnToggle = (columnKey) => {
    setOrderedColumns(prev => 
      prev.map(col => 
        col.key === columnKey 
          ? { ...col, visible: !col.visible }
          : col
      )
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(orderedColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderedColumns(items);
  };

  const resetToDefault = () => {
    const defaultColumns = columns.map((col, index) => ({
      key: col.field || col.uniqueId || col.header,
      displayLabel: typeof col.header === 'function' ? 
        (col.headerLabel || col.field || 'Column') : 
        col.header,
      visible: true,
      order: index
    }));
    setOrderedColumns(defaultColumns);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        className="hover:bg-gray-100 focus:outline-none"
        size="small"
      >
        <SettingsIcon className="text-gray-600" />
      </IconButton>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: "mt-2 shadow-lg",
          style: {
            maxHeight: 400,
            width: 300,
          }
        }}
      >
        <Box className="p-4 space-y-2">
          <div className="flex justify-between items-center mb-4">
            <span className="text-sm font-semibold text-gray-700">Column Settings</span>
            <Button 
              onClick={resetToDefault}
              size="small"
              className="text-blue-500 hover:bg-blue-50"
            >
              Reset
            </Button>
          </div>
          
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columns">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-1"
                >
                  {orderedColumns.map((column, index) => (
                    <Draggable 
                      key={column.key} 
                      draggableId={column.key} 
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="flex items-center bg-white hover:bg-gray-50 rounded-md"
                        >
                          <div {...provided.dragHandleProps} className="px-2">
                            <DragIndicatorIcon className="text-gray-400" />
                          </div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={column.visible}
                                onChange={() => handleColumnToggle(column.key)}
                                sx={{
                                  color: '#64748b',
                                  '&.Mui-checked': {
                                    color: '#0ea5e9',
                                  },
                                  padding: '4px',
                                }}
                                size="small"
                              />
                            }
                            label={
                              <span className="text-sm font-medium text-gray-700">
                                {column.displayLabel}
                              </span>
                            }
                            className="flex-1 m-0 py-1 px-2"
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Menu>
    </div>
  );
};

export default ColumnVisibilityManager;
