import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const ChannelNameFilter = ({ channelName, setChannelName }) => {
  const channelOptions = [
    { id: 'sojori', name: 'Sojori' },
    { id: 'BookingCom', name: 'Booking' },
    { id: 'AirBNB', name: 'AirBNB' }
  ];

  return (
    <FilterSearch
      options={channelOptions}
      selectedItems={channelName}
      onItemsChange={setChannelName}
      placeholder="Channel"
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default ChannelNameFilter;