import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormControl,
    InputLabel,
    Button,
    TextField,
    Box
} from '@mui/material';
import {
    searchListings,
    getListingsTa,
    getTaskConfigs
} from '../../services/serverApi.task';
import AddTask from './AddTask';
import AddTaskButton from './AddTaskButton';
import { useLocation, useNavigate } from 'react-router-dom';
import resetSvgIcon from 'assets/images/resetSvgIcon.svg';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const FilterTask = ({
    onFilterChange,
    onSaveTask,
    staff,
    initialFilters = {}
}) => {
    const [filters, setFilters] = useState({
        type: [],
        subType: [],
        status: ["PENDING", "CONFIRMED"],
        assignmentStatus: [],
        listingId: initialFilters.listingId || '',
        reservationNumber: initialFilters.reservationNumber || '',
        searchByGuest: initialFilters.searchByGuest || ''
    });

    const filterRef = useRef(null);
    const [listingOptions, setListingOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
    const [isAddTask, setIsAddTask] = useState(false);
    const [listings, setListings] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(true);

    const location = useLocation();
    const navigate = useNavigate();
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

    useEffect(() => {
        let searchParams = new URLSearchParams();

        if (filters.reservationNumber) {
            searchParams.set('reservationNumber', filters.reservationNumber);
        }
        if (filters.listingId) {
            searchParams.set('listingId', filters.listingId);
        }
        if (filters.searchByGuest) {
            searchParams.set('searchByGuest', filters.searchByGuest);
        }

        navigate(`/admin/Tasks?${searchParams.toString()}`, { replace: true });

        onFilterChange(filters);
    }, [
        filters.type,
        filters.subType,
        filters.status,
        filters.assignmentStatus,
        filters.listingId,
        filters.reservationNumber,
        filters.searchByGuest
    ]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const reservationNumber = searchParams.get('reservationNumber');
        const listingId = searchParams.get('listingId');
        const searchByGuest = searchParams.get('searchByGuest');

        setFilters(prevFilters => ({
            ...prevFilters,
            reservationNumber: reservationNumber || '',
            listingId: listingId || '',
            searchByGuest: searchByGuest || ''
        }));
    }, [location]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchListings();
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, staging]);

    useEffect(() => {
        fetchTaskTypes();
        fetchListingOptions();
    }, [staging]);

    const fetchListingOptions = async () => {
        try {
            const listings = await getListingsTa(staging);
            setListings(listings);

            if (filters.listingId) {
                const selectedListing = listings.find(listing => listing._id === filters.listingId);
                if (selectedListing) {
                    setListingOptions([{
                        label: selectedListing.name,
                        value: selectedListing._id
                    }]);
                }
            }
        } catch (error) {
            console.error('Error fetching listing options:', error);
        }
    };

    const fetchTaskTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getTaskConfigs();
            if (response && Array.isArray(response)) {
                setTaskTypes(response);
            } else {
                setTaskTypes([]);
            }
        } catch (error) {
            console.error('Error fetching task config:', error);
            setTaskTypes([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchListings = async () => {
        try {
            const response = await searchListings({
                name: searchTerm,
                limit: 20,
                staging
            });
            if (response.data) {
                setListingOptions(response.data.map(listing => ({
                    label: listing.name,
                    value: listing._id
                })));
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
            setListingOptions([]);
        }
    };

    const handleFilterChange = (field, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [field]: value
        }));
    };

    const handleClearFilters = () => {
        const clearedFilters = {
            type: [],
            subType: [],
            status: ['PENDING', 'CONFIRMED'],
            assignmentStatus: [],
            listingId: '',
            reservationNumber: '',
            searchByGuest: ''
        };
        setFilters(clearedFilters);
        navigate('/admin/Tasks', { replace: true });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOpenAddTaskModal = () => {
        setOpenAddTaskModal(true);
        setIsAddTask(true);
    };

    const handleCloseAddTaskModal = () => {
        setOpenAddTaskModal(false);
    };

    const renderMultiSelect = (field, label, options) => {
        let selectOptions = [];

        if (field === 'type') {
            selectOptions = options.map(option => ({
                id: option.task,
                name: option.task
            }));
        } else if (field === 'subType') {
            selectOptions = options.flatMap(type =>
                type.subs.map(sub => ({
                    id: sub.type,
                    name: sub.type
                }))
            );
        } else {
            selectOptions = options.map(option => ({
                id: option,
                name: option
            }));
        }

        return (
            <FilterSearch
                options={selectOptions}
                selectedItems={filters[field]}
                onItemsChange={(value) => handleFilterChange(field, value)}
                placeholder={label}
                idKey="id"
                labelKey="name"
                width={250}
            />
        );
    };

    return (
        <>
            <div className='flex justify-between items-center px-4 py-2'>
                <div className="flex gap-2 items-center">
                    <Button
                        onClick={() => setShowFilters(!showFilters)}
                        className="!p-button-text !p-1 !rounded !my-1 sm:my-3 !bg-medium-aquamarine !h-9 !px-4 !justify-center"
                    >
                        <span className="!text-white !text-xs !font-medium">
                            Filter {showFilters ? '▼' : '▶'}
                        </span>
                    </Button>
                    {/* <div className="col-span-6 flex items-center justify-end space-x-2 gap-2">
                        <Button
                            onClick={handleClearFilters}
                            variant="outlined"
                            style={{ borderColor: '#66dac3', color: '#66dac3' }}
                            size="small"
                        >
                            Clear Filters
                        </Button>
                    </div> */}

                    <Button
                        onClick={handleClearFilters}
                        className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !h-9 !w-9 !justify-center"
                    >
                        <img src={resetSvgIcon} alt="reset" className="mr-2" />
                    </Button>

                </div>
                <div>
                    <AddTaskButton onClick={handleOpenAddTaskModal} />
                </div>
            </div>

            {showFilters && (
                <div
                    ref={filterRef}
                    className="w-full p-4"
                >
                    <div className="flex flex-wrap gap-4">
                        <div className="">
                            <FormControl fullWidth size="small">
                                <TextField
                                    label="Search by Guest"
                                    value={filters.searchByGuest}
                                    onChange={(e) => handleFilterChange('searchByGuest', e.target.value)}
                                    size="small"
                                    inputProps={{
                                        style: { height: '20px' }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="">
                            <FormControl fullWidth size="small">
                                <TextField
                                    label="Search"
                                    value={filters.reservationNumber}
                                    onChange={(e) => handleFilterChange('reservationNumber', e.target.value)}
                                    size="small"
                                    inputProps={{
                                        style: { height: '20px' }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div className="w-[200px]">
                            <FormControl fullWidth size="small">
                                <InputLabel>Select a Listing</InputLabel>
                                <Select
                                    value={filters.listingId}
                                    onChange={(e) => handleFilterChange('listingId', e.target.value)}
                                    label="Select a Listing"
                                    onOpen={() => setSearchTerm('')}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    <Box className="bg-gray-50">
                                        <TextField
                                            size="small"
                                            placeholder="Search listings..."
                                            className='w-full p-1 !rounded-md focus:outline-none'
                                            onChange={(e) => handleFilterChange('listingId', e.target.value)}
                                            InputProps={{
                                                onChange: handleSearchChange,
                                                value: searchTerm,
                                            }}
                                        />
                                    </Box>
                                    {listingOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="">
                            {renderMultiSelect('type', 'Task Types', taskTypes)}
                        </div>
                        <div className="">
                            {renderMultiSelect('status', 'Task Status', [
                                'PENDING',
                                'CONFIRMED',
                                'IN_PROGRESS',
                                'COMPLETED',
                                'CANCELED',
                                'CANCELED-BY-CUST'
                            ])}
                        </div>
                        <div className="">
                            {renderMultiSelect('subType', 'Task Sub-Types', taskTypes)}
                        </div>
                        <div className="">
                            {renderMultiSelect('assignmentStatus', 'Assignment Status', ['ASSIGNED', 'UNASSIGNED', 'ACCEPTED', 'REFUSED'])}
                        </div>
                    </div>
                </div>
            )}

            {openAddTaskModal && (
                <AddTask
                    open={openAddTaskModal}
                    onClose={handleCloseAddTaskModal}
                    onSave={onSaveTask}
                    listing={listings}
                    staff={staff}
                    isAddTask={isAddTask}
                    listingId={filters.listingId}
                />
            )}
        </>
    );
}

export default FilterTask;