import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Typography, List, ListItem, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';

function Chat({ messages = [] }) {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const listRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [showIcons, setShowIcons] = useState(false);
  const [flexDirection, setFlexDirection] = useState('row');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        setShowIcons(width < 700); 
        setFlexDirection(width < 330 ? 'column' : 'row');
      }
    });

    if (chatContainerRef.current) {
      resizeObserver.observe(chatContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sending message:', newMessage);
    setNewMessage('');
  };

  const handleApprove = (messageId) => {
    console.log('Approving message:', messageId);
  };

  const handleDismiss = (messageId) => {
    console.log('Dismissing message:', messageId);
  };

  const handleEdit = (messageId) => {
    console.log('Editing message:', messageId);
  };

  const sortedMessages = Array.isArray(messages) ? [...messages].sort((a, b) => new Date(a.date) - new Date(b.date)) : [];

  return (
    <div ref={chatContainerRef} className="flex flex-col h-full max-h-full">
      <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Chat</Typography>      
      <List ref={listRef} className="flex-grow overflow-y-auto overflow-x-hidden p-4 space-y-2 flex flex-col-reverse scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100">
        <div ref={messagesEndRef} />
        {(sortedMessages && sortedMessages.length > 0) ? (
          [...sortedMessages].reverse().map((msg) => (
            <React.Fragment key={msg._id}>
              <ListItem className={`flex flex-col ${msg.source_info.toLowerCase() === 'client' ? '!items-start' : '!items-end'} mt-4`}>
                <div className={`w-1/2 max-w-md ${msg.source_info.toLowerCase() === 'client' ? 'bg-gray-200 !text-left' : 'bg-medium-aquamarine text-white'} rounded-lg p-2`}>
                  <Typography variant="body2">{msg.message_text}</Typography>
                  <Typography variant="caption">
                    <span>{msg.source_info}</span>
                  </Typography>
                </div>
                <Typography variant="caption" component="div" className="mt-1">
                  {new Date(msg.date).toLocaleString()}
                </Typography>
                {msg.source_info === 'openAI' && (
                  <div className={`mt-2 flex gap-2 flex-${flexDirection}`}>
                    {showIcons ? (
                      <>
                        <IconButton onClick={() => handleApprove(msg._id)} className="!bg-[#1AC255] !text-white"><CheckIcon /></IconButton>
                        <IconButton onClick={() => handleDismiss(msg._id)} className="!bg-[#F2534C] !text-white"><CloseIcon /></IconButton>
                        <IconButton onClick={() => handleEdit(msg._id)} className="!bg-white !text-black border !border-sky-500"><EditIcon /></IconButton>
                      </>
                    ) : (
                      <>
                        <Button variant="contained" className="!bg-[#1AC255] !text-white" onClick={() => handleApprove(msg._id)}>Approve</Button>
                        <Button variant="contained" className="!bg-[#F2534C] !text-white" onClick={() => handleDismiss(msg._id)}>Dismiss</Button>
                        <Button variant="outlined" className="!bg-white !text-black" onClick={() => handleEdit(msg._id)}>Edit</Button>
                      </>
                    )}
                  </div>
                )}
              </ListItem>
            </React.Fragment>
          ))
        ) : (
          <Typography>No messages to display</Typography>
        )}
      </List>
      <form onSubmit={handleSubmit} className="p-4 flex gap-2 border-t border-gray-300 bg-white">
        <TextField fullWidth rows={3} maxRows={4} variant="outlined" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder="Type a message..." className="mr-2" />
        <Button type="submit" variant="contained" className="!bg-medium-aquamarine !text-white">
          {showIcons ? <SendIcon /> : 'Send'}
        </Button>
      </form>
    </div>
  );
}

export default Chat;
