import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Stack,
  Chip,
  IconButton,
  Paper,
  Grid
} from '@mui/material';
import {
  X,
  Clock,
  Calendar,
  User,
  DollarSign,
  Home,
  Users,
  MapPin,
  ChevronDown,
  ChevronUp,
  Briefcase
} from 'lucide-react';

const TaskDetails = ({ event, open, onClose }) => {
  const [expandedSections, setExpandedSections] = useState({
    reservationInfo: false
  });

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const formatTime = (time) => {
    return new Date(`2024-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderInfoSection = (icon, title, content, secondaryContent = null) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      p: 2,
      borderRadius: 2,
      backgroundColor: 'rgba(0,0,0,0.03)'
    }}>
      {icon}
      <Box sx={{ flex: 1 }}>
        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body1" fontWeight="medium" className="!text-sm">
          {content}
        </Typography>
      </Box>
      {secondaryContent}
    </Box>
  );

  const ReservationInfoSection = () => {
    if (!event?.reservationCode) return null;

    return (
      <Paper
        elevation={0}
        sx={{
          backgroundColor: 'background.default',
          p: 3,
          borderRadius: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            mb: 2
          }}
          onClick={() => toggleSection('reservationInfo')}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Home size={24} color="#2196f3" />
            <Typography variant="h6" fontWeight="bold">
              Reservation Details
            </Typography>
          </Box>
          {expandedSections.reservationInfo ? <ChevronUp /> : <ChevronDown />}
        </Box>

        {expandedSections.reservationInfo && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 1.5,
                borderRadius: 2,
                backgroundColor: 'rgba(0,0,0,0.03)'
              }}>
                <MapPin size={20} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Reservation Number
                  </Typography>
                  <Typography variant="body1" fontWeight="medium" className="!text-sm">
                    {event.reservationCode || 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 1.5,
                borderRadius: 2,
                backgroundColor: 'rgba(0,0,0,0.03)'
              }}>
                <Users size={20} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Guest Name
                  </Typography>
                  <Typography variant="body1" fontWeight="medium" className="!text-sm">
                    {event.guestName || 'Not specified'}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 1.5,
                borderRadius: 2,
                backgroundColor: 'rgba(0,0,0,0.03)'
              }}>
                <Calendar size={20} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Check-In
                  </Typography>
                  <Typography variant="body1" fontWeight="medium" className="!text-sm">
                    {formatDate(event.startDate)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                p: 1.5,
                borderRadius: 2,
                backgroundColor: 'rgba(0,0,0,0.03)'
              }}>
                <Calendar size={20} />
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    Check-Out
                  </Typography>
                  <Typography variant="body1" fontWeight="medium" className="!text-sm">
                    {formatDate(event.endDate)}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Chip
                  icon={<Users size={16} />}
                  label={`Adults: ${event.adults || '0'}, Children: ${event.children || '0'}`}
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label={event.channel || 'Airbnb'}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ p: 3, position: 'relative' }}>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'text.secondary'
          }}
        >
          <X size={20} />
        </IconButton>

        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Briefcase size={24} />
          Task Details
        </Typography>

        <Stack spacing={2}>
          {renderInfoSection(
            <User size={20} />,
            'Staff',
            event?.Staff?.username || 'Not assigned',
            <Chip
              label={event?.assignmentStatus || 'Not Assigned'}
              size="small"
              color="primary"
              variant="outlined"
            />
          )}

          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
            {renderInfoSection(
              <Calendar size={20} />,
              'Date',
              formatDate(event?.date)
            )}
            {renderInfoSection(
              <Clock size={20} />,
              'Time',
              `${formatTime(event?.checkInTime)} - ${formatTime(event?.checkOutTime)}`
            )}
          </Box>

          
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>

            {renderInfoSection(
              <DollarSign size={20} />,
              'Total Price',
              `$${event?.price || '0'}`,
              <Chip
                label={event?.paid ? 'Paid' : 'Unpaid'}
                color={event?.paid ? 'success' : 'error'}
                size="small"
              />
            )}

            {renderInfoSection(
              <Briefcase size={20} />,
              'Type',
              `${event?.type || 'Not specified'} ${event?.subType && `- ${event.subType}`}`
            )}
          </Box>

          <ReservationInfoSection />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TaskDetails;