import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, FormControlLabel, Switch } from '@mui/material';
import { getReservation, getMailTemplateById } from '../services/serverApi.adminConfig';
import DynamicFieldButtons from './DynamicFieldButtons';

const MailTemplateForm = ({ templateId = null, initialData, onSubmit, onCancel, updateFormData }) => {
    const [messageContent, setMessageContent] = useState(initialData?.content || '');
    const [messageContentEng, setMessageContentEng] = useState(initialData?.contentEng || '');
    const [isLoading, setIsLoading] = useState(false);
    const [reservationData, setReservationData] = useState(null);

    const [originalContent, setOriginalContent] = useState(initialData?.content || '');
    const [originalContentEng, setOriginalContentEng] = useState(initialData?.contentEng || '');


    useEffect(() => {
        setMessageContent(convertTemplateToDisplay(initialData?.content || ''));
        setMessageContentEng(convertTemplateToDisplay(initialData?.contentEng || ''));
        setOriginalContent(initialData?.content || '');
        setOriginalContentEng(initialData?.contentEng || '');
        if (initialData) {
            setFormData(prev => ({
                ...prev,
                type: initialData.type || prev.type,
                messageName: initialData.messageName || prev.messageName,
                enabled: initialData.enabled !== undefined ? initialData.enabled : prev.enabled
            }));
        }
    }, [initialData]);



    const emailName = [
        { value: 'CONFIRMATION_RESERVATION_STANDARD', label: 'CONFIRMATION_RESERVATION_STANDARD' },
        { value: 'CONFIRMATION_RESERVATION_AVEC_LIEN', label: 'CONFIRMATION_RESERVATION_AVEC_LIEN' },
        { value: 'RAPPEL_7_JOURS_AVANT_ARRIVEE', label: 'RAPPEL_7_JOURS_AVANT_ARRIVEE' },
        { value: 'RAPPEL_COMPLET_CLIENT', label: 'RAPPEL_COMPLET_CLIENT' },
        { value: 'RAPPEL_ENREGISTREMENT_MANQUANT', label: 'RAPPEL_ENREGISTREMENT_MANQUANT' },
        { value: 'RAPPEL_HORAIRE_MANQUANT', label: 'RAPPEL_HORAIRE_MANQUANT' },
        { value: 'RAPPEL_ACTIONS_MANQUANTES', label: 'RAPPEL_ACTIONS_MANQUANTES' },
        { value: 'RAPPEL_SERVICES_PENDANT_SEJOUR', label: 'RAPPEL_SERVICES_PENDANT_SEJOUR' },
        { value: 'RAPPEL_DEPART', label: 'RAPPEL_DEPART' },
        { value: 'REMERCIEMENT_REVIEW_POSITIF', label: 'REMERCIEMENT_REVIEW_POSITIF' },
        { value: 'REMERCIMENT_REVIEW_NEGATIF', label: 'REMERCIMENT_REVIEW_NEGATIF' },
        { value: 'SOLLICITATION_NOTE_ABSENTE', label: 'SOLLICITATION_NOTE_ABSENTE' },
    ];

    const emailTypes = [
        { value: 'Whatsapp', label: 'Whatsapp' },
        { value: 'Email', label: 'Email' },
        { value: 'Message', label: 'Message' },
        { value: 'Review', label: 'Review' },
    ];

    const [formData, setFormData] = useState({
        type: initialData?.type || 'Email',
        content: initialData?.content || '',
        contentEng: initialData?.contentEng || '',
        messageName: initialData?.messageName || 'CONFIRMATION_RESERVATION_STANDARD',
        enabled: initialData?.enabled !== undefined ? initialData.enabled : true // Explicit check for undefined
    });

    useEffect(() => {
        updateFormData({
            type: formData.type,
            content: messageContent,
            contentEng: messageContentEng,
            messageName: formData.messageName,
            enabled: formData.enabled


        });
    }, [formData.type, messageContent, messageContentEng, formData.messageName, formData.enabled]);


    const availableFields = [
        { label: 'Guest Full Name', value: '${guestName}', display: '[GUEST_NAME]' },
        { label: 'Guest First Name', value: '${guestFirstName}', display: '[GUEST_FIRST_NAME]' },
        { label: 'Guest Last Name', value: '${guestLastName}', display: '[GUEST_LAST_NAME]' },
        { label: 'Guest Email', value: '${guestEmail}', display: '[GUEST_EMAIL]' },
        { label: 'Guest Phone', value: '${phone}', display: '[GUEST_PHONE]' },
        { label: 'Guest Address', value: '${guestAddress}', display: '[GUEST_ADDRESS]' },
        { label: 'Guest City', value: '${guestCity}', display: '[GUEST_CITY]' },
        { label: 'Guest Country', value: '${guestCountry}', display: '[GUEST_COUNTRY]' },
        { label: 'Nationality', value: '${nationality}', display: '[NATIONALITY]' },
        { label: 'Reservation Number', value: '${reservationNumber}', display: '[RESERVATION_NUMBER]' },
        { label: 'Number of Guests', value: '${numberOfGuests}', display: '[NUMBER_OF_GUESTS]' },
        { label: 'Number of Nights', value: '${nights}', display: '[NIGHTS]' },
        { label: 'Arrival Date', value: '${arrivalDate}', display: '[ARRIVAL_DATE]' },
        { label: 'Departure Date', value: '${departureDate}', display: '[DEPARTURE_DATE]' },
        { label: 'Check-in Time', value: '${checkInTime}', display: '[CHECK_IN_TIME]' },
        { label: 'Check-out Time', value: '${checkOutTime}', display: '[CHECK_OUT_TIME]' },
        { label: 'Total Price', value: '${totalPrice}', display: '[TOTAL_PRICE]' },
        { label: 'Currency', value: '${currency}', display: '[CURRENCY]' },
        { label: 'Payment Status', value: '${paymentStatus}', display: '[PAYMENT_STATUS]' },
        { label: 'Payment Method', value: '${paymentMethod}', display: '[PAYMENT_METHOD]' },
        { label: 'Door Code', value: '${doorCode}', display: '[DOOR_CODE]' },
        { label: 'Listing Name', value: '${listing.name}', display: '[LISTING_NAME]', },
    ];

    useEffect(() => {
        fetchReservationData();
    }, []);

    const convertTemplateToDisplay = (content) => {
        let displayContent = content || '';
        availableFields.forEach(field => {
            displayContent = displayContent.replace(
                new RegExp(escapeRegExp(field.value), 'g'),
                field.display
            );
        });
        return displayContent;
    };

    const convertDisplayToTemplate = (content) => {
        let templateContent = content || '';
        availableFields.forEach(field => {
            templateContent = templateContent.replace(
                new RegExp(escapeRegExp(field.display), 'g'),
                field.value
            );
        });
        return templateContent;
    };
    const fetchReservationData = async () => {
        try {
            const response = await getReservation(1, 0);
            if (response.data.success) {
                setReservationData(response.data.data[0]);
            }
        } catch (error) {
            console.error('Error fetching reservation:', error);
        }
    };

    const insertField = (field, isEnglish = false) => {
        const textarea = isEnglish
            ? document.querySelector('textarea[name="contentEng"]')
            : document.querySelector('textarea[name="content"]');

        const currentContent = isEnglish ? messageContentEng : messageContent;
        const cursorPosition = textarea?.selectionStart || currentContent.length;
        const textBeforeCursor = currentContent.slice(0, cursorPosition);

        const needsSpace = cursorPosition > 0 &&
            textBeforeCursor.slice(-1) !== ' ' &&
            textBeforeCursor.slice(-1) !== '\n';

        const fieldToInsert = needsSpace ? ` ${field.display}` : field.display;
        const newContent = currentContent.slice(0, cursorPosition) +
            fieldToInsert +
            currentContent.slice(cursorPosition);

        if (isEnglish) {
            setMessageContentEng(newContent);
        } else {
            setMessageContent(newContent);
        }
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, key) => (acc ? acc[key] : ''), obj);
    };

    const getPreviewContent = (isEnglish = false) => {
        if (!reservationData || (!messageContent && !messageContentEng)) return '';

        const currentContent = isEnglish ? messageContentEng : messageContent;
        let previewContent = currentContent;
        availableFields.forEach(field => {
            const fieldPath = field.value.slice(2, -1);
            const value = field.display === '[ARRIVAL_DATE]' || field.display === '[DEPARTURE_DATE]'
                ? new Date(getNestedValue(reservationData, fieldPath)).toLocaleDateString()
                : getNestedValue(reservationData, fieldPath) || '';

            previewContent = previewContent.replace(
                new RegExp(escapeRegExp(field.display), 'g'),
                String(value)
            );
        });

        return previewContent;
    };

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const handleSubmit = () => {
        const templateContent = convertDisplayToTemplate(messageContent);
        const templateContentEng = convertDisplayToTemplate(messageContentEng);

        onSubmit({
            ...formData,
            content: templateContent,
            contentEng: templateContentEng,
            enabled: formData.enabled
        });
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-medium-aquamarine"></div>
            </div>
        );
    }

    return (
        <div>
            <Card className="rounded-0 shadow-none">
                <CardContent>
                    <div className="mb-6">
                        <div className="flex flex-row justify-between items-center mb-2">
                            <div className="flex gap-4 mb-4">
                                <div className="flex flex-col gap-2">
                                    <label className="block mb-2 text-sm">Name</label>
                                    <select
                                        className="w-full !px-2 !py-2 border rounded !text-sm"
                                        value={formData.messageName}
                                        onChange={(e) => setFormData({ ...formData, messageName: e.target.value })}
                                    >
                                        {emailName.map(name => (
                                            <option key={name.value} value={name.value}>
                                                {name.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block mb-2 text-sm">Type</label>
                                    <select
                                        className="w-[200px] !px-2 !py-2 border rounded !text-sm"
                                        value={formData.type}
                                        onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                                    >
                                        {emailTypes.map(type => (
                                            <option key={type.value} value={type.value}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="block mb-2 text-sm">Status</label>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                            checked={formData.enabled}
                                            onChange={(e) => setFormData({ ...formData, enabled: e.target.checked })}
                                            sx={{
                                                '& .MuiSwitch-switchBase.Mui-checked': {
                                                    color: '#00b4b4',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 180, 180, 0.08)',
                                                    },
                                                },
                                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                    backgroundColor: '#00b4b4',
                                                },
                                            }}
                                            className="!text-medium-aquamarine"
                                        />
                                        }
                                        label={formData.enabled ? "Enabled" : "Disabled"}
                                        className="!m-0"
                                    />
                                </div>

                            </div>
                        </div>

                        <div className="flex flex-row gap-4">
                            <Card className="!p-4 w-[60%]">
                                <div className="">
                                    <div className="mb-2">
                                        <label className="block mb-2 !text-sm font-bold">
                                            Your model is written in French
                                        </label>
                                        <textarea
                                            name="content"
                                            className="w-full p-2 border rounded min-h-[200px] !text-sm font-mono focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
                                            value={messageContent}
                                            onChange={(e) => setMessageContent(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <DynamicFieldButtons
                                            availableFields={availableFields}
                                            insertField={insertField}
                                            isEnglish={false}
                                        />
                                    </div>
                                </div>
                            </Card>
                            <Card className="!p-4 w-[40%] h-[270px]">
                                <div className="mb-4">
                                    <label className="block mb-2 !text-sm font-bold">Message preview</label>
                                    <div className="p-4 bg-gray-100 rounded !text-sm whitespace-pre-wrap h-[190px] overflow-y-auto">
                                        {getPreviewContent(false)}
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="flex flex-row gap-4 mt-4">
                            <Card className="!p-4 w-[60%]">
                                <div className="">
                                    <div className="mb-2">
                                        <label className="block mb-2 !text-sm font-bold">
                                            Your model is written in English
                                        </label>
                                        <textarea
                                            name="contentEng"
                                            className="w-full p-2 border rounded min-h-[200px] !text-sm font-mono focus:outline-none focus:border-blue-400 focus:ring-1 focus:ring-blue-400"
                                            value={messageContentEng}
                                            onChange={(e) => setMessageContentEng(e.target.value)}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <DynamicFieldButtons
                                            availableFields={availableFields}
                                            insertField={insertField}
                                            isEnglish={true}
                                        />
                                    </div>
                                </div>
                            </Card>
                            <Card className="!p-4 w-[40%] h-[270px]">
                                <div className="mb-4">
                                    <label className="block mb-2 !text-sm font-bold">English Message preview</label>
                                    <div className="p-4 bg-gray-100 rounded !text-sm whitespace-pre-wrap h-[190px] overflow-y-auto">
                                        {getPreviewContent(true)}
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                        <button
                            onClick={onCancel}
                            className="px-4 py-2 !text-sm border rounded hover:bg-gray-100"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className="px-4 py-2 !text-sm bg-medium-aquamarine text-white rounded hover:bg-opacity-90"
                        >
                            Next
                        </button>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default MailTemplateForm;
