import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useCalendar } from '../../tasks/hooks/useCalendar';
import '../../tasks/Task.css';
import { getReservations } from '../services/serverApi.reservation';
import { ToastContainer, toast } from 'react-toastify';
import TableCalendarReservation from './TableCalendarReservation';

export const CalendarReservation = ({
    groupedListings,
    listingId,
    selectedCountries,
    selectedStatuses
}) => {
    const [listingsData, setListingsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    const [cachedReservations, setCachedReservations] = useState({});
    const [refreshTrigger, setRefreshTrigger] = useState(0);


    const handleReservationUpdate = async (updatedReservation) => {
        setListingsData(prevData => {
            return prevData.map(group => ({
                ...group,
                properties: group.properties.map(property => {
                    if (property._id === updatedReservation.sojoriId) {
                        return {
                            ...property,
                            reservations: property.reservations.map(res => 
                                res._id === updatedReservation._id ? updatedReservation : res
                            )
                        };
                    }
                    return property;
                })
            }));
        });

        setRefreshTrigger(prev => prev + 1);
    };

    const {
        currentDate,
        goToNextPeriod,
        goToToday,
        goToPreviousPeriod,
        daysInView,
        setViewType,
        viewType,
        scrollBackward,
        scrollForward,
        scrollOffset,
        setCurrentDate,
        changeMonthView
    } = useCalendar(new Date());

    const startDate = moment(daysInView[0]).format('YYYY-MM-DD');
    const endDate = moment(daysInView[daysInView.length - 1]).format('YYYY-MM-DD');
    const formattedDateRange = `${moment(startDate).format('MMM')}`;

    useEffect(() => {
        const fetchData = async () => {
            if (!groupedListings || groupedListings.length === 0) {
                console.log('No grouped listings available');
                setListingsData([]);
                setIsLoading(false);
                return;
            }
        
            try {
                setIsLoading(true);
                const allListings = groupedListings.flatMap(group => group.listings);
                const listingIds = allListings.map(listing => listing._id);
        
                const params = {
                    startDate: moment(startDate).subtract(6, 'months').format('YYYY-MM-DD'),
                    endDate: moment(endDate).add(6, 'months').format('YYYY-MM-DD'),
                    listings: listingIds, 
                    staging,
                    limit: 1000,
                    page: 0,
                    countryNames: selectedCountries,
                    status: selectedStatuses.join(',')
                };
        
                const response = await getReservations(params);
        
                const processedListings = groupedListings.map(group => ({
                    _id: group._id,
                    properties: group.listings.map(listing => {
                        const listingReservations = response.filter(reservation => 
                            reservation.sojoriId === listing._id || 
                            (reservation.listing && reservation.listing.name === listing.name)
                        );
        
                        return {
                            ...listing,
                            name: listing.name,
                            _id: listing._id,
                            lastService: 'N/A',
                            reservations: listingReservations.map(reservation => ({
                                ...reservation, 
                                _id: reservation.id || reservation._id,
                                arrivalDate: new Date(reservation.checkInDate),
                                departureDate: new Date(reservation.checkOutDate),
                                guestName: reservation.guestName || '',
                                status: reservation.status || '',
                                reservationNumber: reservation.reservationNumber || reservation.id,
                                adults: reservation.adults || 0,
                                children: reservation.children || 0
                                
                            }))
                        };
                    })
                }));
        
                setListingsData(processedListings);
            } catch (error) {
                console.error('Failed to fetch reservations:', error);
                setError(error.message);
                // toast.error('Failed to fetch reservations');
                setListingsData([]);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [groupedListings, startDate, endDate, staging, refreshTrigger, selectedCountries, selectedStatuses]);

    return (
        <div className="calendar1">
            <ToastContainer />
            <TableCalendarReservation
                groupedProperties={listingsData}
                daysInView={daysInView}
                listingId={listingId}
                formattedDateRange={formattedDateRange}
                goToNextPeriod={goToNextPeriod}
                goToToday={goToToday}
                goToPreviousPeriod={goToPreviousPeriod}
                setViewType={setViewType}
                viewType={viewType}
                scrollBackward={scrollBackward}
                scrollForward={scrollForward}
                scrollOffset={scrollOffset}
                setCurrentDate={setCurrentDate}
                changeMonthView={changeMonthView}
                currentDate={currentDate}
                isLoading={isLoading}
                onReservationUpdate={handleReservationUpdate}
            />
        </div>
    );
};