import React from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import PublicAdminWhatsapp from './components/PublicAdminWhatsapp';

function AdminWhatsapp() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="Task">
        <PublicAdminWhatsapp />
      </div>
    </DashboardLayout>
  );
}

export default AdminWhatsapp;
