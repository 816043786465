import React from 'react';
import { Box, TextField, Typography, Switch, FormControlLabel } from '@mui/material';
import { toast } from 'react-toastify';

const TimeInputGroup = ({ section, timing, values, onChange, enabled = true, onToggle }) => {
  const handleFieldChange = (field, value) => {
    if (!enabled) return;
    
    if (value !== '') {  
      const numValue = parseInt(value);
      const maxValues = {
        day: 365,
        hours: 23,
        minutes: 59
      };

      if (numValue < 0) {
        toast.error(`${field.charAt(0).toUpperCase() + field.slice(1)} cannot be negative`);
        return;
      }
      
      if (numValue > maxValues[field]) {
        toast.error(`${field.charAt(0).toUpperCase() + field.slice(1)} cannot exceed ${maxValues[field]}`);
        return;
      }
    }
    
    onChange(section, timing, field, value);
  };
  

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <FormControlLabel
        control={
          <Switch
            checked={enabled}
            onChange={(e) => onToggle?.(section, timing, e.target.checked)}
            sx={{
              '& .MuiSwitch-switchBase.Mui-checked': {
                color: '#00b4b4',
                '&:hover': {
                  backgroundColor: 'rgba(0, 180, 180, 0.08)',
                },
              },
              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#00b4b4',
              },
            }}
          />
        }
        label={<Typography className="!text-base">{timing.charAt(0).toUpperCase() + timing.slice(1)}</Typography>}
        sx={{ minWidth: 150 }}
      />
      <TextField
        type="number"
        size="small"
        label="Day"
        value={values?.day || ''} 
        onChange={(e) => handleFieldChange('day', e.target.value)}
        inputProps={{ min: 0, max: 365 }}
        sx={{ width: 100 }}
        disabled={!enabled}
      />
      <TextField
        type="number"
        size="small"
        label="Hours"
        value={values?.hours || ''}
        onChange={(e) => handleFieldChange('hours', e.target.value)}
        inputProps={{ min: 0, max: 23 }}
        sx={{ width: 100 }}
        disabled={!enabled}
      />
      <TextField
        type="number"
        size="small"
        label="Minutes"
        value={values?.minutes || ''}
        onChange={(e) => handleFieldChange('minutes', e.target.value)}
        inputProps={{ min: 0, max: 59 }}
        sx={{ width: 100 }}
        disabled={!enabled}
      />
    </Box>
  );
};

export default TimeInputGroup;