import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getTasks(params = {}) {
  const { 
    startDate, 
    endDate, 
    listingIds = [], 
    staging = false,
    selectedTaskTypes = [],
    selectedFrenchTasks = [],
    selectedDarijaTasks = []
  } = params;

  if (!startDate || !endDate) {
    throw new Error('startDate and endDate are required parameters');
  }

  const queryParams = new URLSearchParams({
    startDate,
    endDate,
    listingIds: listingIds.join(','),
    staging,
  });

  if (selectedTaskTypes.length > 0) {
    selectedTaskTypes.forEach(type => {
      queryParams.append('types', type);
    });
  }

  selectedFrenchTasks.forEach(taskId => {
    const taskName = taskId.replace('-fr', '');
    queryParams.append('taskNames', taskName);
  });

  selectedDarijaTasks.forEach(taskId => {
    const taskName = taskId.replace('-da', '');
    queryParams.append('taskNames', taskName);
  });

  return axios
    .get(`${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/get-tasks?${queryParams.toString()}`)
    .then((response) => {
      if (!response.data) {
        throw new Error('No data received from server');
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Error in getTasks:', error);
      throw error;
    });
}
export function getListings(staging = false, listingIds = [], cityIds = [], countryNames = []) {
  // Create base params
  const params = new URLSearchParams({
      limit: '0',
      page: '0',
      paged: 'false',
      staging: String(staging)
  });

  if (Array.isArray(listingIds) && listingIds.length > 0) {
      listingIds.forEach(id => {
          if (id) params.append('listingIds', id);
      });
  }

  if (Array.isArray(cityIds) && cityIds.length > 0) {
      cityIds.forEach(id => {
          if (id) params.append('cityIds', id);
      });
  }

  if (Array.isArray(countryNames) && countryNames.length > 0) {
      countryNames.forEach(country => {
          if (country) params.append('countryNames', country);
      });
  }

  const url = `${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/listings-by-zone?${params.toString()}`;

  return axios.get(url);
}


export function getListingsTa(staging = false) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.LISTING}?staging=${staging}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data.data.map((listing) => ({
        id: listing._id,
        name: listing.name,
        TS_CLEAN: listing.TS_CLEAN,
      }));
    })
    .catch((error) => {
      console.error('Error fetching listings:', error);
      throw error;
    });
}
export function getTasksByStaff(params = {}) {
  const {
      startDate,
      endDate,
      staffIds = [],
      staging = false,
      selectedTaskTypes = [],
      selectedListings = [],
      selectedCities = [],
      selectedFrenchTasks = [],
      selectedDarijaTasks = []
  } = params;

  if (!startDate || !endDate) {
      throw new Error('startDate and endDate are required parameters');
  }

  let url = `${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/get-tasks-by-staff?`;

  url += `startDate=${startDate}&endDate=${endDate}`;
  url += `&staffIds=${staffIds.join(',')}`;
  url += `&staging=${staging}`;

  if (selectedTaskTypes.length > 0) {
      selectedTaskTypes.forEach(type => {
          url += `&types=${encodeURIComponent(type)}`;
      });
  }

  if (selectedListings.length > 0) {
      selectedListings.forEach(id => {
          url += `&listingIds=${encodeURIComponent(id)}`;
      });
  }

  if (selectedCities.length > 0) {
      selectedCities.forEach(id => {
          url += `&cityIds=${encodeURIComponent(id)}`;
      });
  }

  selectedFrenchTasks.forEach(taskId => {
    const taskName = taskId.replace('-fr', '');
    url += `&taskNames=${encodeURIComponent(taskName)}`;
});
  selectedDarijaTasks.forEach(taskId => {
    const taskName = taskId.replace('-da', '');
    url += `&taskNames=${encodeURIComponent(taskName)}`;
});
  return axios
      .get(url)
      .then((response) => {
          if (!response.data) {
              throw new Error('No data received from server');
          }
          return response.data;
      })
      .catch((error) => {
          console.error('Error in getTasksByStaff:', error);
          throw error;
      });
}


export function getStaff() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_TASK}/staff/approved-staff?page=0&limit=25&paged=true&search_text=`,
  );
}

export async function AssignTaskToStaff(id, staffId) {
  try {
    const response = await axios.put(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/assign-task/${id}`,
      { staffId },
    );
    return response;
  } catch (error) {
    console.error('Error in AssignTaskToStaff:', error);
    throw error;
  }
}

export function createTask(task) {
  return axios
    .post(`${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/create-task`, task)
    .then((response) => {
      if (!response.data) {
        throw new Error('No data received from server');
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Error in createTask:', error);
      throw error;
    });
}

export function updateTask(id, task) {
  return axios
    .put(`${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/update-task/${id}`, task)
    .then((response) => {
      if (!response.data) {
        throw new Error('No data received from server');
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Error in createTask:', error);
      throw error;
    });
}

export function getReservationByNumber(reservationNumber, staging = false) {
  return axios
    .get(
      `${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/by-reservation-number/${reservationNumber}?staging=${staging}`,
    )
    .then((response) => {
      if (!response.data) {
        throw new Error('No data received from server');
      }
      return response.data.reservation;
    })
    .catch((error) => {
      console.error('Error in getReservationByNumber:', error);
      throw error;
    });
}

export function getAllTasks(params = {}) {
  const defaultParams = {
    page: 0,
    limit: 20,
    paged: true,
    type: [],
    subType: [],
    status: [],
    assignmentStatus: [],
    listingId: null,
    reservationNumber: '',
    searchByGuest: '', 
  };

  const {
    page = 0,
    limit = 20,
    paged = true,
    type = defaultParams.type,
    subType = defaultParams.subType,
    status = [],
    assignmentStatus = [],
    reservationNumber = defaultParams.reservationNumber,
    listingId = null,
    searchByGuest = defaultParams.searchByGuest,
    staging = false,
  } = { ...defaultParams, ...params };

  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    paged: paged.toString(),
    type: type.join(','),
    subType: subType.join(','),
    status: status.join(','),
    assignmentStatus: assignmentStatus.join(','),
    listingId: listingId?.toString() || '',
    reservationNumber: reservationNumber?.toString() || '',
    searchByGuest: searchByGuest?.toString() || '', 
    staging: staging,
  }).toString();

  return axios
    .get(`${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/get-all-tasks?${queryParams}`)
    .then((response) => {
      if (!response.data) {
        throw new Error('No data received from server');
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Error in getAllTasks:', error);
      throw error;
    });
}
export function searchListings(params = {}) {
  const {
    page = 0,
    limit = 10,
    name = '',
    city = '',
    country = '',
    sortingBy = '',
    staging = false,
  } = params;

  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
    name,
    city,
    country,
    sortingBy,
    staging,
  }).toString();

  return axios
    .get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/?${queryParams}`)
    .then((response) => {
      if (!response.data) {
        throw new Error('No data received from server');
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Error in searchListings:', error);
      throw error;
    });
}

// __________________________TASK_CONFIG__________________________

export const getTaskConfigs = async () => {
  try {
    const response = await axios.get(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/task-config/get`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching task configs:', error);
    throw error;
  }
};

export const createTaskConfig = async (data) => {
  try {
    const response = await axios.post(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/task-config/create`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error('Error creating task config:', error);
    throw error;
  }
};

export const updateTaskConfig = async (id, data) => {
  try {
    const response = await axios.put(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/task-config/update/${id}`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating task config:', error);
    throw error;
  }
};

export const deleteTaskConfig = async (id) => {
  try {
    const response = await axios.delete(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/task-config/delete/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting task config:', error);
    throw error;
  }
};


// ______________________________REMINDER____________________________

export const getReminders = async () => {
  try {
    const response = await axios.get(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/reminder`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching reminders:', error);
    throw error;
  }
}

export const updateReminder = async (data) => {
  try {
    const response = await axios.put(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/reminder/update`,
      data,
    );
    return response.data;
  } catch (error) {
    console.error('Error updating reminder:', error);
    throw error;
  }
}


// _____________________________ListingList____________________________

export function getListingsList(staging = false) {
  return axios
    .get(`${MICROSERVICE_BASE_URL.LISTING}/?staging=${staging}`)
    .then((response) => {
      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      return response.data.data.map((listing) => ({
        id: listing._id,
        name: listing.name,
        checkInTime: listing.checkInTimeStart,
        checkOutTime: listing.checkOutTime,
        propertyUnit: listing.propertyUnit,
        roomTypes: listing.roomTypes,
      }));
    })
    .catch((error) => {
      console.error('Error fetching listings:', error);
      throw error;
    });
}

export function getCities() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.CITY}?page=0&limit=10&search_text&paged=false`,
  );
}


export function getCountries() {
  return axios.get(
    `${MICROSERVICE_BASE_URL.COUNTRY}?page=0&limit=10&search_text&paged=false`,
  );
}
