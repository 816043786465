import React from 'react';
import Tooltip from 'components/TooltipGlobal/Tooltip';

const InvoicesAndCharges = ({ reservationDetails }) => {
    if (!reservationDetails?.roomsDetails?.length) {
        return (
            <div className="!mt-8">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2 md:gap-0">
                    <h5 className="text-base font-bold mb-2 text-[#676a6c]">Invoices & charges</h5>
                </div>
                <div className="!my-3">
                    <h6 className="text-base font-bold mb-2 text-[#676a6c]">Payment balance</h6>
                    <div type="text" className="mb-2 p-1 focus:outline-none text-[#676a6c] focus:border-gray-300 rounded flex flex-col md:flex-row justify-between w-full md:w-full text-sm" style={{ borderBottom: '3px solid #ccc' }}>
                        <div>Balance due: {reservationDetails.totalPrice} {reservationDetails.currency}</div>
                        <div>Total: {reservationDetails.totalPrice} {reservationDetails.currency}</div>
                    </div>
                    <div className="flex gap-2 items-center mt-2">
                        <hr className="my-3  !w-full" />
                        <Tooltip 
                            text="There is no credit card for this reservation. You can register an offline payment or ask the guest to provide a credit card (we suggest sharing the guest portal for it) and manually charge by payment method card online." 
                            iconClass="pi pi-info-circle text-[#676a6c] text-xs" 
                            position="top" 
                        />
                    </div>
                    <p className="text-[#676a6c] text-xs w-full flex justify-end">Remaining charges: {reservationDetails.totalPrice} {reservationDetails.currency}</p>
                </div>
            </div>
        );
    }

    const roomDetails = reservationDetails.roomsDetails[0];

    return (
        <div className="!mt-8">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2 md:gap-0">
                <h5 className="text-base font-bold mb-2 text-[#676a6c]">Invoices & charges</h5>
            </div>
            <div className="!my-3">
                <h6 className="text-base font-bold mb-2 text-[#676a6c]">Payment balance</h6>
                <div className="text-sm font-medium mb-2">
                    Total nightly rate: {Number(roomDetails?.totalDailyPrice).toFixed(2)} {reservationDetails.currency} <span className="font-bold">PAID</span>
                </div>
                <div className="text-sm font-medium mb-2">
                    Taxes Fees: {Number(roomDetails?.totalTaxNotInclusive).toFixed(2)} {reservationDetails.currency} <span className="font-bold">PAID</span>
                </div>
                <div className="text-sm font-medium mb-2">
                    Cleaning Fees: {Number(roomDetails?.totalServicesNotInclusive).toFixed(2)} {reservationDetails.currency} <span className="font-bold">PAID</span>
                </div>

                <div className="text-sm font-bold my-2">
                    Total Amount Paid By Customer: 
                    {Number(roomDetails?.channexAmount) === roomDetails?.sojoriAmount ? (
                        ` ${Number(roomDetails?.channexAmount).toFixed(2)} ${reservationDetails.currency}`
                    ) : (
                        ` ${Number(roomDetails?.channexAmount).toFixed(2)} (${Number(roomDetails?.sojoriAmount).toFixed(2)}) ${reservationDetails.currency}`
                    )}
                </div>
            </div>
        </div>
    );
};

export default InvoicesAndCharges;