import { useState, useCallback, useEffect } from 'react';

export const useCalendar = (initialDate = new Date()) => {
    const [currentDate, setCurrentDate] = useState(initialDate);
    const [viewType, setViewType] = useState('Month');
    const [daysInView, setDaysInView] = useState(30);
    const [scrollOffset, setScrollOffset] = useState(0);

    useEffect(() => {
        switch (viewType) {
          case '10 Day':
            setDaysInView(10);
            break;
          case '14 Day':
            setDaysInView(14);
            break;
          case '20 Day':
            setDaysInView(20);
            break;
          case '30 Day':
            setDaysInView(30);
            break;
          case 'Month':
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            setDaysInView(daysInMonth);
            break;
          default:
            setDaysInView(14);
        }
      }, [viewType, currentDate]);

    const getDaysInView = useCallback(() => {
        const days = [];
        const startDate = new Date(currentDate);
        startDate.setDate(startDate.getDate() - scrollOffset);

        for (let i = 0; i < daysInView; i++) {
            const day = new Date(startDate);
            day.setDate(startDate.getDate() + i);
            days.push(day);
        }
        return days;
    }, [currentDate, daysInView, scrollOffset]);

    const goToNextPeriod = useCallback(() => {
        setCurrentDate(date => {
            const newDate = new Date(date);
            newDate.setMonth(newDate.getMonth() + 1);
            newDate.setDate(1);
            return newDate;
        });
    }, []);

    const goToPreviousPeriod = useCallback(() => {
        setCurrentDate(date => {
            const newDate = new Date(date);
            if (newDate.getDate() === 1) {
                newDate.setMonth(newDate.getMonth() - 1);
            } else {
                newDate.setDate(1);
            }
            return newDate;
        });
    }, []);

    const changeMonthView = useCallback((direction) => {
        setCurrentDate(date => {
            const newDate = new Date(date);
            newDate.setMonth(date.getMonth() + (direction === 'next' ? 1 : -1));
            return newDate;
        });
        setViewType('Month');
    }, []);

    const goToToday = useCallback(() => {
        setCurrentDate(new Date());
        setScrollOffset(0);
    }, []);

    const scrollBackward = useCallback(() => {
        setScrollOffset(prev => prev + 7);
    }, []);

    const scrollForward = useCallback(() => {
        setScrollOffset(prev => prev - 7);
    }, []);

    return {
        currentDate,
        setCurrentDate,
        viewType,
        setViewType,
        goToNextPeriod,
        goToPreviousPeriod,
        goToToday,
        daysInView: getDaysInView(),
        scrollBackward,
        scrollForward,
        scrollOffset,
        changeMonthView
    };
};