import React, { useState, useEffect } from 'react';
import { Calendar } from './components/Calendar/Calendar';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getListings, getListingsList, getCities, getTaskConfigs, getCountries } from './services/serverApi.task';
import FilterSearch from 'components/FilterSearch/FilterSearch';
import { useParams, useNavigate } from 'react-router-dom';

function Task() {
    const [groupedListings, setGroupedListings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [listingOptions, setListingOptions] = useState([]);
    const [selectedListings, setSelectedListings] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [cities, setCities] = useState([]);
    const { listingId } = useParams();
    const navigate = useNavigate();
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
    const [selectedFrenchTasks, setSelectedFrenchTasks] = useState([]);
    const [selectedDarijaTasks, setSelectedDarijaTasks] = useState([]);
    const [taskTypeOptions, setTaskTypeOptions] = useState([]);
    const [frenchTaskOptions, setFrenchTaskOptions] = useState([]);
    const [darijaTaskOptions, setDarijaTaskOptions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);

    const fetchListings = async () => {
        try {
            setIsLoading(true);
            const response = await getListings(
                staging,
                selectedListings,
                selectedCities,
                selectedCountries
            );

            if (
                response?.data?.success === false ||
                !response?.data?.data ||
                response.data.data.length === 0
            ) {
                setGroupedListings([]);
                setError("No listings found for the selected criteria.");
                return;
            }

            if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
                const normalizedListings = response.data.data.map(group => ({
                    ...group,
                    _id: group._id.toLowerCase()
                }));

                let filteredListings = normalizedListings;
                if (listingId) {
                    filteredListings = normalizedListings.map(group => ({
                        ...group,
                        listings: group.listings.filter(listing => listing._id === listingId),
                        count: group.listings.filter(listing => listing._id === listingId).length
                    })).filter(group => group.count > 0);
                }

                const groupedByZone = filteredListings.reduce((acc, group) => {
                    const zone = group._id;
                    if (!acc[zone]) {
                        acc[zone] = { _id: zone, listings: [], count: 0 };
                    }
                    acc[zone].listings = [...acc[zone].listings, ...group.listings];
                    acc[zone].count += group.count;
                    return acc;
                }, {});

                const regroupedListings = Object.values(groupedByZone);

                setGroupedListings(regroupedListings);
            }
        } catch (err) {
            console.error('Error fetching listings:', err);
            setGroupedListings([]);
            setError('Failed to fetch listings. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchTaskConfigs = async () => {
        try {
            const response = await getTaskConfigs();
            processTaskConfigs(response);
        } catch (error) {
            console.error('Error fetching task configs:', error);
        }
    };

    const processTaskConfigs = (configs) => {
        const frenchOptions = [];
        const darijaOptions = [];
        const taskTypes = [];

        configs.forEach(taskConfig => {
            taskTypes.push({
                id: taskConfig.task,
                name: taskConfig.task
            });

            if (taskConfig.subs && Array.isArray(taskConfig.subs)) {
                taskConfig.subs.forEach(sub => {
                    if (sub.name) {
                        frenchOptions.push({
                            id: `${sub.name.Francais}-fr`,
                            name: sub.name.Francais,
                            _id: sub._id,
                            type: sub.type
                        });

                        darijaOptions.push({
                            id: `${sub.name.Darija}-da`,
                            name: sub.name.Darija,
                            _id: sub._id,
                            type: sub.type
                        });
                    }
                });
            }
        });

        setTaskTypeOptions(taskTypes);
        setFrenchTaskOptions(frenchOptions);
        setDarijaTaskOptions(darijaOptions);
    };

    useEffect(() => {
        fetchTaskConfigs();
    }, [staging]);


    const fetchListingOptions = async () => {
        try {
            const options = await getListingsList(staging);
            setListingOptions(options);
        } catch (err) {
            console.error('Error fetching listing options:', err);
        }
    };

    const fetchCities = async () => {
        try {
            const response = await getCities();
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
            setError('Failed to fetch cities. Please try again.');
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await getCountries();
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
            setError('Failed to fetch countries. Please try again.');
        }
    };

    useEffect(() => {
        fetchListingOptions();
        fetchCities();
        fetchCountries();
    }, [staging]);

    useEffect(() => {
        fetchListings();
    }, [selectedListings, selectedCities, selectedCountries, staging, selectedTaskTypes, selectedFrenchTasks, selectedDarijaTasks]);

    const handleTaskTypeChange = (selectedIds) => {
        setSelectedTaskTypes(selectedIds);
    };

    const handleFrenchTaskChange = (selectedIds) => {
        setSelectedFrenchTasks(selectedIds);
    };

    const handleDarijaTaskChange = (selectedIds) => {
        setSelectedDarijaTasks(selectedIds);
    };

    const handleListingChange = (selectedIds) => {
        setSelectedListings(selectedIds);
        if (listingId && !selectedIds.includes(listingId)) {
            navigate('/task');
        }
    };

    const handleCityChange = (selectedCities) => {
        setSelectedCities(selectedCities);
    };

    const handleCountryChange = (selectedCountries) => {
        setSelectedCountries(selectedCountries);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="task-container card !p-4">
                <div className="flex items-center mb-3 gap-2">
                    <FilterSearch
                        selectedItems={selectedCountries}
                        options={countries}
                        onItemsChange={handleCountryChange}
                        placeholder="Select Countries"
                        idKey="name"
                        labelKey="name"
                    />
                    <FilterSearch
                        selectedItems={selectedCities}
                        options={cities}
                        onItemsChange={handleCityChange}
                        placeholder="Select Cities"
                        idKey="_id"
                        labelKey="name"
                    />
                    <FilterSearch
                        selectedItems={selectedListings}
                        options={listingOptions}
                        onItemsChange={handleListingChange}
                        placeholder="Select Listings"
                        idKey="id"
                        labelKey="name"
                    />

                    <FilterSearch
                        selectedItems={selectedTaskTypes}
                        options={taskTypeOptions}
                        onItemsChange={handleTaskTypeChange}
                        placeholder="Select Task Types"
                        idKey="id"
                        labelKey="name"
                    />
                    <FilterSearch
                        selectedItems={selectedFrenchTasks}
                        options={frenchTaskOptions}
                        onItemsChange={handleFrenchTaskChange}
                        placeholder="Sélectionner les tâches"
                        idKey="id"
                        labelKey="name"
                    />
                    <FilterSearch
                        selectedItems={selectedDarijaTasks}
                        options={darijaTaskOptions}
                        onItemsChange={handleDarijaTaskChange}
                        placeholder="اختر المهام"
                        idKey="id"
                        labelKey="name"
                    />
                </div>
                <Calendar
                    groupedListings={groupedListings}
                    listingId={listingId}
                    selectedTaskTypes={selectedTaskTypes}
                    selectedFrenchTasks={selectedFrenchTasks}
                    selectedDarijaTasks={selectedDarijaTasks}
                />
            </div>
        </DashboardLayout>
    );
}

export default Task;