
export const quickFilterOptions = [
    { label: "Today's check-ins", value: "arrival" },
    { label: "Today's check-outs", value: "departure" },
    { label: "Tomorrow's check-ins", value: "tomorrow_arrival" },
    { label: "Tomorrow's check-outs", value: "tomorrow_departure" },
    { label: "Next week's check-ins", value: "next_week_arrival" },
    { label: "Next week's check-outs", value: "next_week_departure" },
    { label: "Last week's check-outs", value: "last_week_departure" }
  ];
  
  export const dateTypeOptions = [
    { name: 'Check-in', value: 'arrival' },
    { name: 'Check-out', value: 'departure' },
    // { name: 'Check-in or check-out', value: 'arrival_or_departure' },
    // { name: 'Creation', value: 'creation' }
  ];
  