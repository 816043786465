import React from 'react';
import GlobalTable from 'components/GlobalTable/GlobalTable';

const RateDetailsTable = ({ roomDetails, reservationDetails }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit'
        });
    };

    const getTableData = () => {
        if (!roomDetails) return [];
        
        let data = [];
        
        if (roomDetails.days_breakdown?.length > 0) {
            data = roomDetails.days_breakdown.map(breakdown => ({
                date: formatDate(breakdown.date),
                title: breakdown.promotion?.title || '',
                tarifParNuit: breakdown.amount
            }));
        } else if (roomDetails.days) {
            data = Object.entries(roomDetails.days).map(([date, amount]) => ({
                date: formatDate(date),
                title: '',
                tarifParNuit: amount
            }));
        }

        const subtotal = data.reduce((sum, item) => sum + Number(item.tarifParNuit), 0);
        data.push({
            date: 'Sous-total',
            title: '',
            tarifParNuit: subtotal,
            isTotal: true
        });

        data.push({
            date: 'Tarif total de l\'hébergement',
            title: '',
            tarifParNuit: roomDetails.totalDailyPrice,
            isTotal: true
        });
        
        return data;
    };

    const getVATInfo = () => {
        if (!roomDetails?.taxes) return null;
        
        const vatTax = roomDetails.taxes.find(tax => 
            tax.is_inclusive === true && 
            tax.name.toLowerCase().includes('tva')
        );

        return vatTax;
    };

    const columns = [
        {
            field: 'date',
            header: 'Date',
            headerStyle: { width: '150px' }
        },
        {
            field: 'title',
            header: 'Tarif',
            headerStyle: { width: '300px' }
        },
        {
            field: 'tarifParNuit',
            header: 'Tarif par nuit',
            headerStyle: { width: '150px' },
            body: (rowData) => {
                const value = `€ ${Number(rowData.tarifParNuit).toFixed(2)}`;
                return rowData.isTotal ? (
                    <div className="font-bold">{value}</div>
                ) : value;
            }
        }
    ];

    const renderFeesAndTaxes = () => {
        if (!roomDetails) return null;

        const services = roomDetails.services || [];
        const taxes = roomDetails.taxes || [];

        return (
            <div className="ml-4">
                <div className="mb-6">
                    <div className="flex mb-2">
                        <div className="w-48 text-base font-medium">Extra Fees</div>
                        <div className="w-32 text-base font-medium">Price</div>
                        <div className="w-32 text-base font-medium">Status</div>
                    </div>
                    {services.map((service, index) => (
                        <div key={index} className="flex mb-1">
                            <div className="w-48 text-sm">{service.name || ''}</div>
                            <div className="w-32 text-sm">{service.total_price || ''} {reservationDetails.currency}</div>
                            <div className="w-32 text-sm">{service.is_inclusive === false && service.excluded === true ? 'Paid' : 'Unpaid'}</div>
                        </div>
                    ))}
                </div>

                <div>
                    <div className="flex mb-2">
                        <div className="w-48 text-base font-medium">Taxes</div>
                        <div className="w-32 text-base font-medium">Price</div>
                        <div className="w-32 text-base font-medium">Status</div>
                    </div>
                    {taxes.map((tax, index) => (
                        <div key={index} className="flex mb-1">
                            <div className="w-48 text-sm">{tax.name || ''}</div>
                            <div className="w-32 text-sm">{tax.total_price || ''} {reservationDetails.currency}</div>
                            <div className="w-32 text-sm">{tax.is_inclusive ? 'Paid' : 'Unpaid'}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
      <div className="mt-4 flex gap-20">
        <div className="w-1/2">
          <GlobalTable
            data={getTableData()}
            columns={columns}
            hasPagination={false}
          />
          {getVATInfo() && (
            <div className="mt-2 text-sm text-[#676a6c] italic">
              {getVATInfo().name} is included in the total price
            </div>
          )}
        </div>
        <div className="w-1/2">
          {renderFeesAndTaxes()}
        </div>
      </div>
    );
};

export default RateDetailsTable;