import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const CountryFilter = ({ countries = [], selectedCountries = [], setSelectedCountries }) => {
  return (
    <FilterSearch
      options={countries}
      selectedItems={selectedCountries}
      onItemsChange={setSelectedCountries}
      placeholder="Select Countries"
      idKey="code"
      labelKey="name"
    />
  );
};

export default CountryFilter;