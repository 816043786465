import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Switch, FormControlLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { getDateRange } from '../dateUtils';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const channelOptions = [
    { id: 'sojori', name: 'Sojori' },
    { id: 'BookingCom', name: 'Booking.com' },
    { id: 'AirBNB', name: 'AirBNB' }
];

const FilterSection = ({ filters, handleFilterChange, view, listings = [], cities = [], countries = [] }) => {
    const handleFilterOptionChange = (option) => {
        const dateRange = getDateRange(option);
        handleFilterChange('dateRange', option);
        handleFilterChange('from', dateRange.start);
        handleFilterChange('to', dateRange.end);
    };

    return (
        <div className="mt-2">
            <div className="grid grid-cols-2 gap-2 sm:flex sm:flex-wrap sm:gap-2 sm:items-center my-4">

                <FilterSearch
                    selectedItems={filters.countryNames}
                    options={countries}
                    onItemsChange={(selectedNames) => handleFilterChange('countryNames', selectedNames)}
                    placeholder="Select Countries"
                    isMultiple={true}
                    idKey="name"
                    labelKey="name"
                    width={250}
                />

                <FilterSearch
                    selectedItems={filters.cityIds}
                    options={cities}
                    onItemsChange={(selectedIds) => handleFilterChange('cityIds', selectedIds)}
                    placeholder="Select Cities"
                    isMultiple={true}
                    idKey="_id"
                    labelKey="name"
                    width={250}
                />

                <FilterSearch
                    selectedItems={filters.listingIds}
                    options={listings}
                    onItemsChange={(selectedIds) => handleFilterChange('listingIds', selectedIds)}
                    placeholder="Select Listings"
                    isMultiple={true}
                    idKey="_id"
                    labelKey="name"
                    width={250}
                />

                <FormControl size="small" className="!w-[10rem] sm:w-60">
                    <InputLabel>Listing Status</InputLabel>
                    <Select
                        value={filters.active}
                        onChange={(e) => handleFilterChange('active', e.target.value)}
                        label="Listing Status"
                    >
                        <MenuItem value={true}>Active Listings</MenuItem>
                        <MenuItem value={false}>Inactive Listings</MenuItem>
                    </Select>
                </FormControl>

                <FilterSearch
                    selectedItems={filters.channelNames || []}
                    options={channelOptions}
                    onItemsChange={(selectedChannels) => handleFilterChange('channelNames', selectedChannels)}
                    placeholder="Select Channels"
                    isMultiple={true}
                    idKey="id"
                    labelKey="name"
                    width={250}
                />

                <FormControl size="small" className="!w-[10rem] sm:w-60">
                    <InputLabel>Check-in</InputLabel>
                    <Select
                        value={filters.checkin}
                        onChange={(e) => handleFilterChange('checkin', e.target.value)}
                        label="Check-in"
                    >
                        <MenuItem value="arrival">All Reservations</MenuItem>
                        <MenuItem value="checked">Checked-in</MenuItem>
                        <MenuItem value="notChecked">Not Checked-in</MenuItem>
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs} className="col-span-2 sm:col-auto">
                    <DatePicker
                        className="!w-[10rem] sm:w-60"
                        label="From"
                        value={dayjs(filters.from)}
                        onChange={(newValue) => handleFilterChange('from', newValue ? newValue.format('YYYY-MM-DD') : '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                className="bg-white !rounded-sm !h-[20px] !bg-[#dcf6f6] text-xs px-1"
                            />
                        )}
                    />
                    <DatePicker
                        className="!w-[10rem] sm:w-60"
                        label="To"
                        value={dayjs(filters.to)}
                        onChange={(newValue) => handleFilterChange('to', newValue ? newValue.format('YYYY-MM-DD') : '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                className="bg-white !rounded-sm !h-[20px] !bg-[#dcf6f6] text-xs px-1"
                            />
                        )}
                    />
                </LocalizationProvider>

                <FormControl size="small" className="!w-[10rem] sm:w-60">
                    <InputLabel>Date Range</InputLabel>
                    <Select
                        value={filters.dateRange || ''}
                        onChange={(e) => handleFilterOptionChange(e.target.value)}
                        label="Date Range"
                    >
                        <MenuItem value="Current month">Current month</MenuItem>
                        <MenuItem value="Last month">Last month</MenuItem>
                        <MenuItem value="Next Month">Next month</MenuItem>
                        <MenuItem value="Today">Today</MenuItem>
                        <MenuItem value="Yesterday">Yesterday</MenuItem>
                        <MenuItem value="Tomorrow">Tomorrow</MenuItem>
                        <MenuItem value="Current Year">Current Year</MenuItem>
                        <MenuItem value="Last Year">Last Year</MenuItem>
                        {/* <MenuItem value="Last 12 months">Last 12 months</MenuItem>
                        <MenuItem value="Last 3 months">Last 3 months</MenuItem>
                        <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                        <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                        <MenuItem value="YTD">YTD</MenuItem>
                        <MenuItem value="MTD">MTD</MenuItem>
                        <MenuItem value="QTD">QTD</MenuItem>
                        <MenuItem value="WTD">WTD</MenuItem> */}
                    </Select>
                </FormControl>



                {view === 'listing' && (
                    <FormControl size="small" className="!w-[10rem] sm:w-60">
                        <InputLabel>Date Filter Type</InputLabel>
                        <Select
                            value={filters.dateFilterType}
                            onChange={(e) => handleFilterChange('dateFilterType', e.target.value)}
                            label="Date Filter Type"
                        >
                            <MenuItem value="arrival">Arrival Date</MenuItem>
                            <MenuItem value="checked">Checked Date</MenuItem>
                            <MenuItem value="notChecked">Not Checked Date</MenuItem>
                        </Select>
                    </FormControl>
                )}

                {/* <span className="col-span-2 sm:col-auto cursor-pointer text-base font-medium text-red-500" onClick={() => handleFilterChange('reset', null)}>
                    Reset filters
                </span> */}
            </div>
        </div>
    );
};

export default FilterSection;
