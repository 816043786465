import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Button } from '@mui/material';
import { Add as Plus, Remove as Minus } from '@mui/icons-material';

const GuestCountCreateInput = ({ formik }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [counts, setCounts] = useState({
        adults: formik.values.adults || 0,
        children: formik.values.children || 0,
        infants: formik.values.infants || 0
    });

    const handleCountChange = (type, increment) => {
        const newCounts = { ...counts };
        newCounts[type] = Math.max(0, counts[type] + increment);
        setCounts(newCounts);
    };

    const handleSave = () => {
        const totalGuests = counts.adults + counts.children + counts.infants;
        
        formik.setFieldValue('numberOfGuests', totalGuests);
        formik.setFieldValue('adults', counts.adults);
        formik.setFieldValue('children', counts.children);
        formik.setFieldValue('infants', counts.infants);

        setIsOpen(false);
    };

    const renderCounter = (label, type) => (
        <div className="!flex items-center justify-between py-4 px-4">
            <span className="!text-sm !text-gray-700">{label}</span>
            <div className="flex items-center gap-4">
                <Button
                    className="!bg-gray-100 !text-gray-700"
                    size="small"
                    onClick={() => handleCountChange(type, -1)}
                    disabled={counts[type] === 0}
                    style={{ minWidth: '40px', padding: '5px' }}
                >
                    <Minus fontSize="small" />
                </Button>
                <span className="!w-8 !text-center">{counts[type]}</span>
                <Button
                    className="!bg-medium-aquamarine !text-white"
                    size="small"
                    onClick={() => handleCountChange(type, 1)}
                    style={{ minWidth: '40px', padding: '5px' }}
                >
                    <Plus fontSize="small" />
                </Button>
            </div>
        </div>
    );

    const displayValue = `${counts.adults}A ${counts.children}C ${counts.infants}I`;

    return (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">Number Of Guests</label>
            <div
                className="flex items-center gap-2 mb-2 px-3 py-2 rounded bg-white border border-gray-300 cursor-pointer"
                onClick={() => setIsOpen(true)}
            >
                <span className="text-sm text-gray-700">{displayValue}</span>
            </div>
            {formik.touched.numberOfGuests && formik.errors.numberOfGuests ? (
                <div className="text-red-500 text-sm">{formik.errors.numberOfGuests}</div>
            ) : null}

            <Dialog 
                open={isOpen} 
                onClose={() => setIsOpen(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle style={{ paddingBottom: 0 }}>
                    Edit Guest Count
                </DialogTitle>
                <DialogContent>
                    <div className="py-4">
                        {renderCounter('Adults', 'adults')}
                        {renderCounter('Children', 'children')}
                        {renderCounter('Infants', 'infants')}
                    </div>
                    <div className="flex justify-end gap-2 pt-4">
                        <Button 
                            className="!bg-gray-100 !text-gray-700"
                            onClick={() => setIsOpen(false)}
                            color="inherit"
                        >
                            Cancel
                        </Button>
                        <Button 
                            className="!bg-medium-aquamarine !text-white"
                            onClick={handleSave}
                            color="primary"
                        >
                            Save
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default GuestCountCreateInput;