import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAccessType,
  deleteAccessType,
} from '../services/serverApi.listing.js';
import ModifyAccessType from '../components/ModifyAccessType.jsx';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import DeleteUserDialog from '../../staff/components/DeleteUserDialog.jsx';

function AccessTypePage() {
  const [accessTypeList, setAccessTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const [selectedAccessType, setSelectedAccessType] = useState(null);
  const [selectedAccessTypeForDelete, setSelectedAccessTypeForDelete] =
    useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const fetchAccessType = async () => {
    setIsLoading(true);
    try {
      const response = await getAccessType(0, 0, false);
      if (response.data && Array.isArray(response.data.data)) {
        setAccessTypeList(response.data.data);
      } else {
        setAccessTypeList([]);
      }
    } catch (error) {
      console.error('Error fetching Access Type:', error);
      setAccessTypeList([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAccessType();
  }, []);

  const handleOpenModifyDialog = (index, accessType) => {
    setSelectedAccessType(accessType);
    setSelectedIndex(index);
    setIsCreating(false);
    setOpenModifyDialog(true);
  };

  const handleCloseModifyDialog = () => {
    setOpenModifyDialog(false);
    setSelectedAccessType(null);
    setSelectedIndex(null);
    setIsCreating(false);
  };

  const handleDelete = async (accessType) => {
    setSelectedAccessTypeForDelete(accessType);
    handleOpenDeleteDialog(true);
  };
  const blocFunction = async () => {
    try {
      const { data } = await deleteAccessType(selectedAccessTypeForDelete._id);
      setAccessTypeList((prevList) =>
        prevList.filter((item) => item._id !== selectedAccessTypeForDelete._id),
      );
      toast.success(
        `Type ${selectedAccessTypeForDelete._id.type} has been deleted successfully`,
      );
      handleCloseDeleteDialog();
    } catch (err) {
      console.error('Error delete type:', err);
      toast.error(err.message || err || 'Failed to delete type');
    }
  };
  const afterActionCreated = (msg) => {
    toast.success(msg);
  };
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedAccessTypeForDelete(null);
    setOpenDeleteDialog(false);
  };
  const columns = [
    {
      field: 'type',
      header: 'Type',
      headerStyle: { width: '100px' },
    },
    {
      field: 'iconUrl',
      header: 'Icon',
      body: (rowData) => (
        <img
          src={rowData.iconUrl}
          alt={rowData.name}
          style={{ width: '70px', height: '70px', objectFit: 'contain' }}
        />
      ),
      headerStyle: { width: '100px' },
    },

    {
      field: 'actions',
      header: 'Action',
      body: (rowData) => (
        <>
          <IconButton
            className="!p-[7px] !rounded-lg bg-white shadow-sm"
            onClick={() =>
              handleOpenModifyDialog(accessTypeList.indexOf(rowData), rowData)
            }
          >
            <ModeEditOutlineOutlinedIcon style={{ color: '#66cdaa' }} />
          </IconButton>
          <IconButton
            className="!p-[7px] !rounded-lg bg-white shadow-sm mx-2"
            onClick={() => handleDelete(rowData)}
          >
            <DeleteIcon style={{ color: '#ff6b6b' }} />
          </IconButton>
        </>
      ),
      headerStyle: { width: '100px' },
    },
  ];
  return (
    <>
      <Button
        onClick={() => {
          setIsCreating(true);
          setSelectedAccessType({});
          setOpenModifyDialog(true);
        }}
        className="mb-3 !bg-[#00b4b4]"
      >
        <span className="!text-white">Create Access Type</span>
      </Button>
      <ToastContainer position="top-right" autoClose={3000} />
      <GlobalTable
        data={accessTypeList}
        columns={columns}
        page={page}
        onPageChange={setPage}
        isNextDisabled={false}
        hasPagination={false}
        limit={limit}
        onLimitChange={setLimit}
        rowsPerPageOptions={[5, 10, 20, 50]}
      />
      {(selectedAccessType || isCreating) && (
        <ModifyAccessType
          open={openModifyDialog}
          onClose={handleCloseModifyDialog}
          setAccessTypeList={setAccessTypeList}
          accessTypeList={accessTypeList}
          index={selectedIndex}
          dataAccessType={selectedAccessType}
          isCreating={isCreating}
        />
      )}
      {selectedAccessTypeForDelete && (
        <DeleteUserDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onAdminCreated={() => afterActionCreated('Type deleted successfully')}
          functionToExecute={() => blocFunction()}
          user={selectedAccessTypeForDelete}
          title={'Delete Type'}
          message={`are you sure want to delete ${
            selectedAccessTypeForDelete?.type || ''
          }`}
          btnTxt={'delete'}
          btnClass={'text-white !bg-red-600 !hover:bg-red-600/90'}
        />
      )}
    </>
  );
}

export default AccessTypePage;
