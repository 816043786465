import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './TableCalendar.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EventPopup from './EventPopup';
import AddTask from './AddTask';
import AssignTask from './AssignTask';
import DetailsTask from './DetailsTask';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { CalendarHeader } from './CalendarHeader';
import { CircularProgress } from '@mui/material';

const TableCalendar = ({
    groupedProperties,
    daysInView,
    staffData,
    onTaskUpdated,
    onSaveTask,
    listingId,
    // Add these new props
    formattedDateRange,
    goToNextPeriod,
    goToToday,
    goToPreviousPeriod,
    setViewType,
    viewType,
    scrollBackward,
    scrollForward,
    scrollOffset,
    setCurrentDate,
    changeMonthView,
    currentDate
}) => {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openTaskModal, setOpenTaskModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [detailsTaskOpen, setDetailsTaskOpen] = useState(false);
    const [selectedTaskDetails, setSelectedTaskDetails] = useState(null);
    const [isEditingTask, setIsEditingTask] = useState(false);
    const [collapsedZones, setCollapsedZones] = useState({});
    const [selectedReservation, setSelectedReservation] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        if (groupedProperties && groupedProperties.length > 0) {
            setIsLoading(false);
        }
    }, [groupedProperties]);

    const toggleZone = (zone) => {
        setCollapsedZones(prev => ({
            ...prev,
            [zone]: !prev[zone]
        }));
    };

    const getPropertyStatus = (property) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const currentReservation = property.events.find(event => {
            const startDate = new Date(event.startDate);
            const endDate = new Date(event.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);
            return today >= startDate && today <= endDate;
        });

        if (currentReservation) {
            const startDate = new Date(currentReservation.startDate);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(currentReservation.endDate);
            endDate.setHours(0, 0, 0, 0);

            if (today.getTime() === startDate.getTime()) {
                return 'Occupied | Check-IN';
            } else if (today.getTime() === endDate.getTime()) {
                return 'Occupied | Check-OUT';
            } else {
                return 'Occupied';
            }
        }

        return 'Vacant';
    };

    const formatDate = (day) => {
        return {
            dayName: day.toLocaleDateString('en-US', { weekday: 'short' }),
            day: day.toLocaleDateString('en-US', { day: 'numeric' }),
            month: day.toLocaleDateString('en-US', { month: 'short' })
        };
    };


    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setOpenModal(true);
    };

    // const handleTaskRightClick = (event, task) => {
    //     event.preventDefault();
    //     setSelectedTask(task);
    //     setIsEditingTask(true);
    //     setOpenAddTaskModal(true);
    // };

    const handleCloseDetailsTask = () => {
        setDetailsTaskOpen(false);
        setSelectedTaskDetails(null);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedEvent(null);
    };

    const handleOpenTaskModal = () => {
        setOpenTaskModal(true);
    };
    const handleClose = () => {
        setOpenAddTaskModal(false);
        setSelectedProperty(null);
    };

    const handleOpenAddTaskModal = (property) => {
        setSelectedProperty(property);
        setOpenAddTaskModal(true);
    };


    const handleSaveTask = (newTask) => {
        console.log('New task:', newTask);
        onSaveTask(newTask);
        handleClose();
    };


    const handleTaskClick = (task) => {
        setSelectedTask(task);
        setIsEditingTask(true);
        setOpenAddTaskModal(true);
    };

    const handleCloseTaskModal = () => {
        setOpenTaskModal(false);
        setSelectedTask(null);
    };

    const renderZoneHeader = (zone) => {
        const isCollapsed = collapsedZones[zone];
        return (
            <div className="group-header-cell cursor-pointer" onClick={() => toggleZone(zone)}>
                {isCollapsed ? (
                    <ArrowRightIcon className="group-header-icon" />
                ) : (
                    <ArrowDropDownIcon className="group-header-icon" />
                )}
                <span className='text-black'>{zone}</span>
            </div>
        );
    };

    const handleUpdateTask = (updatedFields) => {
        console.log('Updated fields:', updatedFields);
        // setEvents(prevEvents => prevEvents.map(event => 
        //   event.id === selectedEvent.id ? {...event, ...updatedFields} : event
        // ));
    };

    const getTaskColor = (status) => {
        switch (status) {
            case 'UNASSIGNED':
                return 'orange';
            case 'ACCEPTED':
                return 'green';
            case 'REFUSED':
                return 'red';
            case 'ASSIGNED':
                return 'blue';

            default:
                return 'black';
        }
    };

    const isSameDay = (date1, date2) => {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    };

    const now = new Date();

    const renderEventCell = (property, day) => {
        const eventsForDay = property.events.filter(event => {
            const eventStart = new Date(event.startDate);
            const eventEnd = new Date(event.endDate);
            eventStart.setHours(0, 0, 0, 0);
            eventEnd.setHours(23, 59, 59, 999);
            return day >= eventStart && day <= eventEnd;
        })
        const calendarStartDate = daysInView[0];
        const tasksForDay = property.tasks.filter(task => isSameDay(task.date, day));
        return (
            <div className="event-container">
                <div className="half-day-line"></div>
                {eventsForDay.map((event, index) => {
                    const eventStart = new Date(event.startDate);
                    const isFirstDay = isSameDay(day, eventStart);
                    const daysLeft = Math.ceil((new Date(event.endDate) - day) / (1000 * 60 * 60 * 24) + 1);
                    const remainingDuration = Math.ceil((new Date(event.endDate) - now) / (1000 * 60 * 60 * 24) + 1);

                    return (
                        <div
                            key={`event-${index}`}
                            className="event-cell"
                            style={{
                                width: `calc(${Math.min(daysLeft, daysInView.length - daysInView.indexOf(day)) * 100}% + ${(Math.min(daysLeft, daysInView.length - daysInView.indexOf(day)) - 1) * 17}px)`,
                                backgroundColor: event.color,
                                zIndex: isFirstDay ? 1 : 1,
                            }}
                        >
                            {(isFirstDay || (eventStart < calendarStartDate && isSameDay(day, calendarStartDate))) && (
                                <div className="flex items-center gap-1 px-1">

                                    <Tooltip title={`${event.duration} Days - ${event.adults}A/${event.children}C - ${event.guestName} ${event.reservationNumber}`} arrow placement="right">
                                        {/* <Tooltip title="Create Task" arrow> */}
                                        <button
                                            className="text-gray-900 hover:text-gray-700 rounded font-bold !mr-2"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSelectedReservation(event);
                                                handleOpenAddTaskModal(property);
                                            }}
                                        >
                                            <AddIcon className="!text-base !rounded-full border hover:border-gray-700 !border-gray-900" />
                                        </button>
                                        {/* </Tooltip> */}
                                        <span className='event-title cursor-pointer' onClick={() => handleEventClick(event)}>
                                            {`${eventStart < now ? remainingDuration : event.duration} Days - ${event.adults}A/${event.children}C - ${event.guestName} ${event.reservationNumber}`}
                                        </span>

                                    </Tooltip>

                                </div>
                            )}
                        </div>
                    );
                })}
                <div className='absolute top-[23px] h-[70px] w-[100%] truncate'>
                    {tasksForDay.map((task, index) => (
                        <div
                            key={task.id || `task-${index}`}
                            className="cursor-pointer"
                            style={{
                                color: getTaskColor(task.assignmentStatus),
                            }}
                            onClick={() => handleTaskClick(task)}
                        >
                            <Tooltip title={`${task.title} ${task.time}`} className="!text-[10px]" arrow placement="right">
                                <span>{`${task.title} ${task.time}`}</span>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>
        );
    };
    const renderPropertyCell = (property) => {
        const status = getPropertyStatus(property);

        return (
            <div className="property-cell">
                <div>
                    <span className='property-name'>{property.name} </span>
                    <span className='property-status'>| {status}</span>
                </div>
                <div>
                    <button className='task-button' onClick={() => handleOpenAddTaskModal(property)}><i className="pi pi-plus"></i>Task</button>
                    <span className='last-service'>Last Service: {property.lastService}</span>
                </div>
            </div>
        );
    }
    const columns = [
        {
            field: 'property',
            header: <div style={{ width: '100%' }}>
                <CalendarHeader
                    formattedDateRange={formattedDateRange}
                    goToNextPeriod={goToNextPeriod}
                    goToToday={goToToday}
                    goToPreviousPeriod={goToPreviousPeriod}
                    setViewType={setViewType}
                    viewType={viewType}
                    scrollBackward={scrollBackward}
                    scrollForward={scrollForward}
                    canScrollForward={scrollOffset > 0}
                    setCurrentDate={setCurrentDate}
                    changeMonthView={changeMonthView}
                    currentDate={currentDate}
                />
            </div>,
            frozen: true,
            style: { width: '300px', zIndex: 2, padding: 0 }

        },
        ...daysInView.map((day, index) => ({
            field: `day${index}`,
            header: (
                <div className="flex flex-col items-center w-full !text-[12px]">
                    <div className="font-light">{formatDate(day).dayName}</div>
                    <div className="font-bold">{formatDate(day).day}</div>
                    <div className="font-light">{formatDate(day).month}</div>
                </div>
            ),
            style: { width: '80px', position: 'relative' }
        }))
    ];

    const data = groupedProperties.flatMap(group => {
        const zoneRow = { id: `zone_${group.zone}`, property: renderZoneHeader(group.zone), isZone: true };
        if (collapsedZones[group.zone]) {
            return [zoneRow];
        }
        return [
            zoneRow,
            ...group.properties.map(property => ({
                id: property.id,
                property: renderPropertyCell(property),
                ...daysInView.reduce((acc, day, index) => {
                    acc[`day${index}`] = renderEventCell(property, day);
                    return acc;
                }, {})
            }))
        ];
    });

    return (
        <div className="table-calendar-container">
            <DataTable
                value={data}
                className="table-calendar"
                scrollable
                scrollHeight="calc(100vh - 250px)"
                showGridlines
                frozenColumns={1}
                rowClassName={(rowData) => rowData.isZone ? 'group-header zone-row' : 'property-row'}
                loading={isLoading}
                emptyMessage={
                    <div className="text-center p-4 text-gray-500">
                        {isLoading ? (
                            <div className="flex justify-center items-center">
                                <CircularProgress color="inherit" className='text-sm' />
                            </div>
                        ) : (
                            <div className="flex justify-center items-center">
                                No properties available
                            </div>
                        )}
                    </div>
                }
            >
                {columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        frozen={index === 0}
                        style={{
                            ...(col.style || {}),
                            ...(index === 0 ? { position: 'sticky', left: 0, zIndex: 2 } : {})
                        }}
                        body={rowData => rowData[col.field]}
                    />
                ))}
            </DataTable>
            {selectedEvent && (
                <EventPopup
                    event={selectedEvent}
                    open={openModal}
                    onClose={handleCloseModal}
                />
            )}
            <AddTask
                open={openAddTaskModal}
                onClose={() => {
                    setOpenAddTaskModal(false);
                    setSelectedTask(null);
                    setIsEditingTask(false);
                    setSelectedReservation(null);
                }}
                staff={staffData}
                onSave={handleSaveTask}
                listing={selectedProperty}
                existingTask={isEditingTask ? selectedTask : null}
                listingId={listingId}
                selectedReservation={selectedReservation}
            />
            <AssignTask
                open={openTaskModal}
                handleClose={handleCloseTaskModal}
                staff={staffData}
                task={selectedTask}
                onTaskUpdated={onTaskUpdated}
            />
            {selectedTaskDetails && (
                <DetailsTask
                    event={selectedTaskDetails}
                    open={detailsTaskOpen}
                    onClose={handleCloseDetailsTask}
                    onUpdate={handleUpdateTask}
                />
            )}
        </div>
    );
};

export default TableCalendar;