import React, { useEffect, useState } from 'react';
// import { getLanguages } from 'features/reservation/services/serverApi.reservation';
import GuestCountCreateInput from '../../GuestCountCreateInput';

const GuestDetails = ({ formik }) => {
    // const [languages, setLanguages] = useState([]);
    const [defaultLanguage, setDefaultLanguage] = useState('');

    // useEffect(() => {
    //     fetchLanguages();
    // }, []);

    useEffect(() => {
        const firstName = formik.values.guestFirstName || '';
        const lastName = formik.values.guestLastName || '';
        const fullName = `${firstName} ${lastName}`.trim();
        formik.setFieldValue('guestName', fullName);
    }, [formik.values.guestFirstName, formik.values.guestLastName]);

    useEffect(() => {
        if (defaultLanguage && !formik.values.guestLanguage) {
            formik.setFieldValue('guestLanguage', defaultLanguage);
        }
    }, [defaultLanguage, formik.values.guestLanguage]);

    // const fetchLanguages = async () => {
    //     try {
    //         const languagesData = await getLanguages();
    //         setLanguages(languagesData);

    //         const frLanguage = languagesData.find(language => language._id === 'fr');
    //         if (frLanguage) {
    //             setDefaultLanguage(frLanguage._id);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching languages:', error);
    //     }
    // };

    const renderInputField = (label, name, type = 'text') => {
        if (name === 'guestName') {
            return null; 
        }

        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <input
                    type={type}
                    name={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[name]}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        fontSize: 'small',
                    }}
                />
                {formik.touched[name] && formik.errors[name] ? (
                    <div className="text-red-500 text-sm">{formik.errors[name]}</div>
                ) : null}
            </div>
        );
    };

    const renderSelectField = (label, name, options) => (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
            <select
                name={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name] || defaultLanguage}
                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                style={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    fontSize: 'small',
                }}
            >
                <option value="">Select a language</option>
                {options.map(option => (
                    <option key={option._id} value={option._id}>
                        {option.name}
                    </option>
                ))}
            </select>
            {formik.touched[name] && formik.errors[name] ? (
                <div className="text-red-500 text-sm">{formik.errors[name]}</div>
            ) : null}
        </div>
    );
     
    const checkinStatusOptions = [
        { _id: 'START', name: 'START' },
        { _id: 'END', name: 'END' }
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {renderInputField('First Name', 'guestFirstName')}
            {renderInputField('Last Name', 'guestLastName')}
            {renderInputField('Email', 'guestEmail', 'email')}
            {renderInputField('Phone', 'phone', 'tel')}
            <GuestCountCreateInput formik={formik} />
            {renderSelectField('Checkin Status', 'checkinStatus', checkinStatusOptions)}
            {renderInputField('Guest Language', 'guestLanguage')}
            {/* {renderSelectField('Guest Language', 'guestLanguage', languages)} */}
        </div>
    );
};

export default GuestDetails;