import React from 'react';
import { Tooltip } from '@mui/material';

const TimelineTranslations = {
  'Coming-Today': 'Arrivée aujourd\'hui',
  'Departure-today': 'Départ aujourd\'hui',
  'Coming-Tomorrow': 'Arrivée demain',
  'Departure-tomorrow': 'Départ demain',
  'Inside': 'Actuellement sur place',
  'Coming-in2days': 'Arrivée dans 2 jours',
  'Coming-in3days': 'Arrivée dans 3 jours',
  'Normal': 'Future Reservation',
  'Leave': 'Départ terminé',
  'One-Night': 'Une nuitée'
};

const ReservationColumns = ({
  handleDetailsClick,
  handleGuestClick,
  handleCalendarClick,
  handleTaskClick,
  openSnackbar,
  handleListingClick,
}) => {
  return [
    {
      field: null,
      uniqueId: 'calendar',
      headerLabel: 'Calendar',
      header: 'Calendar',
      body: (rowData) => (
        <span id={`calendarTooltip-${rowData.id}`}>
          <i
            className="pi pi-calendar text-medium-aquamarine font-semibold cursor-pointer size-4"
            style={{ fontWeight: 'bold' }}
            onClick={() =>
              handleCalendarClick(
                rowData.sojoriId,
                rowData.checkInDate,
                rowData.checkOutDate,
              )
            }
          ></i>
        </span>
      ),
      headerStyle: { width: '2.5rem' },
      header: () => (
        <>
          <span id="calendarHeaderTooltip">Calendar</span>
          <Tooltip title="Calendar" />
        </>
      ),
    },
    {
      field: null,
      uniqueId: 'details',
      headerLabel: 'Details',
      header: 'Details',
      body: (rowData) => (
        <span id={`detailsTooltip-${rowData.id}`}>
          <i
            className="pi pi-align-justify text-medium-aquamarine font-semibold cursor-pointer size-4"
            style={{ fontWeight: 'bold' }}
            onClick={() => handleDetailsClick(rowData.id)}
          ></i>
        </span>
      ),
      headerStyle: { width: '2.5rem' },
      header: () => (
        <>
          <span id="detailsHeaderTooltip">Details</span>
          <Tooltip title="Details" />
        </>
      ),
    },
    {
      field: null,
      uniqueId: 'contact',
      headerLabel: 'Contact',
      header: 'Contact',
      body: (rowData) => (
        <span id={`contactTooltip-${rowData.id}`}>
          <i
            className="pi pi-inbox text-medium-aquamarine font-bold cursor-pointer size-4"
            style={{ fontWeight: 'bold' }}
            onClick={() => handleTaskClick(rowData.reservationNumber)}
          ></i>
        </span>
      ),
      headerStyle: { width: '2.5rem' },
      header: () => (
        <>
          <span id="contactHeaderTooltip">Contact</span>
          <Tooltip title="Contact/Tasks" />
        </>
      ),
    },
    {
      field: 'reservationNumber',
      header: 'Reservation',
      body: (rowData) => (
        <Tooltip title="Click to copy reservation number">
          <span
            className="cursor-pointer"
            onClick={() => {
              const reservationNumber = rowData.reservationNumber;
              navigator.clipboard.writeText(reservationNumber);
              openSnackbar(
                'Reservation' +
                ' ' +
                reservationNumber +
                ' ' +
                'copied to clipboard',
              );
            }}
          >
            {rowData.reservationNumber}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'createdAt',
      header: 'Res Date',
    },
    {
      field: 'guestName',
      header: 'Guest',
      body: (rowData) => (
        <Tooltip title={rowData.guestName}>
          <span
            id={`guestNameTooltip-${rowData.id}`}
            className="text-medium-aquamarine font-bold cursor-pointer"
            onClick={() => handleDetailsClick(rowData.id)}
          >
            {rowData.guestName}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'checkInDate',
      header: 'Check-in',
    },
    {
      field: 'checkOutDate',
      header: 'Check-out',
    },
    {
      field: 'totalPrice',
      header: 'Price',
      body: (rowData) => (
        <>
          <span className="">{rowData.totalPrice} </span>
          <span className="font-bold">{rowData.currency}</span>
        </>
      ),
    },
    //     channelName
    // otaCommission
    // paymentCollect
    // byChannex
    // otaCode
    // voucherNo
    {
      field: 'channelName',
      header: (
        <Tooltip title="Channel">
          <span>Channel</span>
        </Tooltip>
      ),
      body: (rowData) => {
        const displayName = rowData.channelName === 'BookingCom' ? 'Booking' : rowData.channelName;
        return (
          <Tooltip title={displayName}>
            <span className="text-medium-aquamarine font-bold cursor-pointer">
              {displayName}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'otaCommission',
      header: (
        <Tooltip title="OTA Fee">
          <span>OTA Fee</span>
        </Tooltip>
      ),
      body: (rowData) => {
        return (
          <Tooltip title={rowData.otaCommission}>
            <span className="cursor-pointer">
              {rowData.otaCommission}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'paymentCollect',
      header: (
        <Tooltip title="Pay Collect">
          <span>Pay Collect</span>
        </Tooltip>
      ),
      body: (rowData) => {
        return (
          <Tooltip title={rowData.paymentCollect}>
            <span className="cursor-pointer">
              {rowData.paymentCollect}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'byChannex',
      header: (
        <Tooltip title="Channex">
          <span>Channex</span>
        </Tooltip>
      ),
      body: (rowData) => {
        const value = rowData.byChannex ? 'Yes' : 'No';
        return (
          <Tooltip title={value}>
            <span className={`${rowData.byChannex ? 'text-medium-aquamarine' : 'text-red-500'} font-bold cursor-pointer`}>
              {value}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'otaCode',
      header: (
        <Tooltip title="OTA ID">
          <span>OTA ID</span>
        </Tooltip>
      ),
      body: (rowData) => {
        return (
          <Tooltip title={rowData.otaCode}>
            <span className="font-bold cursor-pointer">
              {rowData.otaCode}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'voucherNo',
      header: (
        <Tooltip title="Voucher">
          <span>Voucher</span>
        </Tooltip>
      ),
      body: (rowData) => {
        return (
          <Tooltip title={rowData.voucherNo}>
            <span className="font-bold cursor-pointer">
              {rowData.voucherNo}
            </span>
          </Tooltip>
        );
      }
    },
    {
      field: 'checkInOutStatus',
      header: 'Check Status',
      body: (rowData) => (
        <Tooltip title={`Check-in: ${rowData.confirmedCheckInTime ? 'Arrived' : 'Not Arrived'}, Check-out: ${rowData.confirmedCheckOutTime ? 'Departed' : 'Not Departed'}`}>
          <span className="cursor-pointer">
            <span className={`font-bold ${rowData.confirmedCheckInTime ? 'text-green-500' : 'text-red-500'}`}>
              A
            </span>
             / 
            <span className={`font-bold ${rowData.confirmedCheckOutTime ? 'text-green-500' : 'text-red-500'}`}>
              D
            </span>
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'Online checkin',
      header: 'Online Ck-in',
      body: (rowData) => {
        const x = rowData.x;
        const y = rowData.y;

        let color;
        switch (rowData.checkinStatus) {
          case 'START':
            color = 'text-orange-500';
            break;
          case 'END':
            color = 'text-green-500';
            break;
          default:
            color = 'text-red-500';
        }

        return (
          <span
            className={`${color} font-bold cursor-pointer`}
            onClick={() => handleGuestClick(rowData.id)}
          >
            {`${x}/${y}`}
          </span>
        );
      },
    },
    {
      field: 'status',
      header: 'Status',
      body: (rowData) => {
        const redStatuses = [
          'CancelledByAdmin',
          'CancelledByCustomer',
          'CancelledByOta',
          'CancelledAfterFailedPayment',
          'OtherCancellation',
          'RefusedBySoj',
        ];
        const isRedStatus = redStatuses.includes(rowData.status);

        return (
          <Tooltip title={rowData.status}>
            <span
              id={`statusTooltip-${rowData.id}`}
              className={`cursor-pointer ${isRedStatus ? '!text-red-500' : '!text-[#00b4b4]'
                }`}
            >
              {rowData.status}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'paymentStatus',
      header: 'Pay Status',
      body: (rowData) => {
        const paymentStatus = rowData.paymentStatus || '';
        const isPaid = paymentStatus.toLowerCase() === 'paid';
        const paymentRedirectToSuccess = rowData.paymentRedirectToSuccess;

        return (
          <Tooltip title={`${paymentStatus}${isPaid
              ? paymentRedirectToSuccess
                ? ' (Redirect to Success)'
                : ' (No Redirect to Success)'
              : ''
            }`}>
            <span
              id={`paymentStatusTooltip-${rowData.id}`}
              className={`cursor-pointer ${isPaid ? '!text-medium-aquamarine' : '!text-red-500'
                }`}
            >
              {paymentStatus}
              {isPaid && (
                <span
                  className={paymentRedirectToSuccess ? '' : '!text-red-500'}
                >
                  {paymentRedirectToSuccess ? ' +' : ' -'}
                </span>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'timeLine',
      header: 'Timeline',
      body: (rowData) => (
        <Tooltip title={TimelineTranslations[rowData.timeLine] || rowData.timeLine}>
          <span className="cursor-pointer">
            {TimelineTranslations[rowData.timeLine] || rowData.timeLine}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'phone',
      header: 'phone',
    },
    {
      field: 'roomTypeName',
      header: 'Room',
      headerLabel: 'Room',
      uniqueId: 'roomType',
      body: (rowData) => (
        <Tooltip title={rowData.roomTypeName}>
          <span
            id={`roomTypeTooltip-${rowData.id}`}
            className="cursor-pointer text-medium-aquamarine"
          >
            {rowData?.roomTypeName}
          </span>
        </Tooltip>
      ),
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'listing',
      header: 'Listing',
      body: (rowData) => (
        <Tooltip title={rowData.listing}>
          <span
            id={`listingTooltip-${rowData.id}`}
            className="cursor-pointer font-bold"
            onClick={() => {
              if (rowData.listingData) {
                handleListingClick(rowData.listingData);
              }
            }}
          >
            {rowData?.listing}
          </span>
        </Tooltip>
      ),
    },
  ];
};

export default ReservationColumns;
