import React, { useState } from 'react';
import { Send, MessageCircle } from 'lucide-react';
import { Alert, Snackbar } from '@mui/material';
import { replyToReview } from 'features/reviews/services/serverApi.reviewConfig';
import { Star, Sparkle, MapPin, Coffee, Key, Users, Smile, Frown, FolderLock, Folder, Reply } from 'lucide-react';
import airbnb from "assets/images/airbnb.png";
import booking from "assets/images/booking.png";

const ReviewDetailsRes = ({ review, containerWidth, onReplySubmitted, reservationInfo }) => {
    const [replyText, setReplyText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

    if (!review) {
        return (
            <div className="h-full flex items-center justify-center">
                <p className="text-gray-500">Select a review to view details</p>
            </div>
        );
    }

    const isAirbnb = review.ota === 'AirBNB';


    const formatDate = (dateStr) => {
        if (!dateStr) return 'N/A';
        try {
            const date = new Date(dateStr);
            const day = date.getDate();
            const month = date.toLocaleDateString('en-US', { month: 'short' });
            const year = date.getFullYear();
            const suffix = ['th', 'st', 'nd', 'rd'][day % 10 > 3 ? 0 : (day % 100 - day % 10 != 10) * (day % 10)];
            return `${day}${suffix} ${month} ${year}`;
        } catch (error) {
            console.error('Date formatting error:', error);
            return 'Invalid date';
        }
    };

    const handleReplySubmit = async () => {
        if (!replyText.trim()) return;

        setIsSubmitting(true);
        try {
            await replyToReview(replyText, review._id);

            setAlert({
                open: true,
                message: 'Reply submitted successfully',
                severity: 'success'
            });

            setReplyText('');
            if (onReplySubmitted) {
                onReplySubmitted();
            }
        } catch (error) {
            setAlert({
                open: true,
                message: 'Failed to submit reply',
                severity: 'error'
            });
            console.error('Error submitting reply:', error);
        } finally {
            setIsSubmitting(false);
        }
    };


    const getScoreIcon = (label) => {
        const iconProps = { className: "w-4 h-4" };
        switch (label.toLowerCase()) {
            case 'overall':
                return <Star {...iconProps} />;
            case 'value':
                return <Star {...iconProps} />;
            case 'clean':
                return <Sparkle {...iconProps} />;
            case 'location':
                return <MapPin {...iconProps} />;
            case 'comfort':
                return <Coffee {...iconProps} />;
            case 'facilities':
                return <Key {...iconProps} />;
            case 'staff':
                return <Users {...iconProps} />;
            case 'check-in':
                return <Star {...iconProps} />;
            case 'communication':
                return <Star {...iconProps} />;
            case 'accuracy':
                return <Star {...iconProps} />;
            default:
                return null;
        }
    };

    const getScores = () => {
        if (isAirbnb) {
            return [
                { label: 'Overall', score: review.overall_score || 0 },
                { label: 'Clean', score: review.scores?.find(s => s.category === 'clean')?.score || 0 },
                { label: 'Accuracy', score: review.scores?.find(s => s.category === 'accuracy')?.score || 0 },
                { label: 'Check-in', score: review.scores?.find(s => s.category === 'checkin')?.score || 0 },
                { label: 'Communication', score: review.scores?.find(s => s.category === 'communication')?.score || 0 },
                { label: 'Location', score: review.scores?.find(s => s.category === 'location')?.score || 0 },
                { label: 'Value', score: review.scores?.find(s => s.category === 'value')?.score || 0 },


            ];
        } else {
            return [
                { label: 'Overall', score: review.overall_score || 0 },
                { label: 'Value', score: review.scores?.find(s => s.category === 'value')?.score || 0 },
                { label: 'Clean', score: review.scores?.find(s => s.category === 'clean')?.score || 0 },
                { label: 'Location', score: review.scores?.find(s => s.category === 'location')?.score || 0 },
                { label: 'Comfort', score: review.scores?.find(s => s.category === 'comfort')?.score || 0 },
                { label: 'Facilities', score: review.scores?.find(s => s.category === 'facilities')?.score || 0 },
                { label: 'Staff', score: review.scores?.find(s => s.category === 'staff')?.score || 0 }
            ];
        }
    };

    const getReviewContent = () => {
        if (isAirbnb) {
            return {
                headline: null,
                positive: null,
                negative: null,
                privateFeedback: review.raw_content?.private_feedback,
                publicFeedback: review.raw_content?.public_review

            };
        } else {
            return {
                headline: review.raw_content?.headline,
                positive: review.raw_content?.positive,
                negative: review.raw_content?.negative,
                privateFeedback: null,
                publicFeedback: null
            };
        }
    };

    const renderTags = () => {
        if (!review.tags || review.tags.length === 0) return null;

        return (
            <div className="flex flex-wrap gap-2 !mt-2">
                {review.tags.map((tag, index) => (
                    <span
                        key={index}
                        className="px-2 py-1 text-xs bg-green-50 text-green-600 rounded-full"
                    >
                        {tag.replace('guest_review_host_positive_', '').replace(/_/g, ' ')}
                    </span>
                ))}
            </div>
        );
    };

    const content = getReviewContent();


    const renderReplySection = () => {
        if (review.is_replied && review.reply) {
            return (
                <div className="border-t p-4">
                    <div className="flex gap-2 flex-col">
                        <div className="flex items-center gap-2">
                            <Reply className="w-5 h-5 text-green-500" />
                            <label className="text-sm font-medium text-gray-700">
                                Your Response
                            </label>
                        </div>
                        <div className="px-3 py-2 bg-gray-50 rounded-lg">
                            <p className="text-gray-700 whitespace-pre-line">{review.reply.reply}</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="border-t p-4">
                <div className="flex gap-2 flex-col">
                    <label
                        htmlFor="replyInput"
                        className="text-sm font-medium text-gray-700"
                    >
                        Your Response
                    </label>
                    <textarea
                        id="replyInput"
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        disabled={isSubmitting}
                        rows={3}
                        className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
                    />
                    <div className="flex flex-col gap-2">
                        <button
                            onClick={handleReplySubmit}
                            disabled={!replyText.trim() || isSubmitting}
                            className="px-4 py-1 bg-green-500 text-white rounded-lg disabled:opacity-50 flex justify-center items-center !rounded-lg"
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col h-full bg-white">
            <div className="border-b p-4 bg-gray-50">
                <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2 !gap-2">
                        <h2 className="!text-base !font-semibold !m-0">
                            {review.reservation?.guestName || review.allData?.attributes?.guest_name || 'Anonymous'}
                        </h2>

                        <div className="!h-[20px] !w-[2px] !bg-gray-400 !mx-2" />

                        <div className="flex items-center">
                            <img
                                src={isAirbnb ? airbnb : booking}
                                alt={review.ota}
                                className="w-6 h-6 object-contain ml-2"
                            />
                            <span className="px-2 py-1 text-sm text-gray-400">
                                {review.ota}
                            </span>
                        </div>
                    </div>
                    {/* <span className="text-sm text-gray-500">
            {formatDate(review.createdAt)}
          </span> */}
                </div>
                {/* {isAirbnb && ( */}
                <div className="flex items-center mt-2 gap-1 space-x-4">
                    <div className="flex items-center">
                        <span className="text-sm text-gray-500 mr-2">Arrival:</span>
                        <span className="text-sm text-gray-500 mr-2">
                            {formatDate(reservationInfo?.arrivalDate)},
                        </span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-sm text-gray-500 mr-2">Nights:</span>
                        <span className="text-sm text-gray-500 mr-2">
                            {reservationInfo?.nights || "N/A"},
                        </span>
                    </div>
                    <div className="flex items-center">
                        <span className="text-sm text-gray-500 mr-2">Listing:</span>
                        <span className="text-sm text-gray-500 mr-2">
                            {reservationInfo?.listing?.name || "N/A"}
                        </span>
                    </div>
                </div>
                {/* )} */}
            </div>

            <div className="flex-1 overflow-y-auto p-4">
                <div className="grid grid-cols-7 gap-4 mb-6">
                    {getScores().map((item, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="mb-2">
                                <div className="w-10 h-10 rounded-full border-3 !border-green-400 flex items-center justify-center">
                                    <div className="text-gray-500">
                                        {getScoreIcon(item.label)}
                                    </div>
                                </div>
                            </div>
                            <span className="text-xs text-gray-600 mb-1">{item.label}</span>
                            <span className="text-sm font-medium">{item.score}</span>
                        </div>
                    ))}
                </div>

                <div className="!mt-6 w-full h-[10px] bg-gray-200"></div>

                <div className="!border-2-b !border-gray-200 !my-4" />

                <div className="space-y-4 px-2 md:!px-32 pt-4">
                    {!isAirbnb && content.headline && (
                        <h5 className="!font-semibold !text-base">&ldquo;{content.headline}&rdquo;</h5>
                    )}
                    {!isAirbnb && (
                        <div className="flex items-start space-x-2 gap-2">
                            <Smile className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                            <p className="text-gray-700 whitespace-pre-line !text-base !leading-7">
                                {content.positive || 'N/A'}
                            </p>
                        </div>
                    )}

                    {!isAirbnb && (
                        <div className="flex items-start space-x-2 gap-2">
                            <Frown className="w-5 h-5 text-green-500 mt-1 flex-shrink-0" />
                            <p className="text-gray-700 whitespace-pre-line !text-base !leading-7">
                                {content.negative || 'N/A'}
                            </p>
                        </div>
                    )}
                    {isAirbnb && (
                        <div className="">
                            <div className="flex items-center space-x-2 gap-2 mb-2">
                                <Folder className="w-5 h-5 text-green-500 mt-1 flex-shrink-0 !mt-0" />
                                <h4 className="!text-base font-medium !mb-0">Public Feedback</h4>
                            </div>
                            <p className="text-gray-700 whitespace-pre-line !text-base !leading-7">
                                {content.publicFeedback}
                            </p>
                        </div>
                    )}
                    {isAirbnb && (
                        <div className="!my-4 w-full h-[1px] bg-gray-300"></div>
                    )}

                    {isAirbnb && content.privateFeedback && (
                        <div className="">
                            <div className="flex items-center space-x-2 gap-2 mb-2">
                                <FolderLock className="w-5 h-5 text-green-500 mt-1 flex-shrink-0 !mt-0" />
                                <h4 className="!text-base font-medium !mb-0">Private Feedback</h4>
                            </div>
                            <p className="text-gray-600 text-sm !mb-1">{content.privateFeedback}</p>
                        </div>
                    )}
                    {renderTags()}
                </div>
            </div>


            {renderReplySection()}


            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => setAlert({ ...alert, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setAlert({ ...alert, open: false })}
                    severity={alert.severity}
                    variant="filled"
                >
                    {alert.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ReviewDetailsRes;