import React from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const StatusFilter = ({ status, setStatus }) => {
  const statusOptions = [
    { id: 'Pending', name: 'Pending' },
    { id: 'Confirmed', name: 'Confirmed' },
    { id: 'Completed', name: 'Completed' },
    { id: 'CancelledByAdmin', name: 'CancelledByAdmin' },
    { id: 'CancelledByCustomer', name: 'CancelledByCustomer' },
    { id: 'CancelledByOta', name: 'CancelledByOta' },
    { id: 'CancelledAfterFailedPayment', name: 'CancelledAfterFailedPayment' },
    { id: 'OtherCancellation', name: 'OtherCancellation' }
  ];

  return (
    <FilterSearch
      options={statusOptions}
      selectedItems={status}
      onItemsChange={setStatus}
      placeholder="Select Status"
      idKey="id"
      labelKey="name"
      width={250}
    />
  );
};

export default StatusFilter;