import moment from 'moment';

export const getDateRange = (option) => {
  const today = moment();
  switch (option) {
    case 'Current month':
      return {
        start: today.clone().startOf('month').format('YYYY-MM-DD'),
        end: today.clone().endOf('month').format('YYYY-MM-DD'),
      };
    case 'Last month':
      return {
        start: today
          .clone()
          .subtract(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: today
          .clone()
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD'),
      };
    case 'Next Month':
      return {
        start: today
          .clone()
          .add(1, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: today.clone().add(1, 'month').endOf('month').format('YYYY-MM-DD'),
      };
    case 'Today':
      return {
        start: today.clone().format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'Yesterday':
      return {
        start: today.clone().subtract(1, 'day').format('YYYY-MM-DD'),
        end: today.clone().subtract(1, 'day').format('YYYY-MM-DD'),
      };
    case 'Tomorrow':
      return {
        start: today.clone().add(1, 'day').format('YYYY-MM-DD'),
        end: today.clone().add(1, 'day').format('YYYY-MM-DD'),
      };
    case 'Current Year':
      return {
        start: today.clone().startOf('year').format('YYYY-MM-DD'),
        end: today.clone().endOf('year').format('YYYY-MM-DD'),
      };
    case 'Last Year':
      return {
        start: today
          .clone()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYY-MM-DD'),
        end: today
          .clone()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYY-MM-DD'),
      };

    case 'Last 12 months':
      return {
        start: today.clone().subtract(12, 'months').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'Last 3 months':
      return {
        start: today.clone().subtract(3, 'months').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'Last 30 days':
      return {
        start: today.clone().subtract(30, 'days').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'Last 7 days':
      return {
        start: today.clone().subtract(7, 'days').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };

    case 'YTD':
      return {
        start: today.clone().startOf('year').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'MTD':
      return {
        start: today.clone().startOf('month').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'QTD':
      return {
        start: today.clone().startOf('quarter').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    case 'WTD':
      return {
        start: today.clone().startOf('week').format('YYYY-MM-DD'),
        end: today.clone().format('YYYY-MM-DD'),
      };
    default:
      return {
        start: today.clone().startOf('month').format('YYYY-MM-DD'),
        end: today.clone().endOf('month').format('YYYY-MM-DD'),
      };
  }
};