import React, { useState, useEffect } from 'react';
import { CircularProgress, Button, Typography, Tooltip, Box } from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  getClientWhiteList,
  blocClientWhiteList,
  updateClientWhiteList,
} from '../services/serverApi.task';
import { ToastContainer, toast } from 'react-toastify';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SearchIcon from '@mui/icons-material/Search';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AdminFilter from './AdminFilter';
import DeleteUserDialog from './DeleteUserDialog';
import ModifyClientWhiteList from './ModifyClientWhiteList';
import { getReservation } from '../services/serverApi.task';

const PublicClientWhiteList = () => {
  const [admins, setAdmins] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState('all');
  const [bannedFilter, setBannedFilter] = useState('all');

  useEffect(() => {
    fetchAdmins();
  }, [page, limit, searchText, deletedFilter, bannedFilter]);

  const fetchAdmins = async () => {
    setIsLoading(true);
    try {
      const params = {
        page,
        limit,
        phone: searchText,
        communicated:
          deletedFilter === 'all' ? undefined : deletedFilter === 'true',
        blocked: bannedFilter === 'all' ? undefined : bannedFilter === 'true',
      };

      const response = await getClientWhiteList(params);

      if (response && Array.isArray(response.data)) {
        setAdmins(response.data);
        setTotalCount(response.total || 0);
        setIsNextDisabled((page + 1) * limit >= response.total);
      } else {
        setAdmins([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching admins:', error);
      setAdmins([]);
      setTotalCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  const afterActionCreated = (msg) => {
    toast.success(msg);
    // setTimeout(() => {
    //   fetchAdmins();
    // }, 5000);
  };

  const handleUpdate = (adminMember, action) => {
    setSelectedAdmin(adminMember);
    setOpenModal(true);
    switch (action) {
      case 'deleteClientWithList':
        handleOpenDeleteDialog();
        break;
      case 'updateClientWithList':
        handleOpenUpdateDialog();
        break;

      default:
        break;
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-1">
        <button
          className="px-2 py-1 bg-red-500 !rounded-md mr-2"
          onClick={() => handleUpdate(rowData, 'deleteClientWithList')}
          disabled={rowData.deleted}
        >
          <BlockIcon className="text-white" />
        </button>
        <button
          className="px-2 py-1 bg-medium-aquamarine !rounded-md"
          onClick={() => handleUpdate(rowData, 'updateClientWithList')}
          disabled={rowData.deleted}
        >
          <EditOffIcon className="text-white" />
        </button>
      </div>
    );
  };
  const blocFunction = async () => {
    try {
      const { data } = await blocClientWhiteList(selectedAdmin._id);
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin._id === selectedAdmin._id ? data : admin,
        )
      );
      toast.success(`Client ${selectedAdmin.phone} has been blocked successfully`);
      handleCloseDeleteDialog();
    } catch (err) {
      console.error('Error blocking client:', err);
      toast.error('Failed to block client');
    }
  };
  const updateFunction = async (id, body) => {
    try {
      const reservationIds = body.reservationIds.map(res => res.reservationId);
      const reservationResponse = await getReservation({
        reservationId: reservationIds,
        limit: 100,
        page: 0
      });

      body.reservationIds = body.reservationIds.map(originalRes => {
        const fetchedReservation = reservationResponse.data.find(
          fetchedRes => fetchedRes._id === originalRes.reservationId
        );

        return {
          ...originalRes,
          arrivalDate: fetchedReservation?.arrivalDate || originalRes.arrivalDate,
          departureDate: fetchedReservation?.departureDate || originalRes.departureDate,
          type: originalRes.type
        };
      });

      const { data } = await updateClientWhiteList(id, body);
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin._id === id ? data : admin
        )
      );
      handleCloseUpdateDialog();
      toast.success('Client updated');
    } catch (err) {
      console.error('Full error details:', err);
      console.error('Error response:', err.response);
      toast.error(err.response?.data?.message || 'Error updating Client from Backend');
    }
  };


  const setClientToStateAfterUpdate = async (data) => {
    setAdmins((prevAdmins) =>
      prevAdmins.map((admin) =>
        admin._id === selectedAdmin._id ? data : admin,
      ),
    );
  };
  const columns = [
    {
      field: 'phone',
      header: 'phone',
      width: '100px',
      body: (rowData) => (
        <Tooltip title={rowData.phone} enterDelay={500} leaveDelay={200} placement="left">
          <div className="font-medium">{rowData.phone}</div>
        </Tooltip>
      ),
    },
    {
      field: 'name',
      header: 'name',
      width: '100px',
      body: (rowData) => (
        <Tooltip title={rowData.name || 'No name'} enterDelay={500} leaveDelay={200} placement="left">
          <div className="font-medium">{rowData.name}</div>
        </Tooltip>
      ),
    },
    {
      field: 'country',
      header: 'country',
      width: '100px',
      body: (rowData) => (
        <Tooltip title={rowData.country || 'No country'} enterDelay={500} leaveDelay={200} placement="left">
          <div className="font-medium">{rowData.country}</div>
        </Tooltip>
      ),
    },
    {
      field: 'language',
      header: 'language',
      width: '100px',
      body: (rowData) => (
        <Tooltip title={rowData.language || 'No language'} enterDelay={500} leaveDelay={200} placement="left">
          <div className="font-medium">{rowData.language}</div>
        </Tooltip>
      ),
    },
    {
      field: 'communicated',
      header: 'communicated',
      width: '100px',
      body: (rowData) => (
        <Tooltip
          title={`Communicated: ${rowData.communicated ? 'YES' : 'NO'}`}
          enterDelay={500}
          leaveDelay={200} placement="left"
        >
          <div className="flex items-center gap-1">
            {rowData.communicated ? (
              <span className="inline-flex items-center gap-1 text-green-500">
                YES
              </span>
            ) : (
              <span className="inline-flex items-center gap-1 text-red-500">
                NO
              </span>
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'blocked',
      header: 'blocked',
      width: '100px',
      body: (rowData) => (
        <Tooltip
          title={`Blocked: ${rowData.blocked ? 'YES' : 'NO'}`}
          enterDelay={500}
          leaveDelay={200} placement="left"
        >
          <div className="flex items-center gap-1">
            {rowData.blocked ? (
              <span className="inline-flex items-center gap-1 text-red-500">
                YES
              </span>
            ) : (
              <span className="inline-flex items-center gap-1 text-green-500">
                NO
              </span>
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'reservation',
      header: 'reservation',
      width: '100px',
      body: (rowData) => (
        <Tooltip
          title={`Reservation: ${rowData.reservation ? 'YES' : 'NO'}`}
          enterDelay={500}
          leaveDelay={200} placement="left"
        >
          <div className="flex items-center gap-1">
            {rowData.reservation ? (
              <span className="inline-flex items-center gap-1 text-green-500">
                YES
              </span>
            ) : (
              <span className="inline-flex items-center gap-1 text-red-500">
                NO
              </span>
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'reservationsNbr',
      header: 'reservationsNbr',
      width: '100px',
      body: (rowData) => (
        <Tooltip
          title={`Reservations: ${(rowData.reservationIds || []).length}`}
          enterDelay={500}
          leaveDelay={200} placement="left"
        >
          <div className="capitalize">
            {(rowData.reservationIds || []).length}
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'action',
      header: 'Action',
      width: '100px',
      body: (rowData) => (
        <div className="flex gap-1">
          <Tooltip title="Block Client" enterDelay={500} leaveDelay={200} placement="left">
            <button
              className="px-2 py-1 bg-red-500 !rounded-md mr-2"
              onClick={() => handleUpdate(rowData, 'deleteClientWithList')}
              disabled={rowData.deleted}
            >
              <BlockIcon className="text-white" />
            </button>
          </Tooltip>
          <Tooltip title="Modify Client" enterDelay={500} leaveDelay={200} placement="left">
            <button
              className="px-2 py-1 bg-medium-aquamarine !rounded-md"
              onClick={() => handleUpdate(rowData, 'updateClientWithList')}
              disabled={rowData.deleted}
            >
              <EditOffIcon className="text-white" />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const handleOpenUpdateDialog = () => {
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  return (
    <div className="card p-4">
      <div className="flex items-center justify-between mb-4">
        <Typography
          variant="h4"
          component="h1"
          className="!flex !items-center !gap-1 !md:text-2xl !text-lg"
        >
          <AdminPanelSettingsIcon className="!md:text-3xl !text-xl" /> Client
          White List Management
        </Typography>
      </div>

      <ToastContainer position="top-right" autoClose={3000} />

      <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-2">
        <div className="w-full md:flex-grow md:mr-4">
          <div className="flex w-full bg-white border rounded-md h-8 md:h-10">
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              placeholder="Search by username..."
              className="p-2 text-xs md:text-xs outline-none rounded-md w-full"
            />
            <div className="px-2">
              <SearchIcon className="!w-3 !h-3 md:!w-4 md:!h-4" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress sx={{ color: '#00b4b4' }} />
          </Box>
        ) : admins.length > 0 ? (
          <GlobalTable
            data={admins}
            columns={columns}
            page={page}
            hasPagination={true}
            onPageChange={handlePageChange}
            isNextDisabled={isNextDisabled}
            limit={limit}
            onLimitChange={handleLimitChange}
            rowsPerPageOptions={[10, 25, 50]}
            totalCount={totalCount}
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            <CircularProgress sx={{ color: '#00b4b4' }} />
          </div>
        )}
      </div>

      <DeleteUserDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onAdminCreated={() =>
          afterActionCreated('Administrator deleted successfully')
        }
        functionToExecute={() => blocFunction()}
        user={selectedAdmin}
        title={'Bloque Client'}
        message={`are you sure want to block ${selectedAdmin?.phone || ''}`}
        btnTxt={'bloque'}
        btnClass={'text-white !bg-red-600 !hover:bg-red-600/90'}
      />
      <ModifyClientWhiteList
        open={openUpdateDialog}
        handleClose={handleCloseUpdateDialog}
        staff={selectedAdmin}
        functionToExecute={(id, body) => updateFunction(id, body)}
      />
    </div>
  );
};

export default PublicClientWhiteList;
