import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  TextField,
  Button,
  Avatar,
  Alert,
  Snackbar,
  IconButton
} from '@mui/material';
import { Send, Image } from 'lucide-react';
import { sendMessage } from '../services/serverApi.messageConfig';

function MessageDetail({ thread }) {
  const [messageText, setMessageText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [thread?.messages]);

  if (!thread) {
    return (
      <Box className="h-full flex items-center justify-center">
        <Typography color="textSecondary">
          Select a conversation to view messages
        </Typography>
      </Box>
    );
  }

  const isValidDate = (dateStr) => {
    if (!dateStr) return false;
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date);
  };

  const formatDate = (dateStr) => {
    if (!isValidDate(dateStr)) {
      return 'No date available';
    }
  
    try {
      const date = new Date(dateStr);
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    } catch (error) {
      console.warn(`Error formatting date: ${dateStr}`, error);
      return 'Invalid date';
    }
  };

  const handleMessageSubmit = async () => {
    if (!messageText.trim()) return;

    setIsSubmitting(true);
    try {
      await sendMessage(messageText, thread._id);
      setAlert({
        open: true,
        message: 'Message sent successfully',
        severity: 'success'
      });
      setMessageText('');
    } catch (error) {
      setAlert({
        open: true,
        message: 'Failed to send message',
        severity: 'error'
      });
      console.error('Error sending message:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isPropertyMessage = (message) => message.sender === 'property';

  return (
    <Box className="flex flex-col h-full w-full">
      <Box className="p-3 md:p-4 border-b">
      <Box className="flex items-center gap-3">
        <Avatar sx={{ width: 40, height: 40 }}>
        {thread.guestName?.[0] || 'G'}
        </Avatar>
        <Box>
        <Typography variant="subtitle1" className="font-medium">
          {thread.guestName || 'Guest'}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {thread.channelName} · {thread.reservationNumber}
        </Typography>
        </Box>
      </Box>
      </Box>

      <List 
      className="flex-grow overflow-y-scroll scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100 p-3 md:p-4 space-y-4"
      style={{ 
        maxHeight: 'calc(100vh - 200px)',
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable'
      }}
      >
      {thread.messages?.map((message) => (
        <ListItem 
        key={message.id} 
        className={`flex flex-col mb-3 ${
          isPropertyMessage(message) 
          ? '!items-end' 
          : '!items-start'
        }`}
        >
        <Box 
          className={`max-w-[80%] rounded-lg p-3 ${
          isPropertyMessage(message)
            ? 'mr-auto bg-blue-50'
            : 'ml-auto bg-gray-50'
          }`}
        >
          <Typography variant="body2" className="whitespace-pre-line mb-2">
          {message.message}
          </Typography>
          <Typography variant="caption" color="textSecondary">
          {formatDate(message.date)}
          </Typography>
        </Box>
        </ListItem>
      ))}
      <div ref={messagesEndRef} />
      </List>

      <div className="border-t p-4">
      <div className="flex gap-2 flex-col">
        <label
        htmlFor="messageInput"
        className="text-sm font-medium text-gray-700"
        >
        Your Response
        </label>
        <textarea
        id="messageInput"
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
        disabled={isSubmitting}
        rows={3}
        className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handleMessageSubmit();
          }
        }}
        />
        <div className="flex flex-col gap-2">
        <button
          onClick={handleMessageSubmit}
          disabled={!messageText.trim() || isSubmitting}
          className="px-4 py-1 bg-green-500 text-white rounded-lg disabled:opacity-50 flex justify-center items-center !rounded-lg"
        >
          Send
        </button>
        </div>
      </div>
      </div>

      <Snackbar
      open={alert.open}
      autoHideDuration={6000}
      onClose={() => setAlert({ ...alert, open: false })}
      >
      <Alert
        onClose={() => setAlert({ ...alert, open: false })}
        severity={alert.severity}
      >
        {alert.message}
      </Alert>
      </Snackbar>
    </Box>
    );
}

export default MessageDetail;