import React, { useState } from 'react';
import FilterSearch from 'components/FilterSearch/FilterSearch';

const ListingFilter = ({ listings, selectedListings, setSelectedListings }) => {
  return (
    <FilterSearch
      options={listings}
      selectedItems={selectedListings}
      onItemsChange={setSelectedListings}
      placeholder="Select Listings"
      idKey="id"
      labelKey="name"
    />
  );
};

export default ListingFilter;