import React, { useState, useEffect } from 'react';

const ListingDetails = ({ listings, editableDetails, isEditMode, handleInputChange }) => {
    const [selectedListing, setSelectedListing] = useState(null);
    
    useEffect(() => {
        if (editableDetails.sojoriId) {
            const listing = listings.find(l => l.id === editableDetails.sojoriId);
            setSelectedListing(listing);
            
            console.log('Selected Listing Property Unit:', listing?.propertyUnit);
            console.log('Selected Listing Details:', listing);
            
            if (listing?.propertyUnit === 'Single' && listing.roomTypes?.[0]) {
                const roomTypeId = listing.roomTypes[0]._id;
                handleInputChange({
                    target: {
                        name: 'roomTypeId',
                        value: roomTypeId
                    }
                });
                console.log('Single Property - Selected Room Type ID:', roomTypeId);
            }
        }
    }, [editableDetails.sojoriId, listings]);

    useEffect(() => {
        if (editableDetails.roomTypeId && selectedListing?.propertyUnit === 'Multi') {
            console.log('Multi Property - Selected Room Type ID:', editableDetails.roomTypeId);
        }
    }, [editableDetails.roomTypeId, selectedListing]);

    const handleListingSelection = (e) => {
        handleInputChange(e);
        handleInputChange({
            target: {
                name: 'roomTypeId',
                value: ''
            }
        });
    };

    return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 my-4">
                <div className="col-span-1 md:col-span-2 !mt-[20px]">
                    <label className="font-bold text-[#676a6c] text-sm mb-2">Listing</label>
                    <div className="flex flex-col">
                        <select
                            id="listing-select"
                            className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
                            name="sojoriId"
                            disabled={!isEditMode}
                            value={editableDetails.sojoriId}
                            onChange={handleListingSelection}
                            style={{
                                backgroundColor: isEditMode ? 'white' : '#eee',
                                border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                                fontSize: 'small',
                            }}
                        >
                            <option value="">Select Listing...</option>
                            {listings.map(listing => (
                                <option key={listing.id} value={listing.id}>
                                    {listing.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {selectedListing?.propertyUnit === 'Multi' && (
                    <div className="col-span-1 md:col-span-2 !mt-[20px]">
                        <label className="font-bold text-[#676a6c] text-sm mb-2">Room Type</label>
                        <div className="flex flex-col">
                            <select
                                id="roomType-select"
                                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
                                name="roomTypeId"
                                disabled={!isEditMode}
                                value={editableDetails.roomTypeId || ''}
                                onChange={handleInputChange}
                                style={{
                                    backgroundColor: isEditMode ? 'white' : '#eee',
                                    border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                                    fontSize: 'small',
                                }}
                            >
                                <option value="">Select Room Type...</option>
                                {selectedListing?.roomTypes?.map((roomType) => (
                                    <option key={roomType._id} value={roomType._id}>
                                        {roomType.roomTypeName} 
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ListingDetails;