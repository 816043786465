import React, { useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Grid, Paper, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import { ChevronRight, ChevronLeft } from 'lucide-react';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    zoomPlugin
);

const Charts = ({ reservationStats = [], channelStats = [], checkinByChannel = [], revenueByChannel = [], bookedNightsByChannel = [], reservationPerChannelData = {}, isLoadingReservationStats, isLoadingChannelStats, isLoadingCheckinByChannel, isLoadingRevenueByChannel, isLoadingBookedNightsByChannel, isLoadingReservationPerChannel, rentalRevenuePerReservationData, isLoadingRentalRevenuePerReservation, selectedPeriod, onPeriodChange, startDate, endDate, onPeriodIndexChange, currentPeriodIndex
}) => {
    // const [currentPeriodIndex, setCurrentPeriodIndex] = useState(0);


    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    const getVisibleData = (data, period, startDate, endDate) => {
        if (!data.length) return { visibleData: [], hasNext: false };

        const start = moment(startDate);
        const end = moment(endDate);

        const filteredData = data.filter(item => {
            const itemDate = moment(`${item.x.year}-${String(item.x.month).padStart(2, '0')}-${String(item.x.date || 1).padStart(2, '0')}`);
            return itemDate.isBetween(start, end, 'day', '[]');
        });

        if (period === 'month') {
            const allMonths = Array.from({ length: 12 }, (_, i) => ({
                x: { year: start.year(), month: i + 1 },
                y: 0,
                sojori: 0,
                BookingCom: 0,
                AirBNB: 0
            }));

            data.forEach(item => {
                const monthIndex = item.x.month - 1;
                allMonths[monthIndex] = item;
            });

            return {
                visibleData: allMonths,
                hasNext: false
            };
        } else if (period === 'day') {
            const targetDate = start.clone().add(currentPeriodIndex, 'months');

            if (targetDate.isAfter(end, 'month')) {
                return { visibleData: [], hasNext: false };
            }

            const days = [];
            const monthStart = moment.max(targetDate.clone().startOf('month'), start);
            const monthEnd = moment.min(targetDate.clone().endOf('month'), end);

            let currentDate = monthStart.clone();
            while (currentDate.isSameOrBefore(monthEnd, 'day')) {
                days.push({
                    x: {
                        year: currentDate.year(),
                        month: currentDate.month() + 1,
                        date: currentDate.date()
                    },
                    y: 0,
                    sojori: 0,
                    BookingCom: 0,
                    AirBNB: 0
                });
                currentDate.add(1, 'day');
            }

            filteredData.forEach(item => {
                const itemDate = moment(`${item.x.year}-${item.x.month}-${item.x.date}`);
                if (itemDate.isSame(targetDate, 'month')) {
                    const dayIndex = days.findIndex(d =>
                        d.x.year === item.x.year &&
                        d.x.month === item.x.month &&
                        d.x.date === item.x.date
                    );
                    if (dayIndex !== -1) {
                        days[dayIndex] = item;
                    }
                }
            });

            const nextMonth = targetDate.clone().add(1, 'month');
            const hasNext = nextMonth.isSameOrBefore(end, 'month');
            const hasPrevious = targetDate.isAfter(start, 'month');

            return {
                visibleData: days,
                hasNext,
                hasPrevious
            };
        } else if (period === 'week') {
            const weeksPerPage = 26;
            const weeks = [];
            let currentDate = moment(startDate).startOf('week');
            const endWeek = moment(endDate).endOf('week');
        
            const uniqueWeeks = [...new Set(data.map(item => `${item.x.year}-${item.x.week}`))];
            uniqueWeeks.forEach(weekKey => {
                const [year, week] = weekKey.split('-').map(Number);
                weeks.push({
                    x: {
                        year: year,
                        week: week
                    },
                    y: 0,
                    sojori: 0,
                    BookingCom: 0,
                    AirBNB: 0
                });
            });
        
            // Fill in the data
            data.forEach(item => {
                const weekIndex = weeks.findIndex(w =>
                    w.x.year === item.x.year && w.x.week === item.x.week
                );
                if (weekIndex !== -1) {
                    weeks[weekIndex] = {
                        ...weeks[weekIndex],
                        ...item
                    };
                }
            });
        
            const startIndex = currentPeriodIndex * weeksPerPage;
            const visibleWeeks = weeks.slice(startIndex, startIndex + weeksPerPage);
        
            return {
                visibleData: visibleWeeks,
                hasNext: startIndex + weeksPerPage < weeks.length,
                hasPrevious: currentPeriodIndex > 0
            };
        }
        return { visibleData: filteredData, hasNext: false };
    };

    // const handleNext = () => {
    //     setCurrentPeriodIndex(prev => prev + 1);
    // };

    // const handlePrevious = () => {
    //     setCurrentPeriodIndex(prev => Math.max(0, prev - 1));
    // };

    const handleNext = () => {
        onPeriodIndexChange(currentPeriodIndex + 1);
    };

    const handlePrevious = () => {
        onPeriodIndexChange(Math.max(0, currentPeriodIndex - 1));
    };


    const formatDate = (x) => {
        if (x.date) return `${x.year}/${String(x.month).padStart(2, '0')}/${String(x.date).padStart(2, '0')}`;
        if (x.week) return `${x.year} Week ${x.week}`;
        if (x.month) return `${x.year}/${String(x.month).padStart(2, '0')}`;
        return '';
    };


    const createChartData = (labels, datasets) => ({ labels, datasets });

    const createBarDataset = (label, data, color) => ({
        label,
        data,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        barThickness: 30,
    });

    const createDoughnutDataset = (data, colors) => ({
        data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
    });

    const chartOptions = {
        scales: { y: { beginAtZero: true } },
        plugins: {
            title: { display: true },
            legend: { position: 'bottom' },
        },
    };

    const getChannelColor = (channel) => {
        switch (channel) {
            case 'BookingCom':
                return '#42A5F5';
            case 'sojori':
                return '#F69648';
            case 'AirBNB':
                return '#F73659';
            default:
                return '#42A5F5';
        }
    };

    const tooltipConfig = {
        callbacks: {
            beforeBody: (tooltipItems) => {
                const total = tooltipItems.reduce((sum, tooltipItem) =>
                    sum + tooltipItem.parsed.y, 0
                );
                return `Total: ${total.toFixed(2).toLocaleString()}`;
            },
            label: (tooltipItem) => {
                const value = tooltipItem.parsed.y;
                if (value > 0) {
                    return `${tooltipItem.dataset.label}: ${value.toFixed(2).toLocaleString()}`;
                }
                return null; 
            }
        }
    };

    const commonStackedOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index'
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    maxRotation: 0,
                    minRotation: 0,
                    autoSkip: false,
                    callback: function (value, index, values) {
                        const data = this.chart.data.labels[index];
                        if (selectedPeriod === 'day') {
                            return data.split('/')[2];
                        } else if (selectedPeriod === 'week') {
                            const weekData = data.split(' ');
                            return `W${weekData[2]}`;
                        }
                        return data;
                    }
                }
            },
            y: {
                stacked: true,
                beginAtZero: true,
                grid: {
                    drawBorder: false
                },
                ticks: {
                    callback: function (value) {
                        return value.toFixed(2);
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            tooltip: {
                ...tooltipConfig,
                callbacks: {
                    ...tooltipConfig.callbacks,
                    title: (tooltipItems) => {
                        const item = tooltipItems[0];
                        const label = item.label;
                        if (selectedPeriod === 'day') {
                            return `Date: ${label}`;
                        } else if (selectedPeriod === 'week') {
                            return `Week: ${label}`;
                        }
                        return label;
                    }
                }
            }
        }
    };

    const getMonthName = (month) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[month - 1];
    };

    const charts = [
        {
            title: 'Rental Revenue Over Time',
            type: 'bar',
            data: (() => {
                const { visibleData } = getVisibleData(reservationStats, selectedPeriod, startDate, endDate);
                const barPercentage = visibleData.length <= 4 ? 0.1 : 0.6;
                const categoryPercentage = visibleData.length <= 4 ? 0.3 : 0.8;
                return createChartData(
                    visibleData.map(stat => formatDate(stat.x)),
                    [
                        {
                            label: 'Sojori',
                            data: visibleData.map(stat => stat.sojori || 0),
                            backgroundColor: '#F69648',
                            borderColor: '#F69648',
                            borderWidth: 1,
                            barPercentage,
                            categoryPercentage,
                            stack: 'stack1'
                        },
                        {
                            label: 'BookingCom',
                            data: visibleData.map(stat => stat.BookingCom || 0),
                            backgroundColor: '#42A5F5',
                            borderColor: '#42A5F5',
                            borderWidth: 1,
                            barPercentage,
                            categoryPercentage,
                            stack: 'stack1'
                        },
                        {
                            label: 'AirBNB',
                            data: visibleData.map(stat => stat.AirBNB || 0),
                            backgroundColor: '#F73659',
                            borderColor: '#F73659',
                            borderWidth: 1,
                            barPercentage,
                            categoryPercentage,
                            stack: 'stack1'
                        }
                    ]
                );
            })(),
            options: {
                ...commonStackedOptions,
                plugins: {
                    ...commonStackedOptions.plugins,
                    title: {
                        display: true,
                        text: selectedPeriod === 'day'
                            ? `Rental Revenue (${getMonthName(moment(startDate).add(currentPeriodIndex, 'months').month() + 1)} ${moment(startDate).add(currentPeriodIndex, 'months').year()})`
                            : `Rental Revenue (${selectedPeriod})`
                    }
                }
            },
            gridSize: { xs: 12, md: 8 },
            isLoading: isLoadingReservationStats,
            wrapperStyle: {
                position: 'relative',
                height: '400px',
                overflow: 'hidden'
            },
            showNavigation: selectedPeriod === 'day' || selectedPeriod === 'week',
            hasNext: getVisibleData(reservationStats, selectedPeriod, startDate, endDate, currentPeriodIndex).hasNext,
            hasPrevious: getVisibleData(reservationStats, selectedPeriod, startDate, endDate, currentPeriodIndex).hasPrevious,
        },
        {
            title: 'Per Channel (Total)',
            type: 'doughnut',
            data: createChartData(
                channelStats.map(channel => channel.label),
                [createDoughnutDataset(
                    channelStats.map(channel => channel.value),
                    channelStats.map(channel => getChannelColor(channel.label))
                )]
            ),
            options: { ...chartOptions, plugins: { ...chartOptions.plugins, title: { ...chartOptions.plugins.title, text: 'Total Revenue per Channel' } } },
            gridSize: { xs: 12, md: 4 },
            isLoading: isLoadingChannelStats
        },
        {
            title: 'Rental Revenue per Channel',
            type: 'bar',
            data: createChartData(
                revenueByChannel.map(stat => stat.x.month),
                [createBarDataset('',
                    revenueByChannel.map(stat => stat.y),
                    revenueByChannel.map(stat => getChannelColor(stat.color))
                )]
            ),
            options: {
                ...chartOptions,
                plugins: {
                    ...chartOptions.plugins,
                    legend: { display: false }
                }
            },
            gridSize: { xs: 12, md: 4 },
            isLoading: isLoadingRevenueByChannel
        },
        {
            title: 'Booked Nights per Channel',
            type: 'bar',
            data: createChartData(
                bookedNightsByChannel.map(stat => stat.x.month),
                [createBarDataset('Booked Nights per Channel',
                    bookedNightsByChannel.map(stat => stat.y),
                    bookedNightsByChannel.map(stat => getChannelColor(stat.color))
                )]
            ),
            options: {
                ...chartOptions,
                plugins: {
                    ...chartOptions.plugins,
                    legend: { display: false }
                }
            },
            gridSize: { xs: 12, md: 4 },
            isLoading: isLoadingBookedNightsByChannel
        },
        {
            title: 'Revenue per reservation per channel',
            type: 'bar',
            data: createChartData(
                rentalRevenuePerReservationData?.map(item => item.x.month),
                [createBarDataset('Revenue per Reservation',
                    rentalRevenuePerReservationData?.map(item => item.y),
                    rentalRevenuePerReservationData?.map(item => getChannelColor(item.color))
                )]
            ),
            options: {
                ...chartOptions,
                plugins: {
                    ...chartOptions.plugins,
                    legend: { display: false },
                    title: {
                        ...chartOptions.plugins.title,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            callback: function (value) {
                                return value.toFixed(2).toLocaleString() + ' MAD';
                            }
                        }
                    }
                }
            },
            gridSize: { xs: 12, md: 4 },
            isLoading: isLoadingRentalRevenuePerReservation
        },
        {
            title: 'Check-ins by Channel',
            type: 'bar',
            data: (() => {
                const { visibleData } = getVisibleData(checkinByChannel, selectedPeriod, startDate, endDate);
                const barPercentage = visibleData.length <= 4 ? 0.1 : 0.6;
                const categoryPercentage = visibleData.length <= 4 ? 0.3 : 0.8;
                return createChartData(
                    visibleData.map(stat => formatDate(stat.x)),
                    [
                        {
                            label: 'Sojori',
                            data: visibleData.map(stat => stat.sojori || 0),
                            backgroundColor: '#F69648',
                            borderColor: '#F69648',
                            borderWidth: 1,
                            barPercentage,
                            categoryPercentage,
                            stack: 'stack1'
                        },
                        {
                            label: 'BookingCom',
                            data: visibleData.map(stat => stat.BookingCom || 0),
                            backgroundColor: '#42A5F5',
                            borderColor: '#42A5F5',
                            borderWidth: 1,
                            barPercentage,
                            categoryPercentage,
                            stack: 'stack1'
                        },
                        {
                            label: 'AirBNB',
                            data: visibleData.map(stat => stat.AirBNB || 0),
                            backgroundColor: '#F73659',
                            borderColor: '#F73659',
                            borderWidth: 1,
                            barPercentage,
                            categoryPercentage,
                            stack: 'stack1'
                        }
                    ]
                );
            })(),
            options: {
                ...commonStackedOptions,
                plugins: {
                    ...commonStackedOptions.plugins,
                    title: {
                        display: true,
                        text: selectedPeriod === 'day'
                            ? `Rental Revenue (${getMonthName(moment(startDate).add(currentPeriodIndex, 'months').month() + 1)} ${moment(startDate).add(currentPeriodIndex, 'months').year()})`
                            : `Number of Check-ins (${selectedPeriod})`
                    }

                }
            },
            gridSize: { xs: 12, md: 8 },
            isLoading: isLoadingCheckinByChannel,
            wrapperStyle: {
                position: 'relative',
                height: '400px',
                overflow: 'hidden'
            },
            showNavigation: selectedPeriod === 'day' || selectedPeriod === 'week',
            hasNext: getVisibleData(checkinByChannel, selectedPeriod, startDate, endDate).hasNext,
            hasPrevious: getVisibleData(checkinByChannel, selectedPeriod, startDate, endDate).hasPrevious,
        },
        {
            title: 'Reservations per channel',
            type: 'doughnut',
            data: createChartData(
                reservationPerChannelData?.graphData?.map(item => item.x.month) || [],
                [createDoughnutDataset(
                    reservationPerChannelData?.graphData?.map(item => item.y) || [],
                    reservationPerChannelData?.graphData?.map(item => getChannelColor(item.color)) || []
                )]
            ),
            options: { ...chartOptions, plugins: { ...chartOptions.plugins, title: { ...chartOptions.plugins.title, text: 'Percentage of reservations based on "check-in date"' } } },
            gridSize: { xs: 12, md: 4 },
            isLoading: isLoadingReservationPerChannel,
            additionalContent: (
                <>
                    <Typography variant="h4" align="center" style={{ position: 'relative', top: '-50%' }}>
                        {reservationPerChannelData?.reservationTotal || 0}
                    </Typography>
                    <Typography variant="subtitle1" align="center" style={{ position: 'relative', top: '-50%', fontSize: '0.9rem' }}>
                        Total reservations
                    </Typography>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="grid grid-cols-2 md:flex md:flex-wrap gap-4 mb-4">
                <Select
                    value={selectedPeriod}
                    onChange={(e) => {

                        onPeriodChange(e.target.value);
                    }}
                    className="w-full md:w-28 h-9 !rounded-sm"
                >
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                </Select>
            </div>
            <Grid container spacing={3}>
                {charts.map((chart, index) => (
                    <Grid item {...chart.gridSize} key={index}>
                        <Paper style={{
                            height: '100%',
                            backgroundColor: 'rgb(248,249,252)',
                            minHeight: '300px',
                            position: 'relative'
                        }}>
                            <Typography variant="h6" align="center">
                                {chart.title}
                            </Typography>
                            {chart.isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div style={chart.wrapperStyle}>
                                    {chart.type === 'bar' ? (
                                        <Bar data={chart.data} options={chart.options} />
                                    ) : (
                                        <Doughnut data={chart.data} options={chart.options} />
                                    )}
                                    {chart.showNavigation && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '12px',
                                            transform: 'translateY(-50%)',
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '0 63px',
                                            zIndex: 1
                                        }}>
                                            {chart.hasPrevious && (
                                                <button
                                                    onClick={handlePrevious}
                                                    className="rounded-full w-10 h-10 flex items-center justify-center"
                                                >
                                                    <ChevronLeft />
                                                </button>
                                            )}
                                            {chart.hasNext && (
                                                <button
                                                    onClick={handleNext}
                                                    className="rounded-full w-10 h-10 flex items-center justify-cente ml-auto"
                                                >
                                                    <ChevronRight />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                    {chart.additionalContent}
                                </div>
                            )}
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Charts;